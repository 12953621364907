import React from 'react'
import { Box } from '@mui/joy'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const Submit = () => {
  const { t } = useTranslation()
  const isLoading = useSelector(
    (state) => state.custom.customFieldsGetAllData?.isLoading,
  )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'end',
        mr: 2,
        mt: 2,
      }}
    >
      <Button
        size="small"
        loading={isLoading}
        type="primary"
        htmlType={'submit'}
        variant="solid"
      >
        {t('add_advertiser_submit')}
      </Button>
    </Box>
  )
}

export default Submit
