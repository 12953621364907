import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import { templateGet } from '../../../../store/templates'
import BaseInformation from './BaseInformation'

const Edit = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const pageId = parseInt(params.pageId)
  const [rows, setRows] = useState(false)

  useEffect(() => {
    if (pageId > 0) {
      dispatch(templateGet({ template_id: pageId })).then((result) => {
        setRows(result.rows)
      })
    }
  }, [pageId, dispatch])

  return rows ? (
    <Box
      sx={{
        maxWidth: 'clamp(450px, 100dvw, 900px)',
        width: '100%',
        overflowY: 'auto',
        borderRight: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'var(--joy-palette-background-surface)',
      }}
    >
      <BaseInformation rows={rows} setRows={setRows} />
    </Box>
  ) : (
    <CircularProgress size="sm" />
  )
}

export default Edit
