import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  IUsersGetAllResponse,
  IUsersGetAllRequest,
  IUsersGetRequest,
  IUsersGetResponse,
} from './types'

export const getAll = (
  params: IUsersGetAllRequest,
): AxiosPromise<IUsersGetAllResponse> =>
  axiosInstance.post(Endpoints.USERS.GET_ALL, params)

export const get = (
  params: IUsersGetRequest,
): AxiosPromise<IUsersGetResponse> =>
  axiosInstance.get(`${Endpoints.USERS.GET}/${params.user_id}`)
