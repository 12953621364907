import React, { useState } from 'react'

import { Box } from '@mui/joy'
import { InputNumber, Select, Space } from 'antd'

import { useFilter } from '../../../../../hooks/filter'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const OperatingSystem = () => {
  const { t } = useTranslation()
  const { updateFilter } = useFilter()

  const [name, setName] = useState([])
  const [condition, setCondition] = useState('')
  const [version, setVersion] = useState('')

  const os = useSelector((state) => state.rotations.target.rows?.os)

  const include = () => {
    if (!name) {
      return
    }
    updateFilter('in_os', {
      value: `${name}${condition}${version}`,
      name,
      condition,
      version,
    })
  }

  const exclude = () => {
    if (!name) {
      return
    }
    updateFilter('ex_os', {
      value: `${name}${condition}${version}`,
      name,
      condition,
      version,
    })
  }

  const items = os
    ? Object.keys(os).map((i) => ({ label: i, value: i, key: i }))
    : []

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            gridGap: 10,
            alignItems: 'center',
          }}
        >
          <Space.Compact block>
            <Select
              placeholder={t('os')}
              maxTagCount={'responsive'}
              allowClear
              style={{ width: '100%' }}
              defaultValue={name}
              options={items}
              onChange={(val) => setName(val ? val : '')}
            />

            {!!name.length && (
              <>
                <Select
                  key={'conditions'}
                  style={{ minWidth: '60px' }}
                  defaultValue={condition}
                  onChange={setCondition}
                  options={[
                    { label: '', value: '---' },
                    { label: '=', value: '=' },
                    { label: '>', value: '>' },
                    { label: '<', value: '<' },
                    { label: '>=', value: '>=' },
                    { label: '<=', value: '<=' },
                  ]}
                />
                <InputNumber
                  style={{ minWidth: '100px' }}
                  min={0}
                  max={1000}
                  step="0.01"
                  onChange={setVersion}
                  placeholder={t('version')}
                />
              </>
            )}
          </Space.Compact>
        </Box>

        <IncludeExcludeBtn include={include} exclude={exclude} />
      </Box>
    </>
  )
}

export default OperatingSystem
