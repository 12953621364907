import React, { useState } from 'react'
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@mui/joy'
import { Input, Switch } from 'antd'
import { Add, PermMediaRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useAdv } from '../../../../../hooks/advertisers'

import FormItem from '../../../../../layout/FormItem'

const Basic = () => {
  const { t } = useTranslation()
  const { adv, updateAdvertiser } = useAdv()
  const [index, setIndex] = useState(0)
  const active = parseInt(adv.active)

  return (
    <Accordion
      expanded={index === 0}
      onChange={(_, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <PermMediaRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>
          {t('add_advertiser_basic')}
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        {'name' in adv ? (
          <FormItem
            text={'add_advertiser_basic_name'}
            component={
              <Input
                style={{ width: '100%' }}
                value={adv.name || ''}
                max={180}
                onChange={(e) => {
                  updateAdvertiser('name', e.target.value)
                }}
              />
            }
          />
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '101px',
              }}
            >
              <CircularProgress size="sm" />
            </Box>
          </>
        )}

        <FormItem
          text={'add_advertiser_basic_active'}
          component={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Switch
                size="small"
                checked={active === 1}
                onChange={(e) => {
                  updateAdvertiser('active', e ? 1 : 0)
                }}
              />
            </Box>
          }
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Basic
