import React from 'react'

import {
  Box,
  Sheet,
  Tooltip,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/joy'

import {
  SourceRounded,
  FolderSpecialRounded,
  DeleteForeverRounded,
} from '@mui/icons-material'

import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useAppDispatch } from '../../../../../store'
import { campaignDel } from '../../../../../store/campaigns'
import { useCmp } from '../../../../hooks/campaigns'
import { Macros, MacrosBtn } from '../../Macros'
import DeleteDialog from '../../../../layout/DeleteDialog'

const ControlBar = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { cmp } = useCmp()
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const advertiserId = parseInt(useParams().pageId)
  const campaignId = parseInt(useParams().campaignId)
  const [openMacros, setOpenMacros] = React.useState(false)
  const navigate = useNavigate()

  const deleteCampaign = () => {
    dispatch(
      campaignDel({
        advertiser_id: advertiserId,
        campaign_id: campaignId,
      }),
    ).then((result) => {
      if (result) {
        setOpenDeleteDialog(false)
        navigate('/advertisers')
      }
    })
  }

  const AddNewBanner = () => (
    <Tooltip
      arrow
      size="sm"
      color="primary"
      placement="top"
      title={t('add_banner')}
      variant="solid"
    >
      <IconButton
        size="sm"
        component={NavLink}
        to={`/advertisers/banner/add/${advertiserId}/${campaignId}`}
      >
        <FolderSpecialRounded
          sx={{
            fontSize: '20px',
          }}
          color="primary"
        />
      </IconButton>
    </Tooltip>
  )

  const RemoveCampaign = () => (
    <Tooltip
      arrow
      size="sm"
      color="primary"
      placement="top"
      title={t('remove_campaign')}
      variant="solid"
    >
      <IconButton size="sm" onClick={setOpenDeleteDialog}>
        <DeleteForeverRounded
          sx={{
            fontSize: '20px',
          }}
          color="danger"
        />
      </IconButton>
    </Tooltip>
  )

  return (
    <>
      <Macros open={openMacros} setOpen={setOpenMacros} />
      <DeleteDialog
        open={{ set: setOpenDeleteDialog, value: openDeleteDialog }}
        description={t('delete_dialog_remove_campaign')}
        onClick={(e) => {
          deleteCampaign()
        }}
      />
      <Sheet
        variant="outlined"
        sx={{
          border: 'none',
          transition: 'transform 0.4s',
          overflow: 'hidden',
          px: 2,
          py: 1.5,
          minHeight: '58px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor:
            'var(--variant-softBg, var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #E3EFFB)))',
        }}
      >
        <Typography
          level="title-md"
          sx={{ flex: 1, display: 'flex', alignItems: 'center' }}
        >
          <SourceRounded sx={{ mr: '10px' }} />
          {'name' in cmp ? cmp.name : <CircularProgress size="sm" />}
        </Typography>

        <Box sx={{ gridGap: '10px', display: 'flex' }}>
          <MacrosBtn onClick={setOpenMacros} />
          <AddNewBanner />
          <RemoveCampaign />
        </Box>
      </Sheet>
    </>
  )
}

export default ControlBar
