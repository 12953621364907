import React from 'react'

import Form from './Form'
import Submit from './Submit'

import { toast } from '../../../layout/JoyToaster'
import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../store'
import { useTranslation } from 'react-i18next'
import { templateEdit } from '../../../../store/templates'

const BaseInformation = ({ rows, setRows }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [, setCookie] = useCookies(['reload'])

  const handlerForm = (event) => {
    event.preventDefault()

    dispatch(
      templateEdit({
        template_id: rows.id,
        name: rows.name,
        body: rows.body,
        type: rows.type,
        async: rows.async,
        active: rows.active,
      }),
    ).then((result) => {
      result
        ? toast.success(t('edit_template_successfully'))
        : toast.error(t('error'))

      setCookie('reload', new Date().getTime(), { path: '/' })
    })
  }

  return (
    <form onSubmit={handlerForm}>
      <Form
        values={{
          set: setRows,
          arr: rows,
        }}
      />
      <Submit />
    </form>
  )
}

export default BaseInformation
