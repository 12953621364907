import React from 'react'

import { Box } from '@mui/joy'
import { FilterProvider } from '../../../hooks/filter'
import { TabProvider } from '../../../hooks/tab'
import { ReportProvider } from '../../../hooks/report'

import Tabs from './Tabs'

const Filter = () => {
  return (
    <>
      <Box
        component="main"
        className="MainContent"
        sx={() => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          minHeight: 'calc(100dvh - var(--Topbar-height))',
          gap: 1,
          overflowY: 'auto',
        })}
      >
        <FilterProvider>
          <TabProvider>
            <ReportProvider>
              <Tabs />
            </ReportProvider>
          </TabProvider>
        </FilterProvider>
      </Box>
    </>
  )
}

export default Filter
