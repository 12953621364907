import * as React from 'react'

import { useCookies } from 'react-cookie'
import SplitterLayout from 'react-splitter-layout-react-v18'
import 'react-splitter-layout-react-v18/lib/index.css'

const Splitter = (props) => {
  const [cookie, setCookie] = useCookies(['splitter'])
  const secondaryInitialSize = cookie.splitter || 84

  return (
    <>
      <SplitterLayout
        customClassName={'splitter-main-container'}
        primaryMinSize={16}
        secondaryMinSize={70}
        secondaryInitialSize={secondaryInitialSize}
        percentage={true}
        onSecondaryPaneSizeChange={(e) => {
          setCookie('splitter', e)
        }}
      >
        {props.children}
      </SplitterLayout>
    </>
  )
}

export default Splitter
