import React, { useEffect, useState } from 'react'

import { Box } from '@mui/joy'
import { Select, Tag, Space } from 'antd'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

import { useFilter } from '../../../../../hooks/filter'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../../store'
import { templateGetAll } from '../../../../../../store/templates'

const Templates = () => {
  const { t } = useTranslation()
  const { updateFilter } = useFilter()

  const [template, setTemplate] = useState({})
  const dispatch = useAppDispatch()
  const rows = useSelector((state) => state.templates.getAllData?.rows)

  useEffect(() => {
    if (!rows) {
      dispatch(templateGetAll())
    }
  }, [dispatch, rows])

  const include = () => updateFilter('in_template', template)
  const exclude = () => updateFilter('ex_template', template)

  const items =
    rows &&
    Object.values(rows).map((i) => ({
      label: i.name,
      value: i.name,
      key: i.id,
    }))

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            gridGap: 10,
            alignItems: 'center',
          }}
        >
          <Select
            placeholder={t('add_templates')}
            maxTagCount={'responsive'}
            allowClear
            mode="multiple"
            style={{ width: '100%' }}
            loading={!items}
            options={items}
            tagRender={({ label, value, closable, onClose }) => {
              const onPreventMouseDown = (event) => {
                event.preventDefault()
                event.stopPropagation()
              }

              return (
                <Tag
                  key={`${label}-${value}`}
                  onMouseDown={onPreventMouseDown}
                  closable={closable}
                  onClose={onClose}
                  style={{
                    marginInlineEnd: 4,
                    display: 'flex',
                  }}
                >
                  <Space style={{ gridGap: '4px', mr: '4px' }}>{label}</Space>
                </Tag>
              )
            }}
            onChange={(_, newValue) => {
              setTemplate(newValue)
            }}
          />
        </Box>

        <IncludeExcludeBtn include={include} exclude={exclude} />
      </Box>
    </>
  )
}

export default Templates
