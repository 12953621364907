import React, { useEffect, useState } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Input, Select, Switch } from 'antd'
import { Add, BookmarksRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import FormItem from '../../../layout/FormItem'
const Templates = require('../../../configs/templates.json')
const { TextArea } = Input

const Basic = ({ values }) => {
  const { t } = useTranslation()
  const [index, setIndex] = useState(0)
  const [checked, setChecked] = useState(0)

  const { arr, set } = values

  useEffect(() => {
    if (arr.active !== null) {
      setChecked(arr.active === 1 ? true : false)
    }
  }, [arr.active])

  return (
    <Accordion
      sx={{ borderBottom: 0 }}
      expanded={index === 0}
      onChange={(event, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <BookmarksRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>{t('add_category_basic')}</Box>
      </AccordionSummary>

      <AccordionDetails>
        <FormItem
          text={'name'}
          component={
            <Input
              style={{ width: '100%' }}
              size="small"
              value={arr.name || ''}
              onChange={(e) => {
                set({
                  ...arr,
                  ...{ name: e.target.value },
                })
              }}
            />
          }
        />

        <FormItem
          text={'add_advertiser_basic_active'}
          component={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Switch
                size="small"
                checked={checked}
                onChange={(e) => {
                  setChecked(e)
                  set({
                    ...arr,
                    ...{ active: e ? 1 : 0 },
                  })
                }}
              />
            </Box>
          }
        />

        <FormItem
          text={'add_template_type'}
          component={
            <Select
              maxTagCount={'responsive'}
              allowClear
              style={{ width: '100%' }}
              value={`${arr.type}`}
              options={Object.entries(Templates).map(
                ([value, { name: label }]) => ({
                  label,
                  value,
                }),
              )}
              onChange={(val) => {
                set({
                  ...arr,
                  ...{ type: val === '' ? 0 : parseInt(val) },
                })
              }}
            />
          }
        />

        <FormItem
          text={'add_template_async'}
          component={
            <Select
              maxTagCount={'responsive'}
              style={{ width: '100%' }}
              value={arr.async || 0}
              options={[
                { label: t('add_template_async_1'), value: 1 },
                { label: t('add_template_async_2'), value: 2 },
                { label: t('add_template_async_3'), value: 3 },
                { label: t('add_template_async_4'), value: 4 },
              ]}
              onChange={(val) => {
                set({
                  ...arr,
                  ...{ async: val === '' ? 0 : parseInt(val) },
                })
              }}
            />
          }
        />

        <FormItem
          text={'template_body'}
          component={
            <TextArea
              value={arr.body}
              onChange={(e) => {
                set({
                  ...arr,
                  ...{ body: e.target.value },
                })
              }}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          }
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Basic
