import React from 'react'
import { Input } from 'antd'
import { useReport } from '../../../../../../hooks/report'
import { useTab } from '../../../../../../hooks/tab'
import { t } from 'i18next'

const Search = ({ name }) => {
  const { tabs, currentTab } = useTab()
  const isSplit = (tabs[currentTab] && tabs[currentTab].length > 1) || 0

  const { report, replaceOption } = useReport()
  const { options: { search = '' } = {} } = report[name] || {}

  const onSearch = ({ target: { value } }) => {
    replaceOption({ name, key: 'search', value })
  }

  return (
    <>
      <Input
        size="small"
        placeholder={t('search_placeholder')}
        defaultValue={search}
        allowClear
        onChange={onSearch}
        style={{
          boxShadow: 'none',
          width: '200px',
          marginRight: 8,
          ...(isSplit
            ? {
                minHeight: '24px',
                fontSize: 12,
              }
            : { fontSize: 14 }),
        }}
      />
    </>
  )
}

export default Search
