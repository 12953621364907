import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import {
  IPublishersAddRequest,
  IPublishersAddSharingRequest,
} from '../../api/publishers/add/types'

import {
  IPublishersRemoveRequest,
  IPublishersRemoveSharingRequest,
} from '../../api/publishers/delete/types'

import {
  IPublishersGetAllRequest,
  IPublishersGetRequest,
  IPublishersGetAllSharingRequest,
} from '../../api/publishers/get/types'

import {
  IPublishersSetActiveRequest,
  IPublishersEditRequest,
} from '../../api/publishers/edit/types'

import {
  publisherAddStart,
  publisherAddSuccess,
  publisherAddFailure,
  publisherGetAllSuccess,
  publisherGetAllFailure,
  publisherGetAllStart,
  publisherSetActiveStart,
  publisherSetActiveSuccess,
  publisherSetActiveFailure,
  publisherDeleteStart,
  publisherDeleteSuccess,
  publisherDeleteFailure,
  publisherEditStart,
  publisherEditSuccess,
  publisherEditFailure,
  publisherGetStart,
  publisherGetSuccess,
  publisherGetFailure,
  publisherAddSharingStart,
  publisherAddSharingSuccess,
  publisherAddSharingFailure,
  publisherGetAllSharingStart,
  publisherGetAllSharingSuccess,
  publisherGetAllSharingFailure,
  publisherSharingDeleteStart,
  publisherSharingDeleteSuccess,
  publisherSharingDeleteFailure,
} from './reducer'

export const publisherAdd =
  (data: IPublishersAddRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(publisherAddStart())
      const result = await api.publishersAdd.create(data)
      dispatch(publisherAddSuccess({ result: result.data }))
      return true
    } catch (e: any) {
      dispatch(publisherAddFailure(e.message))
      return false
    }
  }

export const publisherAddSharing =
  (data: IPublishersAddSharingRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(publisherAddSharingStart())
      await api.publishersAdd.sharing(data)
      dispatch(publisherAddSharingSuccess())
      return true
    } catch (e: any) {
      dispatch(publisherAddSharingFailure(e.message))
      return false
    }
  }

export const publisherDel =
  (data: IPublishersRemoveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(publisherDeleteStart())
      await api.publishersDel.remove(data)
      dispatch(publisherDeleteSuccess())
      return true
    } catch (e: any) {
      dispatch(publisherDeleteFailure(e.message))
      return false
    }
  }

export const sharingDel =
  (data: IPublishersRemoveSharingRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(publisherSharingDeleteStart())
      await api.publishersDel.removeSharing(data)
      dispatch(publisherSharingDeleteSuccess())
      return true
    } catch (e: any) {
      dispatch(publisherSharingDeleteFailure(e.message))
      return false
    }
  }

export const publisherEdit =
  (data: IPublishersEditRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(publisherEditStart())
      await api.publishersEdit.update(data)
      dispatch(publisherEditSuccess())
      return true
    } catch (e: any) {
      dispatch(publisherEditFailure(e.message))
      return false
    }
  }

export const getPublisher =
  (data: IPublishersGetRequest) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      dispatch(publisherGetStart())
      const result = await api.publishersGet.get(data)
      dispatch(publisherGetSuccess({ result: result.data }))
      return result.data
    } catch (e: any) {
      dispatch(publisherGetFailure(e.message))
      return false
    }
  }

export const getAllPublishers =
  (data: IPublishersGetAllRequest) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      dispatch(publisherGetAllStart())
      const result = await api.publishersGet.getAll(data)
      dispatch(publisherGetAllSuccess({ result: result.data }))
    } catch (e: any) {
      dispatch(publisherGetAllFailure(e.message))
    }
  }

export const getAllPublisherSharings =
  (data: IPublishersGetAllSharingRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(publisherGetAllSharingStart())
      const result = await api.publishersGet.getAllSharings(data)
      dispatch(publisherGetAllSharingSuccess({ result: result.data }))
      return true
    } catch (e: any) {
      console.log(e)
      dispatch(publisherGetAllSharingFailure(e.message))
      return false
    }
  }

export const setActive =
  (data: IPublishersSetActiveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(publisherSetActiveStart())
      await api.publishersEdit.setActive(data)
      dispatch(publisherSetActiveSuccess())
      return true
    } catch (e: any) {
      dispatch(publisherSetActiveFailure(e.message))
      return false
    }
  }
