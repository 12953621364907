import React, { useEffect, useState } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Input, Select, Switch } from 'antd'
import { Add, PersonRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../store'
import { templateGetAll } from '../../../../store/templates'
import FormItem from '../../../layout/FormItem'

const Basic = ({ values }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [index, setIndex] = useState(0)
  const [checked, setChecked] = useState(false)
  const { set, arr } = values

  const templateTypes = useSelector((state) => state.templates.getAllData?.rows)

  useEffect(() => {
    if (!templateTypes) dispatch(templateGetAll())
  }, [dispatch, templateTypes])

  return (
    <Accordion
      sx={{ borderBottom: 0 }}
      expanded={index === 0}
      onChange={(event, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <PersonRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>
          {t('add_advertiser_basic')}
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <FormItem
          text={'name'}
          component={
            <Input
              style={{ width: '100%' }}
              size="small"
              max={180}
              onChange={(e) => {
                set({
                  ...arr,
                  ...{ name: e.target.value },
                })
              }}
            />
          }
        />

        <FormItem
          text={'add_templates'}
          component={
            <Select
              style={{ width: '100%' }}
              options={templateTypes}
              loading={!templateTypes}
              fieldNames={{ label: 'name', value: 'id' }}
              onChange={(val) => {
                set({
                  ...arr,
                  ...{ template: val },
                })
              }}
            />
          }
        />

        <FormItem
          text={'add_advertiser_basic_active'}
          component={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Switch
                style={{ marginLeft: 'initial', marginRight: 'auto' }}
                size="small"
                checked={checked}
                onChange={(e) => {
                  setChecked(e)
                  set({
                    ...arr,
                    ...{ active: e ? 1 : 0 },
                  })
                }}
              />
            </Box>
          }
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Basic
