import React, { createContext, useContext, useState } from 'react'
export const PublisherContext = createContext()

export function PublisherProvider({ children }) {
  const [pub, setPub] = useState({})

  const updatePublisher = (key, newValue) => {
    setPub((current) => ({ ...current, [key]: newValue }))
  }

  const replacePublisher = (newValue) => {
    setPub(newValue)
  }

  return (
    <PublisherContext.Provider
      value={{ pub, updatePublisher, replacePublisher }}
    >
      {children}
    </PublisherContext.Provider>
  )
}

export function usePub() {
  const context = useContext(PublisherContext)
  if (!context) {
    throw new Error('usePub must be used within a Publisher')
  }
  return context
}
