import React, { useEffect } from 'react'

import { Box, CircularProgress } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../../store'
import { useBnr } from '../../../../hooks/banners'
import api from '../../../../../api'

import ControlBar from './ControlBar'
import Form from './Form'

const Edit = () => {
  const dispatch = useAppDispatch()
  const { bnr, replaceBanner } = useBnr()
  const advertiserId = parseInt(useParams().pageId, 10)
  const bannerId = parseInt(useParams().campaignId, 10)

  useEffect(() => {
    const isValid = !!bnr ? !bnr.banner_id || bnr.banner_id !== bannerId : false

    if (isValid && advertiserId > 0 && bannerId > 0) {
      api.bannersGet
        .get({
          banner_id: bannerId,
          advertiser_id: advertiserId,
        })
        .then((result) => {
          if (!result.data.error) {
            replaceBanner(result.data.rows)
          }
        })
    }
  }, [replaceBanner, dispatch, bannerId, advertiserId, bnr])

  return !!bnr && Object.keys(bnr).length > 0 && bnr.banner_id === bannerId ? (
    <Box sx={{ width: '100%' }}>
      <ControlBar />
      <Form />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '20dvh',
      }}
    >
      <CircularProgress size="sm" />
    </Box>
  )
}

export default Edit
