import React from 'react'
import { Tooltip, IconButton } from '@mui/joy'
import { NavLink } from 'react-router-dom'

const Button = ({ path, onClick, active, component, tooltip }) => {
  const iconButtonSx = () => ({
    display: 'flex',
    minHeight: '25px',
    minWidth: '25px',
    [`&.active svg`]: {
      color: 'var(--joy-palette-primary-600)',
    },
  })

  return (
    <Tooltip
      arrow
      size={'sm'}
      placement="top"
      color="primary"
      title={tooltip}
      sx={{ px: 1 }}
    >
      {path ? (
        <IconButton
          size={'sm'}
          sx={iconButtonSx}
          component={NavLink}
          to={path}
          className={active && 'active'}
        >
          {component}
        </IconButton>
      ) : (
        <IconButton
          size={'sm'}
          sx={{
            minHeight: '25px',
            minWidth: '25px',
            ...iconButtonSx,
            ...{
              [`&.active svg`]: {
                color:
                  'var(--joy-palette-success-solidBg, var(--joy-palette-success-500, #1F7A1F))',
              },
            },
          }}
          onClick={onClick}
          className={active && 'active'}
        >
          {component}
        </IconButton>
      )}
    </Tooltip>
  )
}

export default Button
