import React, { useCallback } from 'react'
import { useAppDispatch } from '../../../../../store'
import { setActive } from '../../../../../store/banners'
import ListItemComponent from './ListItemComponent'

const Banner = ({ banner: { banner_id, active, name }, advertiserId }) => {
  const dispatch = useAppDispatch()
  const path = `/advertisers/banner/edit/${advertiserId}/${banner_id}`

  const onSetActive = useCallback(
    (banner_id, active) => {
      dispatch(setActive({ active, banner_id }))
    },
    [dispatch],
  )

  return (
    <ListItemComponent
      isOpen={false}
      name={name}
      path={path}
      id={banner_id}
      active={active}
      hasNestedList={false}
      onSetActive={onSetActive}
      toggleOpen={() => {}}
      ml="20px"
    />
  )
}

export default Banner
