import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  ICustomFieldsEditRequest,
  ICustomFieldsEditResponse,
  ICustomParamsEditRequest,
  ICustomParamsEditResponse,
} from './types'

export const fieldsUpdate = (
  params: ICustomFieldsEditRequest,
): AxiosPromise<ICustomFieldsEditResponse> =>
  axiosInstance.put(Endpoints.CUSTOM.FIELDS_EDIT, params)

export const paramsUpdate = (
  params: ICustomParamsEditRequest,
): AxiosPromise<ICustomParamsEditResponse> =>
  axiosInstance.put(Endpoints.CUSTOM.PARAMS_EDIT, params)
