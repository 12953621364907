import React from 'react'
import { Button, Dropdown } from 'antd'
import { useReport } from '../../../../../../hooks/report'
import { FileDownloadRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import jsPDF from 'jspdf'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import { fontBinary } from '../../../../../../fonts/pdf'

const DownloadButtons = ({ name, tableRef }) => {
  const { t } = useTranslation()
  const { report } = useReport()
  const { reports: current = [], options: { group_by } = {} } =
    report[name] || {}

  const fileName = () => {
    return 'adangle report - ' + new Date().toLocaleString()
  }

  const onDownloadPdf = () => {
    const fontName = 'noah'
    const fontDir = '../../../../../../font/noah-normal.ttf'
    const doc = new jsPDF('l')

    doc.addFileToVFS(fontDir, fontBinary)
    doc.addFont(fontDir, fontName, 'normal')
    doc.setFont(fontName)

    doc.autoTable({
      html: '#table',
      styles: {
        font: fontName,
      },
      willDrawPage: function (data) {
        doc.setFontSize(20)
        doc.setTextColor(40)
        doc.text('AdAngle', data.settings.margin.left, 22)
      },
      margin: { top: 30 },
    })
    doc.save(fileName() + '.pdf')
  }

  const DownloadXLS = () => (
    <DownloadTableExcel
      filename={fileName()}
      sheet={'report'}
      currentTableRef={tableRef}
    >
      {t('download_excel')}
    </DownloadTableExcel>
  )

  const onDownloadCSV = () => {
    const convertTableToCSV = (table) => {
      if (!table) return

      const columnDelimiter = ','
      const lineDelimiter = '\n'
      let csv = ''

      const escapeForCSV = (value) => `"${value.replace(/"/g, '""')}"`

      const processRow = (row) =>
        Array.from(row.cells)
          .map((cell) => escapeForCSV(cell.innerText))
          .join(columnDelimiter)

      const headers = Array.from(table.querySelectorAll('thead th'))
        .map((header) => escapeForCSV(header.innerText))
        .join(columnDelimiter)
      const rows = Array.from(table.querySelectorAll('tbody tr'))
        .map(processRow)
        .join(lineDelimiter)
      const footers = Array.from(table.querySelectorAll('tfoot td'))
        .map((footer) => escapeForCSV(footer.innerText))
        .join(columnDelimiter)

      csv += [headers, rows, footers].filter((row) => row).join(lineDelimiter)

      return csv
    }

    const csv = convertTableToCSV(tableRef)
    if (!csv) return

    const filename = `${fileName()}.csv`
    const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
    const link = document.createElement('a')

    link.setAttribute('href', csvData)
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const onDownloadJSON = () => {
    const data = current.map((item) => {
      const newItem = {}
      Object.entries(item).forEach(([key, value]) => {
        if (key.startsWith('customField')) {
          const index = parseInt(key.substring('customField'.length), 10)
          const newKey = index < group_by.length ? group_by[index] : key
          newItem[newKey] = value
        } else if (key === 'count') {
          newItem['views'] = value
        } else if (key === 'ctr') {
          newItem[key] = parseFloat(value.toFixed(2))
        } else {
          newItem[key] = value
        }
      })
      return newItem
    })

    const json = JSON.stringify(data, null, 2)
    const blob = new Blob([json], { type: 'application/json' })
    const href = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = href
    link.download = `${fileName()}.json`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  const items = [
    { key: 'pdf', label: t('download_pdf'), onClick: onDownloadPdf },
    { key: 'xls', label: <DownloadXLS /> },
    { key: 'csv', label: t('download_csv'), onClick: onDownloadCSV },
    { key: 'json', label: t('download_json'), onClick: onDownloadJSON },
  ]

  return (
    <>
      <Dropdown arrow menu={{ items }} placement="bottomLeft">
        <Button
          ghost
          style={{
            paddingLeft: '4px',
            paddingRight: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          size={'small'}
        >
          <FileDownloadRounded sx={{ fontSize: 15 }} />
        </Button>
      </Dropdown>
    </>
  )
}

export default DownloadButtons
