import React, { useState } from 'react'

import FormContainer from './FormContainer'

import { useTranslation } from 'react-i18next'
import { toast } from '../../../../../layout/JoyToaster'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../../../store'
import { campaignEdit } from '../../../../../../store/campaigns'
import { useCmp } from '../../../../../hooks/campaigns'

const BaseInformation = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { cmp } = useCmp()
  const advertiserId = parseInt(useParams().pageId)
  const [isLoading, setIsLoading] = useState(false)

  const handlerForm = (event) => {
    event.preventDefault()
    setIsLoading(true)

    dispatch(
      campaignEdit({
        advertiser_id: advertiserId,
        campaign_id: cmp.campaign_id,
        active: cmp.active,
        template: cmp.template,
        name: cmp.name,
      }),
    ).then((result) => {
      if (result) {
        result
          ? toast.success(t('edit_advertiser_successfully'))
          : toast.error(t('error'))
      }
      setIsLoading(false)
    })
  }

  return (
    <form onSubmit={handlerForm}>
      <FormContainer isLoading={isLoading} />
    </form>
  )
}

export default BaseInformation
