import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  ICampaignsRemoveRequest,
  ICampaignsRemoveResponse,
  ICampaignsPricesRemoveRequest,
  ICampaignsPricesRemoveResponse,
  ICampaignsTrackerRemoveRequest,
  ICampaignsTrackerRemoveResponse,
  ICampaignsRateRemoveRequest,
  ICampaignsRateRemoveResponse,
} from './types'

export const remove = (
  params: ICampaignsRemoveRequest,
): AxiosPromise<ICampaignsRemoveResponse> =>
  axiosInstance.delete(Endpoints.CAMPAIGNS.DELETE, { data: { ...params } })

export const pricesRemove = (
  params: ICampaignsPricesRemoveRequest,
): AxiosPromise<ICampaignsPricesRemoveResponse> =>
  axiosInstance.delete(Endpoints.CAMPAIGNS.PRICES_DELETE, {
    data: { ...params },
  })

export const trackerRemove = (
  params: ICampaignsTrackerRemoveRequest,
): AxiosPromise<ICampaignsTrackerRemoveResponse> =>
  axiosInstance.delete(Endpoints.CAMPAIGNS.TRACKER_DELETE, {
    data: { ...params },
  })

export const rateRemove = (
  params: ICampaignsRateRemoveRequest,
): AxiosPromise<ICampaignsRateRemoveResponse> =>
  axiosInstance.delete(
    `${Endpoints.CAMPAIGNS.RATE_DELETE}/${params.campaign_id}`,
    {
      data: { ...params },
    },
  )
