import React, { useState } from 'react'

import { Input, Select, Space, Button } from 'antd'
import { PlusOutlined, LinkOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from '../../../../../../layout/JoyToaster'

import { useAppDispatch } from '../../../../../../../store'
import { bannerTrackerAdd } from '../../../../../../../store/banners'
import pixelSystem from '../../../../../../handlers/pixelSystem'

const events = require('../../../../../../configs/events.json')
const { TextArea } = Input

const AddTracker = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const advertiser_id = parseInt(useParams().pageId)
  const banner_id = parseInt(useParams().campaignId)

  const [trackingCode, setTrackingCode] = useState('')
  const [trackingEvent, setTrackingEvent] = useState(0)
  const [trackingType, setTrackingType] = useState(1)
  const [trackingVideoEvent, setTrackingVideoEvent] = useState(1)

  const [trackingAuto, setTrackingAuto] = useState(0)
  const [trackingVideoEventAuto, setTrackingVideoEventAuto] = useState(0)

  const handlerAddTracker = () => {
    const params = {
      banner_id,
      advertiser_id,
      type: trackingType,
      code: trackingCode,
      event: trackingAuto > 0 ? trackingAuto : trackingEvent,
      video_event:
        (trackingAuto > 0 ? trackingAuto : trackingEvent) === 4
          ? trackingVideoEventAuto > 0
            ? trackingVideoEventAuto
            : trackingVideoEvent
          : null,
    }

    dispatch(bannerTrackerAdd(params)).then((result) => {
      result
        ? toast.success(t('add_campaign_prices_added'))
        : toast.error(t('error'))
    })
  }

  const replaceParams = (code) => {
    setTrackingAuto(0)
    setTrackingVideoEventAuto(0)

    if (trackingEvent === 0) {
      const system = pixelSystem(code)

      if (system.type > 0) {
        setTrackingAuto(system.type)
      }

      if (system.videoType > 0) {
        setTrackingVideoEventAuto(system.videoType)
      }

      if (system.code) {
        code = system.code
      }
    }

    setTrackingCode(code)
  }

  return (
    <Space.Compact style={{ width: '100%' }}>
      {trackingType === 1 ? (
        <Input
          prefix={<LinkOutlined />}
          onChange={(el) => replaceParams(el.target.value)}
        />
      ) : (
        <TextArea
          prefix={<LinkOutlined />}
          onChange={(el) => replaceParams(el.target.value)}
        />
      )}

      <Select
        style={{ width: '150px' }}
        value={trackingType}
        onChange={setTrackingType}
        options={[
          { label: t('pixel'), value: 1 },
          { label: t('tracker'), value: 2 },
        ]}
      />

      <Select
        style={{ width: '180px' }}
        value={trackingEvent}
        onChange={(val) => setTrackingEvent(parseInt(val))}
        options={Object.entries(events.types).map(([value, label]) => ({
          label,
          value: parseInt(value),
        }))}
      />
      {trackingEvent === 4 && (
        <Select
          value={trackingVideoEvent}
          onChange={(val) => setTrackingVideoEvent(parseInt(val))}
          options={Object.entries(events.video).map(([value, label]) => ({
            label,
            value: parseInt(value),
          }))}
        />
      )}

      <Button onClick={handlerAddTracker}>
        <PlusOutlined /> {t('user_sharing_setting_submit')}
      </Button>
    </Space.Compact>
  )
}

export default AddTracker
