import * as React from 'react'

import {
  PublicRounded,
  LocationOnRounded,
  ModeOfTravelRounded,
  SignalCellularAltRounded,
  VerifiedUserRounded,
  ContactEmergencyRounded,
  RouterRounded,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { Accord, AccordItem } from '../Accord'

import Country from './Country'
import Locations from './Locations'
import IpRange from './IpRange'
import VpnTor from './VpnTor'
import Isp from './Isp'
import UserType from './UserType'
import ConnectionType from './ConnectionType'

const Targets = ({ rotation, current }) => {
  const { t } = useTranslation()

  return (
    <>
      <Accord
        items={
          <>
            <AccordItem
              text={t('target_1_country')}
              icon={<PublicRounded />}
              items={<Country rotation={rotation} current={current} />}
            />
            <AccordItem
              text={t('target_1_location')}
              icon={<LocationOnRounded />}
              items={<Locations rotation={rotation} current={current} />}
            />
            <AccordItem
              text={t('target_1_ip_range')}
              icon={<ModeOfTravelRounded />}
              items={<IpRange rotation={rotation} current={current} />}
            />
            <AccordItem
              text={t('target_1_operator')}
              icon={<SignalCellularAltRounded />}
              items={<Isp rotation={rotation} current={current} />}
            />
            <AccordItem
              text={t('target_1_vpn_tor')}
              icon={<VerifiedUserRounded />}
              items={<VpnTor rotation={rotation} current={current} />}
            />
            <AccordItem
              text={t('target_1_user_type')}
              icon={<ContactEmergencyRounded />}
              items={<UserType rotation={rotation} current={current} />}
            />
            <AccordItem
              text={t('target_1_connection_type')}
              icon={<RouterRounded />}
              last={1}
              items={<ConnectionType rotation={rotation} current={current} />}
            />
          </>
        }
      />
    </>
  )
}

export default Targets
