import React from 'react'

import {
  Box,
  Sheet,
  Tooltip,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/joy'

import {
  SourceRounded,
  FolderSpecialRounded,
  DeleteForeverRounded,
} from '@mui/icons-material'

import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../store'
import { advertiserDel } from '../../../../store/advertisers'
import { useAdv } from '../../../hooks/advertisers'
import { Macros, MacrosBtn } from '../Macros'
import DeleteDialog from '../../../layout/DeleteDialog'

const ControlBar = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { adv, replaceAdvertiser } = useAdv()
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const [openMacros, setOpenMacros] = React.useState(false)
  const navigate = useNavigate()

  const deleteAdvert = () => {
    dispatch(
      advertiserDel({
        advertiser_id: adv.advertiser_id,
      }),
    ).then((result) => {
      if (result) {
        replaceAdvertiser({})
        navigate('/advertisers')
      }
    })
    setOpenDeleteDialog(false)
  }

  const AddNewCampaigns = () => {
    return (
      <Tooltip
        arrow
        size="sm"
        color="primary"
        placement="top"
        title={t('create_advertiser_campaign')}
        variant="solid"
      >
        <IconButton
          size="sm"
          component={NavLink}
          to={`/advertisers/campaigns/add/${adv.advertiser_id}`}
        >
          <FolderSpecialRounded sx={{ fontSize: '20px' }} color="primary" />
        </IconButton>
      </Tooltip>
    )
  }

  const RemoveAdvertiser = () => {
    return (
      <Tooltip
        arrow
        size="sm"
        color="primary"
        placement="top"
        title={t('remove_advertiser')}
        variant="solid"
      >
        <IconButton size="sm" onClick={setOpenDeleteDialog}>
          <DeleteForeverRounded sx={{ fontSize: '20px' }} color="danger" />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <>
      <Macros open={openMacros} setOpen={setOpenMacros} />
      <DeleteDialog
        open={{ set: setOpenDeleteDialog, value: openDeleteDialog }}
        description={t('delete_dialog_remove_advertiser')}
        onClick={deleteAdvert}
      />
      <Sheet
        variant="outlined"
        sx={{
          border: 'none',
          transition: 'transform 0.4s',
          overflow: 'hidden',
          px: 2,
          py: 1.5,
          minHeight: '58px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor:
            'var(--variant-softBg, var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #E3EFFB)))',
        }}
      >
        <Typography
          level="title-md"
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <SourceRounded sx={{ mr: '10px' }} />
          <span
            title={adv?.name || ''}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {'name' in adv ? adv.name : <CircularProgress size="sm" />}
          </span>
        </Typography>

        <Box sx={{ gridGap: '10px', display: 'flex' }}>
          <MacrosBtn onClick={setOpenMacros} />
          <AddNewCampaigns />
          <RemoveAdvertiser />
        </Box>
      </Sheet>
    </>
  )
}

export default ControlBar
