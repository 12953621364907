import React, { useCallback, useState } from 'react'

import ListItemComponent from './ListItemComponent'
import Banner from './Banner'
import { useAppDispatch } from '../../../../../store'
import { setActive } from '../../../../../store/campaigns'
import { bannerGetAll } from '../../../../../store/banners'
import { useSelector } from 'react-redux'
import { makeSelectBannersByCampaignId } from '../../../../../store/campaigns/selector'
import { Box, CircularProgress } from '@mui/joy'

const Campaign = ({
  campaign: { campaign_id, name, active },
  advertiserId,
}) => {
  const dispatch = useAppDispatch()
  const banners = useSelector((state) =>
    makeSelectBannersByCampaignId()(state, campaign_id),
  )
  const [isOpen, setIsOpen] = useState(false)
  const addPath = `/advertisers/banner/add/${advertiserId}/${campaign_id}`
  const path = `/advertisers/campaigns/edit/${advertiserId}/${campaign_id}`
  const [isLoading, setIsLoading] = useState(false)

  const getBanners = useCallback(
    (advertiserId, campaign_id) => {
      setIsLoading(true)

      dispatch(
        bannerGetAll({
          active: 1,
          advertiser_id: advertiserId,
          campaign_id,
          campaigns: [campaign_id],
        }),
      ).then((res) => {
        setIsLoading(false)
      })
    },
    [dispatch],
  )

  const onSetActive = useCallback(
    (campaign_id, active) => {
      dispatch(setActive({ active, campaign_id }))
    },
    [dispatch],
  )

  const toggleBannerList = useCallback(() => {
    setIsOpen((bool) => !bool)
    !isOpen && getBanners(advertiserId, campaign_id)
  }, [isOpen, getBanners, advertiserId, campaign_id])

  return (
    <ListItemComponent
      isOpen={isOpen}
      name={name}
      path={path}
      id={campaign_id}
      addPath={addPath}
      active={active}
      onSetActive={onSetActive}
      toggleOpen={toggleBannerList}
      borderLeft={true}
      nestedItems={
        isLoading ? (
          <Box
            sx={{
              display: 'flex',
              height: '30px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress size="sm" />
          </Box>
        ) : banners.length ? (
          banners.map((banner) => (
            <Banner
              key={banner.banner_id}
              banner={banner}
              advertiserId={advertiserId}
            />
          ))
        ) : (
          <></>
        )
      }
    />
  )
}

export default Campaign
