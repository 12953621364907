import * as React from 'react'

import {
  SourceRounded,
  DisplaySettingsRounded,
  AssignmentIndRounded,
  BookmarksRounded,
  DescriptionRounded,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { Accord, AccordItem } from '../../Accord'
import { useSelector } from 'react-redux'

import Advertisers from './Advertisers'
import Custom from './Custom'
import Publishers from './Publishers'
import Category from './Category'
import Templates from './Templates'

const General = ({ withAdvancedSearch }) => {
  const { t } = useTranslation()
  const text = t('sidebar_navigation', { returnObjects: true })
  const role_id = useSelector(
    (state) => state.auth.profileData.profile?.role_id,
  )

  return (
    <>
      <Accord
        items={
          <>
            <AccordItem
              text={`${text['advertisers']} / ${t('campaigns')} / ${t(
                'banners',
              )}`}
              icon={<SourceRounded />}
              items={<Advertisers />}
              last={role_id !== 1}
            />
            {role_id === 1 && (
              <>
                <AccordItem
                  text={text['categories']}
                  icon={<BookmarksRounded />}
                  items={<Category />}
                />
                {withAdvancedSearch !== 0 && (
                  <>
                    <AccordItem
                      text={`${text['publishers']} / ${t('target_2_zones')}`}
                      icon={<AssignmentIndRounded />}
                      items={<Publishers />}
                    />
                    <AccordItem
                      text={t('target_1_custom')}
                      icon={<DisplaySettingsRounded />}
                      items={<Custom />}
                    />
                    <AccordItem
                      text={text['templetes']}
                      icon={<DescriptionRounded />}
                      items={<Templates />}
                      last={1}
                    />
                  </>
                )}
              </>
            )}
          </>
        }
      />
    </>
  )
}

export default General
