import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import {
  ICampaignsAddRequest,
  ICampaignsPricesAddRequest,
  ICampaignsTrackerAddRequest,
  ICampaignsAddRateRequest,
} from '../../api/campaigns/add/types'

import {
  ICampaignsRemoveRequest,
  ICampaignsPricesRemoveRequest,
  ICampaignsTrackerRemoveRequest,
  ICampaignsRateRemoveRequest,
} from '../../api/campaigns/delete/types'

import {
  ICampaignsGetAllRequest,
  ICampaignsPricesGetAllRequest,
  ICampaignsTrackerGetAllRequest,
} from '../../api/campaigns/get/types'

import {
  ICampaignsSetActiveRequest,
  ICampaignsEditRequest,
} from '../../api/campaigns/edit/types'

import {
  campaignAddDone,
  campaignEditDone,
  campaignDeleteDone,
  campaignGetAllDone,
  campaignSetActiveDone,
  campaignFetchStart,
  campaignPricesFetchStart,
  campaignPricesAddDone,
  campaignPricesDeleteDone,
  campaignPricesGetAllDone,
  campaignTrackerFetchStart,
  campaignTrackerAddDone,
  campaignTrackerDeleteDone,
  campaignTrackerGetAllDone,
  campaignRateGetDone,
  campaignRateDeleteDone,
  campaignRateFetchStart,
} from './reducer'

export const campaignAdd =
  (data: ICampaignsAddRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(campaignFetchStart())
      const result = await api.campaignsAdd.create(data)
      dispatch(
        campaignAddDone({
          result: { ...JSON.parse(result.config.data), ...result.data },
          success: true,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(campaignAddDone({ error: e.message, success: false }))
      return false
    }
  }

export const campaignDel =
  (data: ICampaignsRemoveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(campaignFetchStart())
      await api.campaignsDel.remove(data)
      dispatch(campaignDeleteDone({ ...data, error: false }))
      return true
    } catch (e: any) {
      dispatch(campaignDeleteDone({ error: e.message }))
      return false
    }
  }

export const campaignEdit =
  (data: ICampaignsEditRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(campaignFetchStart())
      await api.campaignsEdit.update(data)
      dispatch(campaignEditDone({ data, success: true, error: false }))
      return true
    } catch (e: any) {
      dispatch(campaignEditDone({ success: false, error: e.message }))
      return false
    }
  }

export const campaignGetAll =
  (data: ICampaignsGetAllRequest) =>
  async (dispatch: Dispatch<any>): Promise<Object | boolean> => {
    try {
      dispatch(campaignFetchStart())
      const result = await api.campaignsGet.getAll(data)

      const updatedCampaigns = result.data.rows.map((campaign) => ({
        ...campaign,
        advertiser_id: JSON.parse(result.config.data).advertiser_id,
      }))
      dispatch(campaignGetAllDone({ data: updatedCampaigns, error: false }))
      return result
    } catch (e: any) {
      dispatch(campaignGetAllDone({ error: e.message }))
      return false
    }
  }

export const setActive =
  (data: ICampaignsSetActiveRequest) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      dispatch(campaignFetchStart())
      await api.campaignsEdit.setActive(data)
      dispatch(campaignSetActiveDone(data))
      return true
    } catch (e: any) {
      dispatch(campaignSetActiveDone({ error: e.message }))
      return false
    }
  }

export const campaignPricesAdd =
  (data: ICampaignsPricesAddRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(campaignPricesFetchStart())
      const result = await api.campaignsAdd.pricesCreate(data)
      dispatch(
        campaignPricesAddDone({
          campaign_price_id: result.data.campaign_price_id,
          success: true,
          error: false,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(campaignPricesAddDone({ error: e.message, success: false }))
      return false
    }
  }

export const campaignPricesDel =
  (data: ICampaignsPricesRemoveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(campaignPricesFetchStart())
      await api.campaignsDel.pricesRemove(data)
      dispatch(
        campaignPricesDeleteDone({
          campaign_price_id: data.campaign_price_id,
          error: false,
          success: true,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(campaignPricesDeleteDone({ error: e.message, success: false }))
      return false
    }
  }

export const campaignPricesGetAll =
  (data: ICampaignsPricesGetAllRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(campaignPricesFetchStart())
      const result = await api.campaignsGet.pricesGetAll(data)
      dispatch(
        campaignPricesGetAllDone({
          result: result.data,
          success: true,
          error: false,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(campaignPricesGetAllDone({ error: e.message, success: false }))
      return false
    }
  }

export const campaignTrackerAdd =
  (data: ICampaignsTrackerAddRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(campaignTrackerFetchStart())
      const result = await api.campaignsAdd.trackerCreate(data)
      dispatch(
        campaignTrackerAddDone({
          campaign_tracking_id: result.data.campaign_tracking_id,
          success: true,
          error: false,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(campaignTrackerAddDone({ error: e.message, success: false }))
      return false
    }
  }

export const campaignTrackerDel =
  (data: ICampaignsTrackerRemoveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(campaignTrackerFetchStart())
      await api.campaignsDel.trackerRemove(data)
      dispatch(
        campaignTrackerDeleteDone({
          campaign_tracking_id: data.campaign_tracking_id,
          error: false,
          success: true,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(campaignTrackerDeleteDone({ error: e.message, success: false }))
      return false
    }
  }

export const campaignTrackerGetAll =
  (data: ICampaignsTrackerGetAllRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(campaignTrackerFetchStart())
      const result = await api.campaignsGet.trackerGetAll(data)
      dispatch(
        campaignTrackerGetAllDone({
          result: result.data,
          success: true,
          error: false,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(campaignTrackerGetAllDone({ error: e.message, success: false }))
      return false
    }
  }

export const campaignRateAdd =
  (data: ICampaignsAddRateRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(campaignTrackerFetchStart())
      const result = await api.campaignsAdd.rateCreate(data)
      if ('error' in result.data && result.data.error) {
        throw new Error('API Error')
      }
      dispatch(
        campaignTrackerAddDone({
          campaign_tracking_id: result.data.campaign_rate_id,
          success: true,
          error: false,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(campaignTrackerAddDone({ error: e.message, success: false }))
      return false
    }
  }

// export const campaignRateGet = ()=>{}
export const campaignRateGet =
  (data: ICampaignsTrackerGetAllRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(campaignTrackerFetchStart())
      const result = await api.campaignsGet.rateGet(data)
      dispatch(
        campaignRateGetDone({
          result: result.data,
          success: true,
          error: false,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(campaignRateGetDone({ error: e.message, success: false }))
      return false
    }
  }

export const campaignRateDel =
  (data: ICampaignsRateRemoveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(campaignRateFetchStart())
      await api.campaignsDel.rateRemove(data)
      dispatch(
        campaignRateDeleteDone({
          campaign_id: data.campaign_id,
          error: false,
          success: true,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(campaignRateDeleteDone({ error: e.message, success: false }))
      return false
    }
  }
