import React, { useEffect } from 'react'

import { Sheet } from '@mui/joy'
import { Space, Button } from 'antd'
import { renderChip } from '../../../../../handlers/Chips'
import { useRetarget } from '../../../../../hooks/retarget'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { updateObjToArrItem } from '../utils'

import OrContainer from './OrContainer'

const Retarget = ({ rotation, current }) => {
  const { t } = useTranslation()
  const { retarget, defaultOrItem } = useRetarget()

  const { banners, retargets } = useSelector((state) => ({
    banners: state.rotations.target.rows?.banners,
    retargets: state.rotations.target.rows?.retargets,
  }))

  const currentContentId = current.val?.content_id
  const currentAdvertiser = retargets?.reduce((acc, item) => {
    if (item.metadata.key === currentContentId) {
      const advertiserId = item.metadata.advertiserId
      const matchedBanners =
        banners?.filter(
          (banner) => banner.metadata.advertiserId === advertiserId,
        ) || []
      acc.push(...matchedBanners)
    }
    return acc
  }, [])

  const convertTreeData = (items) =>
    items?.map((item) => ({
      selectable: !item.children,
      value: item.metadata.key || item.metadata.advertiserId,
      title: item.name,
      ...(item.children && { children: convertTreeData(item.children) }),
    }))

  const treeData = convertTreeData(currentAdvertiser)
  const targets = current.val.targets

  useEffect(() => {
    defaultOrItem(targets.retarget)
  }, [defaultOrItem, targets])

  const handlerOnClick = () => {
    return updateObjToArrItem({
      rotation,
      current,
      value: retarget,
      target_name: 'retarget',
    })
  }

  return (
    <>
      <Sheet
        sx={(theme) => ({
          mt: '0',
          borderRadius: '8px',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          [theme.getColorSchemeSelector('light')]: {
            backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
          },
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
          },
        })}
      >
        <Space style={{ marginBottom: '5px' }}>
          <Button size={'small'} onClick={handlerOnClick}>
            {t('include')}
          </Button>
        </Space>

        <Space direction="vertical" style={{ gap: 10 }}>
          {!!retarget &&
            retarget.map((_, key) => (
              <OrContainer
                treeData={treeData[0]?.children}
                key={key}
                itemKey={key}
              />
            ))}
        </Space>
        {renderChip({
          current: current,
          rotation: rotation,
          type: 'retarget',
          label: (i) => {
            const name = []
            name.push(t(`retarget_status_${i.status}`))

            if (i.period > 0) {
              name.push(t(`period_${i.period}`))
            }

            if (i.banner_name) {
              name.push(i.banner_name)
            }

            if (i.first || i.last) {
              name.push(`(${i.first}${i.last ? ` - ${i.last}` : ''})`)
            }

            if (i.items && i.items.length) {
              const itms = i.items
                .map((item) => {
                  const name2 = []
                  if (item.value) {
                    name2.push(`${item.pr}=${item.value}`)

                    if (item.count) {
                      name2.push(`count ${item.condition} ${item.count}`)
                    }

                    if (item.period > 0) {
                      name2.push(t(`period_${item.period}`))
                    }

                    if (item.first || item.last) {
                      name2.push(
                        `(${item.first}${item.last ? ` - ${item.last}` : ''})`,
                      )
                    }
                  }

                  return name2.length ? `(${name2.join(', ')})` : null
                })
                .filter((i) => !!i)

              name.push(itms.length > 0 ? `(${itms.join(' AND ')})` : '')
            }

            return name.join(', ')
          },
        })}
      </Sheet>
    </>
  )
}

export default Retarget
