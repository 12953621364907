import React, { useState } from 'react'
import Container from './Container'

import { Box, CircularProgress } from '@mui/joy'
import { useAppDispatch } from '../../../../../store'
import { zoneGetAll, setActive } from '../../../../../store/zones'

const Zones = () => {
  const dispatch = useAppDispatch()
  const [zones, setZones] = useState({})

  const getZones = (publisher_id) => {
    dispatch(zoneGetAll({ active: 1, publisher_id })).then((result) => {
      if (result.data.rows) {
        setZones({
          ...zones,
          ...{ [publisher_id]: result.data.rows },
        })
      }
    })
  }

  const onChangeActive = (id, active, publisher_id) => {
    dispatch(setActive({ active, publisher_id, id })).then((result) => {
      if (result) {
        getZones(publisher_id)
      }
    })
  }

  const getter = (setter, publisher_id) => {
    setter([{ publisher_id }])
    getZones(publisher_id)
  }

  const template = (adverts, id) => {
    if (!zones[id]) {
      return (
        <Box
          sx={{
            display: 'flex',
            height: '30px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size="sm" />
        </Box>
      )
    }

    if (!(adverts[0].publisher_id === id && zones[id] && !!zones[id].length)) {
      return <></>
    }

    return zones[id].map((item) => (
      <Container
        key={item.id}
        title={item.name}
        path={`/publishers/zones/edit/${adverts[0].publisher_id}/${item.id}`}
        content_id={item.id}
        active={item.active}
        activeBtnOnClick={(id, active) =>
          onChangeActive(id, active, adverts[0].publisher_id)
        }
      />
    ))
  }

  return { getter, template }
}

export default Zones
