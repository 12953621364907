import React, { useState } from 'react'

import { Space, Button, InputNumber } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from '../../../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../../../store'
import {
  campaignRateAdd,
  campaignRateGet,
} from '../../../../../../../store/campaigns'
import DateInputs from './DateInputs'
import Locations from './Locations'
import dayjs from 'dayjs'
import { useFilter } from '../../../../../../hooks/filter'
import { useCookies } from 'react-cookie'

const AddRate = ({ isHasRate }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [, setCookie] = useCookies(['reload'])

  const campaignId = parseInt(useParams().campaignId)
  const dateFormat = 'YYYY-MM-DD'

  const { filter } = useFilter()

  const [rate, setRate] = useState(1.0)
  const [locations, setLocations] = useState([])
  const [dateFrom, setDateFrom] = useState(
    filter.date_from ? dayjs(filter.date_from, dateFormat) : dayjs(),
  )
  const [dateTo, setDateTo] = useState(
    filter.date_to ? dayjs(filter.date_to, dateFormat) : dayjs(),
  )

  const handleInputNumberChange = (value) => {
    setRate(value)
  }

  const handlerAddRate = () => {
    if (locations.length === 0) {
      return toast.error(t('error_countries_empty'))
    }
    if (rate >= 100) {
      return toast.error(t('error_rating_is_out_of_range'))
    }

    const countryCodes = locations
      .map((country) => country.value.toLowerCase())
      .join(',')

    const params = {
      campaignId,
      rate,
      rateDatetimeFrom: dayjs(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
      rateDatetimeTo: dayjs(dateTo).format('YYYY-MM-DD HH:mm:ss'),
      countries: `{${countryCodes}}`,
    }

    dispatch(campaignRateAdd(params)).then((result) => {
      result
        ? toast.success(t('add_campaign_rate_added'))
        : toast.error(t('error'))
      setLocations([])
      dispatch(campaignRateGet({ campaign_id: campaignId }))
    })
    setCookie('reload', new Date().getTime(), { path: '/' })
  }

  return (
    <Space.Compact style={{ width: '100%' }}>
      <InputNumber
        style={{ width: '100px' }}
        min={1.0}
        step={0.1}
        value={rate}
        onChange={handleInputNumberChange}
      />

      <DateInputs
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
      />

      <Locations setLocations={setLocations} locations={locations} />

      <Button onClick={handlerAddRate} disabled={isHasRate}>
        <PlusOutlined /> {t('user_sharing_setting_submit')}
      </Button>
    </Space.Compact>
  )
}

export default AddRate
