import React from 'react'
import { useReport } from '../../../../../hooks/report'

const Body = ({ name }) => {
  const { report } = useReport()
  const { options, reports: items } = report[name] || {}
  const {
    unique = '0',
    order_by: orderBy,
    order_by_key: orderByKey,
    page,
    onPage,
    search = '',
    columns = [],
  } = options || {}

  const filterArrayBySearch = (array) =>
    array.filter((item) =>
      Object.keys(item).some(
        (key) =>
          key.startsWith('customField') &&
          item[key].toString().toLowerCase().includes(search.toLowerCase()),
      ),
    )

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const descendingComparator = (a, b) => {
    if (b[orderByKey] < a[orderByKey]) return -1
    if (b[orderByKey] > a[orderByKey]) return 1
    return 0
  }

  const getComparator = () => {
    return orderBy === 'desc'
      ? (a, b) => descendingComparator(a, b)
      : (a, b) => -descendingComparator(a, b)
  }

  const isAvailable = (key) => !columns.includes(key)

  const Item = ({ value, item }) =>
    isAvailable(item) && <td style={{ textAlign: 'end' }}>{value}</td>

  const tableD = (row) => {
    const columns = Object.keys(row)
      .map((key) => {
        if (
          !['clicks', 'count', 'unique', 'ctr', 'spend', 'frequency'].includes(
            key,
          )
        ) {
          return isAvailable(key) ? <td key={key}>{row[key]}</td> : null
        }
        return false
      })
      .filter((i) => !!i)

    return (
      <tr key={`${Object.values(row).join('-')}-${Object.keys(row).join('-')}`}>
        {columns}
        <Item item={`count`} value={row.count.toLocaleString('ru-RU')} />
        <Item item={`clicks`} value={row.clicks.toLocaleString('ru-RU')} />
        <Item item={`ctr`} value={`${row.ctr > 0 ? row.ctr.toFixed(2) : 0}%`} />
        {unique === '1' && (
          <>
            <Item item={`unique`} value={row.unique.toLocaleString('ru-RU')} />
            <Item
              item={`frequency`}
              value={row.frequency.toLocaleString('ru-RU')}
            />
          </>
        )}

        <Item
          item={`spend`}
          value={`$${row.spend ? row.spend.toFixed(3) : '0'}`}
        />
      </tr>
    )
  }

  const result =
    items &&
    stableSort(filterArrayBySearch(items), getComparator())
      .slice(page * onPage, page * onPage + onPage)
      .map((item) => tableD(item))

  return <tbody>{result}</tbody>
}
export default Body
