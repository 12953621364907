import React, { useState } from 'react'
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@mui/joy'
import { Input, Switch } from 'antd'
import { Add, PermMediaRounded } from '@mui/icons-material'

import FormItem from '../../../../../layout/FormItem'
import { useTranslation } from 'react-i18next'
import { usePub } from '../../../../../hooks/publishers'

const Basic = () => {
  const { t } = useTranslation()
  const { pub, updatePublisher } = usePub()
  const [index, setIndex] = useState(0)
  const active = parseInt(pub.active)

  return (
    <Accordion
      sx={{ borderBottom: 0 }}
      expanded={index === 0}
      onChange={(_, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <PermMediaRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>
          {t('add_advertiser_basic')}
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        {pub.name ? (
          <FormItem
            text={'name'}
            component={
              <Input
                style={{ width: '100%' }}
                value={pub.name || ''}
                onChange={(e) => {
                  updatePublisher('name', e.target.value)
                }}
              />
            }
          />
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '101px',
              }}
            >
              <CircularProgress size="sm" />
            </Box>
          </>
        )}
        {[1, 0].includes(active) && (
          <FormItem
            text={'add_advertiser_basic_active'}
            component={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Switch
                  size="small"
                  checked={active === 1}
                  onChange={(e) => {
                    updatePublisher('active', e ? 1 : 0)
                  }}
                />
              </Box>
            }
          />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default Basic
