import React from 'react'

import { List } from '@mui/joy'
import { listItemButtonClasses } from '@mui/joy/ListItemButton'
import { useAppDispatch } from '../../../../../store'
import { setActive } from '../../../../../store/publishers'
import { useCookies } from 'react-cookie'

import Zones from './Zones'
import Container from './Container'

const Publishers = ({ items }) => {
  const dispatch = useAppDispatch()
  const [, setCookie] = useCookies(['reload'])

  const listSx = () => ({
    '.advertiser_item_name': {
      '--ListItem-plainActiveBg': 'transparent',
      [`& .${listItemButtonClasses.root}`]: {
        borderLeftColor: 'transparent',
      },
    },
    [`& .${listItemButtonClasses.root}`]: {
      borderRadius: 'var(--Button-radius, var(--joy-radius-sm))',
    },
    [`& .${listItemButtonClasses.root}.active`]: {
      backgroundColor:
        'var(--variant-plainActiveBg, var(--joy-palette-neutral-plainActiveBg, var(--joy-palette-neutral-200, #DDE7EE)))',
    },
    '--ListItem-paddingLeft': '16px',
    '--ListItem-startActionWidth': '0px',
    '--ListItem-startActionTranslateX': '-50%',
  })

  const onSetActive = (id, active) => {
    dispatch(setActive({ active, id })).then((result) => {
      if (result) setCookie('reload', new Date().getTime(), { path: '/' })
    })
  }

  const cmpg = Zones()

  return (
    <List size="sm" sx={listSx}>
      {items &&
        items.map((i) => {
          return (
            <Container
              key={i.id}
              title={i.name}
              path={`/publishers/edit/${i.id}`}
              addPath={`/publishers/zones/add/${i.id}`}
              content_id={i.id}
              active={i.active}
              activeBtnOnClick={onSetActive}
              templateItem={cmpg.template}
              getItems={cmpg.getter}
            />
          )
        })}
    </List>
  )
}

export default Publishers
