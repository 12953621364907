import React, { useState } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy/'
import { Add, DataObjectRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Video from './Video'
import Banner from './Banner'

const Basic = () => {
  const { t } = useTranslation()
  const [index, setIndex] = useState(0)

  const out = useSelector((state) => state.zones?.getData?.out)

  return (
    <Accordion
      sx={{ border: 'none' }}
      expanded={index === 0}
      onChange={(_, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <DataObjectRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>{t('vast_codes')}</Box>
      </AccordionSummary>

      <AccordionDetails>
        {out && out.vast && <Video />}
        {out && out.client && <Banner />}
      </AccordionDetails>
    </Accordion>
  )
}

export default Basic
