import React, { useCallback, useState } from 'react'

import { IconButton, Box, ToggleButtonGroup } from '@mui/joy'
import { RemoveRedEyeRounded, VisibilityOffRounded } from '@mui/icons-material'
import FormItem from '../../../../../layout/FormItem'
import { useTranslation } from 'react-i18next'

const VpnTor = ({ rotation, current }) => {
  const { t } = useTranslation()
  const targets = current.val.targets

  const [vpn, setVpn] = useState(targets.anonymous?.vpn?.toString() || '0')
  const [tor, setTor] = useState(targets.anonymous?.tor?.toString() || '0')

  const updateRotationObjTarget = useCallback(
    (rotation, item_id, key, val) =>
      rotation.map((item) => {
        if (item.item_id === item_id) {
          item.targets = { ...item.targets, [key]: val }
        }
        if (item.items) {
          item.items = updateRotationObjTarget(item.items, item_id, key, val)
        }
        return item
      }),
    [],
  )

  const setVpnOrTorData = (type, newValue) => {
    const setValue = type === 'vpn' ? setVpn : setTor
    setValue(newValue)

    const res = {
      vpn: Number(vpn),
      tor: Number(tor),
      [type]: Number(newValue),
    }

    updateRotationObjTarget(rotation.val, current.val.item_id, 'anonymous', res)

    current.val.targets = {
      ...current.val.targets,
      anonymous: res,
    }

    current.set(current.val)
  }

  const ToggleButtonComponent = ({ currentValue, onChange }) => (
    <ToggleButtonGroup
      size="sm"
      color="neutral"
      value={currentValue}
      onChange={(_, val) => onChange(val || '0')}
    >
      <IconButton
        variant="outlined"
        color={currentValue === '1' ? 'primary' : undefined}
        sx={{ px: 2 }}
        value="1"
      >
        <RemoveRedEyeRounded />
      </IconButton>
      <IconButton
        variant="outlined"
        color={currentValue === '2' ? 'danger' : undefined}
        sx={{ px: 2 }}
        value="2"
      >
        <VisibilityOffRounded />
      </IconButton>
    </ToggleButtonGroup>
  )

  return (
    <>
      <Box>
        <FormItem
          text={t('vpn_switch')}
          component={
            <ToggleButtonComponent
              currentValue={vpn}
              onChange={(newValue) => setVpnOrTorData('vpn', newValue)}
            />
          }
        />
        <FormItem
          text={t('tor_switch')}
          component={
            <ToggleButtonComponent
              currentValue={tor}
              onChange={(newValue) => setVpnOrTorData('tor', newValue)}
            />
          }
        />
      </Box>
    </>
  )
}

export default VpnTor
