import React from 'react'
import validator from 'validator'
import Button from '@mui/joy/Button'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../store'
import { loginUser } from '../../../../store/auth/actionCreators'
import { toast } from '../../../layout/JoyToaster'

import EmailInput from './EmailInput'
import PasswordInput from './PasswordInput'

const Form = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const required = (value) => {
    if (!value.toString().trim().length) {
      toast.error(t('error_required'))
      return false
    }

    return true
  }

  const email = (value) => {
    if (!validator.isEmail(value) || value === '') {
      toast.error(t('error_email').replace('{value}', value))
      return false
    }

    return true
  }

  const onLoginSubmit = (event) => {
    event.preventDefault()

    const formElements = event.currentTarget.elements
    const login = formElements.email.value
    const password = formElements.password.value

    if (!required(login) || !required(password) || !email(login)) return

    const user = { login, password }

    dispatch(loginUser(user, t('error_password')))
  }

  return (
    <form onSubmit={onLoginSubmit}>
      <EmailInput />
      <PasswordInput />

      <Button type="submit" fullWidth>
        {t('sign_in')}
      </Button>
    </form>
  )
}

export default Form
