import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  ICategoriesGetAllResponse,
  ICategoriesGetRequest,
  ICategoriesGetResponse,
} from './types'

export const getAll = (): AxiosPromise<ICategoriesGetAllResponse> =>
  axiosInstance.get(Endpoints.CATEGORIES.GET_ALL)

export const get = (
  params: ICategoriesGetRequest,
): AxiosPromise<ICategoriesGetResponse> =>
  axiosInstance.get(`${Endpoints.CATEGORIES.GET}/${params.category_id}`)
