import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  IAdvertisersRemoveRequest,
  IAdvertisersRemoveResponse,
  IAdvertisersRemoveSharingRequest,
} from './types'

export const remove = (
  params: IAdvertisersRemoveRequest,
): AxiosPromise<IAdvertisersRemoveResponse> =>
  axiosInstance.delete(Endpoints.ADVERTISERS.DELETE, { data: { ...params } })

export const removeSharing = (
  params: IAdvertisersRemoveSharingRequest,
): AxiosPromise<IAdvertisersRemoveResponse> =>
  axiosInstance.delete(Endpoints.ADVERTISERS.SHARING_DEL, {
    data: { ...params },
  })
