import React from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Input, Select } from 'antd'
import { Add, ContactEmergencyRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAdv } from '../../../../../hooks/advertisers'
import FormItem from '../../../../../layout/FormItem'

const { TextArea } = Input

const Additional = () => {
  const { t } = useTranslation()
  const { adv, updateAdvertiser } = useAdv()
  const categories = useSelector((state) => state.categories?.getAllData)

  return (
    <Accordion sx={{ border: 'none' }}>
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <ContactEmergencyRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>
          {t('add_advertiser_additional')}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <FormItem
          text={'add_advertiser_additional_contact_name'}
          component={
            <Input
              style={{ width: '100%' }}
              value={adv.contact_name || ''}
              onChange={(e) => {
                updateAdvertiser('contact_name', e.target.value)
              }}
            />
          }
        />

        <FormItem
          text={'add_advertiser_additional_email'}
          component={
            <Input
              sx={{ width: '100%' }}
              value={adv.email || ''}
              onChange={(e) => {
                updateAdvertiser('email', e.target.value)
              }}
            />
          }
        />

        {categories.rows !== null && (
          <FormItem
            text={'add_advertiser_additional_category'}
            component={
              <Select
                style={{ width: '100%' }}
                value={adv.category || 0}
                fieldNames={{ label: 'name', value: 'category_id' }}
                options={[
                  { category_id: 0, name: '---' },
                  ...Object.values(categories.rows),
                ]}
                onChange={(val) => {
                  updateAdvertiser('category', val === '' ? 0 : val)
                }}
              />
            }
          />
        )}

        <FormItem
          text={'add_advertiser_additional_description'}
          component={
            <TextArea
              style={{ width: '100%' }}
              value={adv.description || ''}
              onChange={(val) => {
                updateAdvertiser(
                  'description',
                  val.target.value === '' ? 0 : val.target.value,
                )
              }}
            />
          }
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Additional
