import React, { useEffect } from 'react'

import { Box, CircularProgress } from '@mui/joy'
import ControlBar from './ControlBar'
import Form from './Form'

import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../../store'
import { zoneGet } from '../../../../../store/zones'
import { useZone } from '../../../../hooks/zones'

const Edit = () => {
  const dispatch = useAppDispatch()
  const { zone, replaceZones } = useZone()
  const { pageId, zoneId } = useParams()

  useEffect(() => {
    if (zoneId > 0) {
      dispatch(zoneGet({ id: zoneId, publisher_id: pageId })).then((result) => {
        if (!result.error) {
          replaceZones(result.rows)
        }
      })
    }
  }, [pageId, zoneId, dispatch, replaceZones])

  return !!zone && Object.keys(zone).length ? (
    <Box sx={{ maxWidth: 'clamp(450px, 100dvw, 900px)' }}>
      <ControlBar />
      <Form />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '20dvh',
      }}
    >
      <CircularProgress size="sm" />
    </Box>
  )
}

export default Edit
