import * as React from 'react'
import Button from '@mui/joy/Button'
import Divider from '@mui/joy/Divider'
import DialogTitle from '@mui/joy/DialogTitle'
import DialogContent from '@mui/joy/DialogContent'
import DialogActions from '@mui/joy/DialogActions'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { useTranslation } from 'react-i18next'

export default function DeleteDialog(props) {
  const { t } = useTranslation()

  return (
    <>
      <Modal open={!!props.open.value} onClose={() => props.open.set(false)}>
        <ModalDialog
          sx={{ maxWidth: '500px' }}
          variant="outlined"
          role="alertdialog"
        >
          <DialogTitle>
            <WarningRoundedIcon />
            {t('delete_dialog_title')}
          </DialogTitle>
          <Divider sx={{ mt: 1 }} />
          <DialogContent>{props.description}</DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              onClick={(e) => props.onClick(props.open.set)}
            >
              {t('delete_dialog_confirm')}
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => props.open.set(false)}
            >
              {t('delete_dialog_cancel')}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  )
}
