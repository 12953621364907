import React, { useCallback, useState } from 'react'

import { UserOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { Space, Input, Select, AutoComplete, Button } from 'antd'
import { toast } from '../../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../../store'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  advertiserAddSharing,
  getAllAdvertiserSharings,
} from '../../../../../../store/advertisers'
import { searchUsersForSharing } from '../../../../../../api/advertisers/get'
import debounce from '@mui/utils/debounce'

const SearchUserAndSharing = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { pageId } = useParams()
  const advertiserId = parseInt(pageId)

  const [options, setOptions] = useState([])
  const [user, setUser] = useState('')
  const [level, setLevel] = useState(2)
  const [searching, setSearching] = useState(false)

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async (search) => {
      try {
        const res = await searchUsersForSharing({
          search,
          advertiser_id: advertiserId,
        })
        if (res.data.error) {
          return toast.error(t('error'))
        }
        setOptions(res.data.rows.map((e) => ({ value: e.email })))
      } catch (e) {
        console.error(e)
        toast.error(t('error'))
      } finally {
        setSearching(false)
      }
    }, 200),
    [advertiserId],
  )

  const onSearchUser = (email) => {
    setUser(email)
    if (!email) {
      return setOptions([])
    }
    setSearching(true)
    debouncedSearch(email)
  }

  const handlerAddUser = () => {
    dispatch(
      advertiserAddSharing({
        email: user,
        level: parseInt(level),
        advertiser_id: advertiserId,
      }),
    ).then((result) => {
      result
        ? toast.success(t('add_advertiser_sharing_added'))
        : toast.error(t('user_not_found'))
      if (result) {
        dispatch(getAllAdvertiserSharings({ advertiser_id: advertiserId }))
      }
    })
  }

  return (
    <Space.Compact>
      <AutoComplete
        onSearch={onSearchUser}
        value={user}
        onSelect={setUser}
        options={options}
        style={{ width: '290px' }}
      >
        <Input
          placeholder={t('user_sharing_setting_user')}
          onChange={(el) => setUser(el.target.value)}
          prefix={<UserOutlined />}
          suffix={searching && <LoadingOutlined />}
        />
      </AutoComplete>
      <Select
        style={{ width: '210px' }}
        value={level}
        onChange={(val) => {
          setLevel(val)
        }}
        options={[
          { value: 1, label: t('user_sharing_setting_is_owner') },
          { value: 2, label: t('user_sharing_setting_can_edit') },
          { value: 3, label: t('user_sharing_setting_can_view') },
        ]}
      />
      <Button onClick={handlerAddUser}>
        <PlusOutlined /> {t('user_sharing_setting_submit')}
      </Button>
    </Space.Compact>
  )
}

export default SearchUserAndSharing
