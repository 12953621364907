import React, { useCallback } from 'react'
import { Box, IconButton, Divider } from '@mui/joy'
import {
  CloseRounded,
  CallSplitRounded,
  ShareRounded,
} from '@mui/icons-material'

import Content from './Content'
import { useTranslation } from 'react-i18next'
import { useTab } from '../../../../hooks/tab'
import { toast } from '../../../../layout/JoyToaster'

const buttonStyle = { minWidth: '14px', minHeight: '18px', fontSize: 14 }

const CustomClose = React.memo(({ onClose }) => (
  <IconButton
    onClick={onClose}
    size="sm"
    variant="plain"
    component="a"
    sx={buttonStyle}
  >
    <CloseRounded sx={{ fontSize: 14 }} />
  </IconButton>
))

const CustomUnSplit = React.memo(({ onUnSplit }) => (
  <IconButton
    onClick={onUnSplit}
    size="sm"
    variant="plain"
    component="a"
    sx={buttonStyle}
  >
    <CallSplitRounded sx={{ fontSize: 14 }} />
  </IconButton>
))

const CustomShare = React.memo(({ onShare }) => (
  <IconButton
    onClick={onShare}
    size="sm"
    variant="plain"
    component="a"
    sx={buttonStyle}
  >
    <ShareRounded sx={{ fontSize: 12, mr: '1px' }} />
  </IconButton>
))

const Case = ({ itemKey, value, inSplitter }) => {
  const { t } = useTranslation()
  const { tabs, replaceTabs } = useTab()

  const handleCloseTab = useCallback(() => {
    const current = tabs[itemKey].filter((i) => ![value.key].includes(i.key))
    replaceTabs(itemKey, current)
  }, [itemKey, tabs, replaceTabs, value.key])

  const handleUnSplitTab = useCallback(() => {
    const current = tabs[itemKey].filter((i) => ![value.key].includes(i.key))
    const newName = `${value.key}`
    replaceTabs(itemKey, current)
    replaceTabs(newName, [value], true)
  }, [itemKey, tabs, replaceTabs, value])

  const handleShareTab = useCallback(() => {
    const uri = `http${
      window.location.host.indexOf('localhost') > -1 ? '' : 's'
    }://${window.location.host}/share/statistic/${value.name}`

    try {
      navigator.clipboard.writeText(uri)
      toast.success(t('copied'))
    } catch (err) {
      toast.error(t('error'))
    }
  }, [t, value])

  return inSplitter ? (
    <Box
      variant="outlined"
      color="neutral"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(calc(100vh - var(--Topbar-height)) - 40px)',
        overflowX: 'auto',
      }}
    >
      <Box sx={{ p: 1, display: 'flex', justifyContent: 'end', width: '100%' }}>
        {itemKey !== value.key && (
          <CustomUnSplit onUnSplit={handleUnSplitTab} />
        )}
        <CustomShare onShare={handleShareTab} />
        <CustomClose onClose={handleCloseTab} />
      </Box>
      <Divider />
      <Box sx={{ flex: 1 }}>
        <Content isSplit={true} itemKey={itemKey} value={value} />
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        overflowY: 'auto',
        padding: 0,
        height: 'calc(calc(100vh - var(--Topbar-height)) - 38px)',
      }}
    >
      <Content itemKey={itemKey} value={value} />
    </Box>
  )
}

export default Case
