import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  ICustomFieldsAddRequest,
  ICustomFieldsAddResponse,
  ICustomParamsAddRequest,
  ICustomParamsAddResponse,
} from './types'

export const createField = (
  params: ICustomFieldsAddRequest,
): AxiosPromise<ICustomFieldsAddResponse> =>
  axiosInstance.post(Endpoints.CUSTOM.FIELDS_ADD, params)

export const createParam = (
  params: ICustomParamsAddRequest,
): AxiosPromise<ICustomParamsAddResponse> =>
  axiosInstance.post(Endpoints.CUSTOM.PARAMS_ADD, params)
