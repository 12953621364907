import React, { useEffect, useState } from 'react'

import { Box, CircularProgress } from '@mui/joy'
import ControlBar from './ControlBar'
import Form from './Form'
import TargetContainer from './TargetContainer'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import { rotationGet } from '../../../../store/rotations'
import { useRtt } from '../../../hooks/rotations'

const Loader = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '20dvh',
    }}
  >
    <CircularProgress size="sm" />
  </Box>
)

const Edit = () => {
  const dispatch = useAppDispatch()
  const { rtt, replaceRotation } = useRtt()
  const { pageId } = useParams()
  const parsedPageId = parseInt(pageId, 10)
  const [rotation, setRotation] = useState([])

  useEffect(() => {
    const isValid = !!rtt && (!rtt.id || rtt.id !== parsedPageId)

    if (isValid && parsedPageId > 0) {
      dispatch(rotationGet({ id: parsedPageId })).then((result) => {
        if (!result.error && !!result.rows) {
          replaceRotation(result.rows)
          setRotation(result.rows.rotation)
        }
      })
    }
  }, [parsedPageId, rtt, replaceRotation, dispatch])

  return !!rtt.id ? (
    <>
      <Box sx={{ width: '100%' }}>
        <ControlBar />
        <Form rotation={rotation} />
      </Box>
      <TargetContainer r={{ setRotation }} />
    </>
  ) : (
    <Loader />
  )
}

export default Edit
