import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './frontend/App'
import reportWebVitals from './frontend/reportWebVitals'

import './frontend/index.css'
import { Provider } from 'react-redux'
import { store } from './store'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store} stabilityCheck="never">
      <App />
    </Provider>
  </React.StrictMode>,
)

reportWebVitals()
