import React, { useState } from 'react'

import { Box } from '@mui/joy'
import { Select, Tag, Space } from 'antd'

import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

import { useFilter } from '../../../../../hooks/filter'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const Isp = () => {
  const { t } = useTranslation()
  const { updateFilter } = useFilter()
  const [value, setValue] = useState([])
  const isp = useSelector((state) => state.rotations.target.rows?.isp)

  const include = () => updateFilter('in_isp', value)
  const exclude = () => updateFilter('ex_isp', value)

  const items = isp
    ? Object.values(isp).map((i) => ({ label: i, value: i, key: i }))
    : []

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            gridGap: 10,
            alignItems: 'center',
          }}
        >
          <Select
            placeholder={t('target_1_operator')}
            maxTagCount={'responsive'}
            defaultValue={value}
            allowClear
            mode="multiple"
            style={{ width: '100%' }}
            loading={!isp}
            options={items}
            tagRender={({ label, value, closable, onClose }) => {
              const onPreventMouseDown = (event) => {
                event.preventDefault()
                event.stopPropagation()
              }

              return (
                <Tag
                  key={`${label}-${value}`}
                  onMouseDown={onPreventMouseDown}
                  closable={closable}
                  onClose={onClose}
                  style={{
                    marginInlineEnd: 4,
                    display: 'flex',
                  }}
                >
                  <Space style={{ gridGap: '4px', mr: '4px' }}>{label}</Space>
                </Tag>
              )
            }}
            onChange={(_, newValue) => setValue(newValue)}
          />
        </Box>
        <IncludeExcludeBtn include={include} exclude={exclude} />
      </Box>
    </>
  )
}

export default Isp
