import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import {
  IAdvertisersAddRequest,
  IAdvertisersAddSharingRequest,
} from '../../api/advertisers/add/types'

import {
  IAdvertisersRemoveRequest,
  IAdvertisersRemoveSharingRequest,
} from '../../api/advertisers/delete/types'

import {
  IAdvertisersGetAllRequest,
  IAdvertisersGetAllSharingRequest,
} from '../../api/advertisers/get/types'

import {
  IAdvertisersSetActiveRequest,
  IAdvertisersEditRequest,
} from '../../api/advertisers/edit/types'

import {
  advertiserAddDone,
  advertiserGetAllDone,
  advertiserSetActiveDone,
  advertiserDeleteDone,
  advertiserEditDone,
  advertiserFetchStart,
  advertiserSharingFetchStart,
  advertiserAddSharingDone,
  advertiserGetAllSharingDone,
  advertiserSharingDeleteDone,
} from './reducer'

export const getAllAdvertisers =
  (data: IAdvertisersGetAllRequest) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      dispatch(advertiserFetchStart())
      const result = await api.advertisersGet.getAll(data)
      dispatch(advertiserGetAllDone(result.data))
    } catch (e: any) {
      dispatch(advertiserGetAllDone({ error: e.message }))
    }
  }

export const advertiserAdd =
  (data: IAdvertisersAddRequest) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      dispatch(advertiserFetchStart())
      const result = await api.advertisersAdd.create(data)
      const advertiserData = {
        ...result.data,
        ...JSON.parse(result.config.data),
      }
      dispatch(
        advertiserAddDone({
          result: advertiserData,
          success: true,
          error: false,
        }),
      )
      return { advertiser_id: advertiserData.advertiser_id }
    } catch (e: any) {
      dispatch(advertiserAddDone({ error: e.message, success: false }))
      return false
    }
  }

export const advertiserDel =
  (data: IAdvertisersRemoveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      await api.advertisersDel.remove(data)
      dispatch(advertiserDeleteDone({ ...data, error: false }))
      return true
    } catch (e: any) {
      dispatch(advertiserDeleteDone({ error: e.message }))
      return false
    }
  }

export const setActive =
  (data: IAdvertisersSetActiveRequest) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      dispatch(advertiserFetchStart())
      await api.advertisersEdit.setActive(data)
      const { active, advertiser_id } = data
      dispatch(advertiserSetActiveDone({ active, advertiser_id, error: false }))
    } catch (e: any) {
      dispatch(advertiserSetActiveDone({ error: e.message }))
    }
  }

export const advertisersEdit =
  (data: IAdvertisersEditRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(advertiserFetchStart())
      await api.advertisersEdit.update(data)
      dispatch(advertiserEditDone({ ...data, success: true, error: false }))
      return true
    } catch (e: any) {
      dispatch(advertiserEditDone({ success: false, error: e.message }))
      return false
    }
  }

export const advertiserAddSharing =
  (data: IAdvertisersAddSharingRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(advertiserSharingFetchStart())
      const result = await api.advertisersAdd.sharing(data)
      const advertiserData = {
        ...result.data,
        ...JSON.parse(result.config.data),
      }
      dispatch(
        advertiserAddSharingDone({ result: advertiserData, error: false }),
      )
      return true
    } catch (e: any) {
      dispatch(advertiserAddSharingDone({ error: e.message }))
      return false
    }
  }

export const sharingDel =
  (data: IAdvertisersRemoveSharingRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(advertiserSharingFetchStart())
      await api.advertisersDel.removeSharing(data)
      dispatch(advertiserSharingDeleteDone({ ...data, error: false }))
      return true
    } catch (e: any) {
      dispatch(advertiserSharingDeleteDone({ error: e.message }))
      return false
    }
  }

export const getAllAdvertiserSharings =
  (data: IAdvertisersGetAllSharingRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(advertiserSharingFetchStart())
      const result = await api.advertisersGet.getAllSharings(data)
      dispatch(advertiserGetAllSharingDone(result.data))
      return true
    } catch (e: any) {
      dispatch(advertiserGetAllSharingDone({ error: e.message }))
      return false
    }
  }
