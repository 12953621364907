import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  IZonesSetActiveRequest,
  IZonesSetActiveResponse,
  IZonesEditRequest,
  IZonesEditResponse,
} from './types'

export const setActive = (
  params: IZonesSetActiveRequest,
): AxiosPromise<IZonesSetActiveResponse> =>
  axiosInstance.patch(Endpoints.ZONES.SET_ACTIVE, params)

export const update = (
  params: IZonesEditRequest,
): AxiosPromise<IZonesEditResponse> =>
  axiosInstance.put(Endpoints.ZONES.EDIT, params)
