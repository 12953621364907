import React, { useState } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Add, PersonRounded } from '@mui/icons-material'
import { Input, Switch } from 'antd'
import { useTranslation } from 'react-i18next'

import FormItem from '../../../layout/FormItem'

const Basic = ({ values }) => {
  const { t } = useTranslation()
  const [index, setIndex] = useState(0)
  const { set, arr } = values

  return (
    <Accordion
      expanded={index === 0}
      onChange={(_, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <PersonRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>
          {t('add_advertiser_basic')}
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <FormItem
          text={'add_advertiser_basic_name'}
          component={
            <Input
              style={{ width: '100%' }}
              value={arr.name || ''}
              onChange={(e) => {
                set({
                  ...arr,
                  name: e.target.value,
                })
              }}
            />
          }
        />

        <FormItem
          text={'add_advertiser_basic_active'}
          component={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Switch
                style={{ marginLeft: 'initial', marginRight: 'auto' }}
                size="small"
                checked={arr.active === 1}
                onChange={(e) => {
                  set({
                    ...arr,
                    active: e ? 1 : 0,
                  })
                }}
              />
            </Box>
          }
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Basic
