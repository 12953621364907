import React, { useEffect } from 'react'

import { Box, CircularProgress } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useAdv } from '../../../hooks/advertisers'
import api from '../../../../api'

import ControlBar from './ControlBar'
import Form from './Form'

const Edit = () => {
  const { adv, replaceAdvertiser } = useAdv()
  const { pageId } = useParams()
  const parsedPageId = parseInt(pageId, 10)

  useEffect(() => {
    const isValid = !!adv
      ? !adv.advertiser_id || adv.advertiser_id !== parsedPageId
      : true

    if (isValid && parsedPageId > 0) {
      api.advertisersGet.get({ advertiser_id: parsedPageId }).then((result) => {
        if (!result.data.error) {
          replaceAdvertiser(result.data.rows)
        }
      })
    }
  }, [parsedPageId, adv, replaceAdvertiser])

  return !!adv &&
    Object.keys(adv).length &&
    adv.advertiser_id === parsedPageId ? (
    <Box sx={{ width: '100%' }}>
      <ControlBar />
      <Form />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '20dvh',
      }}
    >
      <CircularProgress size="sm" />
    </Box>
  )
}

export default Edit
