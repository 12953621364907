import React, { useState } from 'react'
import { Box, Sheet } from '@mui/joy'

import { renderChip } from '../../../../../handlers/Chips'
import { updateObjToArrItem } from '../utils'
import { useSelector } from 'react-redux'

import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'
import TreeView from '../../../../../layout/TreeView'

const Zones = ({ rotation, current }) => {
  const [selectedZones, setSelectedZones] = useState([])
  const zones = useSelector((state) => state.rotations.target.rows.zones)

  const handleIncludeExclude = (target_name) => {
    updateObjToArrItem({ rotation, current, value: selectedZones, target_name })
  }

  return (
    <>
      <Sheet
        sx={(theme) => ({
          mt: '0',
          borderRadius: '8px',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          [theme.getColorSchemeSelector('light')]: {
            backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
          },
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
          },
        })}
      >
        <TreeView
          items={zones}
          multiSelect={true}
          onSelect={(e) => {
            setSelectedZones(e)
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gridGap: 10,
            mt: 2,
            justifyContent: 'end',
          }}
        >
          <IncludeExcludeBtn
            include={() => {
              handleIncludeExclude('zones')
            }}
            exclude={() => {
              handleIncludeExclude('ex_zones')
            }}
          />
        </Box>
      </Sheet>
      {renderChip({
        current: current,
        rotation: rotation,
        type: 'zones',
        label: (i) => `zone = '${i}'`,
        ex_label: (i) => `zone != '${i}'`,
      })}
    </>
  )
}

export default Zones
