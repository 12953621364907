import React, { useState } from 'react'
import { Box, Sheet } from '@mui/joy'
import { Select, Tag, Space } from 'antd'

import { renderChip } from '../../../../../handlers/Chips'
import { useTranslation } from 'react-i18next'
import { updateItem } from '../utils'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const DeviceFormat = ({ rotation, current }) => {
  const { t } = useTranslation()
  const deviceText = t('device', { returnObjects: true })
  const [value, setValue] = useState({})

  const handleIncludeExclude = (target_name) => {
    updateItem({ rotation, current, value: value, target_name })
  }

  const deviceFormat = [
    { value: deviceText['_1'], key: '1' },
    { value: deviceText['_2'], key: '2' },
    { value: deviceText['_3'], key: '3' },
    { value: deviceText['_4'], key: '4' },
    { value: deviceText['_5'], key: '5' },
    { value: deviceText['_6'], key: '6' },
    { value: deviceText['_7'], key: '7' },
    { value: deviceText['_8'], key: '8' },
  ]

  return (
    <>
      <Sheet
        sx={(theme) => ({
          mt: '0',
          borderRadius: '8px',
          p: 2,
          display: 'flex',
          [theme.getColorSchemeSelector('light')]: {
            backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
          },
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
          },
        })}
      >
        <Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              gridGap: 10,
              alignItems: 'center',
            }}
          >
            <Select
              placeholder={t('target_1_device')}
              maxTagCount={'responsive'}
              allowClear
              mode="multiple"
              style={{ width: '100%' }}
              defaultValue={value.value}
              options={deviceFormat}
              fieldNames={{ label: 'value', value: 'key' }}
              tagRender={({ label, value, closable, onClose }) => {
                const onPreventMouseDown = (event) => {
                  event.preventDefault()
                  event.stopPropagation()
                }

                return (
                  <Tag
                    key={`${label}-${value}`}
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={onClose}
                    style={{
                      marginInlineEnd: 4,
                      display: 'flex',
                    }}
                  >
                    <Space style={{ gridGap: '4px', mr: '4px' }}>{label}</Space>
                  </Tag>
                )
              }}
              onChange={(newValue) => {
                setValue(
                  newValue.map((val) => ({
                    value: val,
                  })),
                )
              }}
            />
          </Box>

          <IncludeExcludeBtn
            include={() => {
              handleIncludeExclude('device_format')
            }}
            exclude={() => {
              handleIncludeExclude('ex_device_format')
            }}
          />
        </Box>
      </Sheet>
      {renderChip({
        current: current,
        rotation: rotation,
        type: 'device_format',
        label: (i) => deviceText[`_${i}`],
      })}
    </>
  )
}

export default DeviceFormat
