import { createSelector } from 'reselect'
import { Campaign } from '../campaigns/types'
import { RootState } from '..'

const selectCampaignsByAdvertiserId = (
  state: RootState,
  advertiser_id: number,
): Campaign[] =>
  Object.values(state.campaigns.allData.campaignsById).filter(
    (campaign) => campaign.advertiser_id === advertiser_id,
  )

export const makeSelectCampaignsByAdvertiserId = () =>
  createSelector([selectCampaignsByAdvertiserId], (campaigns) => campaigns)

export const selectAdvertisersById = createSelector(
  [(state) => state.advertisers.allData.advertisersById],
  (advertisersById) => Object.values(advertisersById),
)

export const selectIsLoading = createSelector(
  [(state) => state.advertisers.allData.isLoading],
  (isLoading) => isLoading,
)
