import React from 'react'

import { Box, Chip, ChipDelete, AspectRatio, ListItemDecorator } from '@mui/joy'

import countryList from 'react-select-country-list'
import { random } from '../../frontend/pages/Rotation/Edit/Modal/utils'
import { useTranslation } from 'react-i18next'
// import { useAppDispatch } from '../../store'
// import { rotationTarget } from '../../store/rotations'
import { useSelector } from 'react-redux'

export const Chips = ({ item }) => {
  const { t } = useTranslation()
  // const dispatch = useAppDispatch()
  const deviceText = t('device', { returnObjects: true })
  const connectionTypeText = t('connection_type', { returnObjects: true })
  const userTypesText = t('user_types', { returnObjects: true })

  const language = useSelector((state) => state.rotations.target.rows?.lang)
  // const isLoading = useSelector(state => state.rotations.target?.isLoading)

  // useEffect(() => {
  // 	if (!language && !isLoading) {
  // 		dispatch(rotationTarget())
  // 	}
  // }, [dispatch])

  const { targets, limits, cappings } = item

  const createChip = (key, color, label, prefix = '') => (
    <Chip
      key={key}
      sx={{ mr: 1, mt: 1, pr: 2 }}
      size="lg"
      variant="soft"
      color={color}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gridGap: '5px',
          fontSize: 14,
        }}
      >
        {prefix}
        {label}
      </Box>
    </Chip>
  )

  const renderItems = (items, type, isExcluded = false) => {
    return items.map((item, index) => {
      const color = isExcluded ? 'danger' : 'primary'
      const key = `${type}-${index}-${color}`
      let label = ''
      let prefix = null

      switch (type) {
        case 'country':
          label = countryList().getLabel(item)
          prefix = <FlagImg countryCode={item} />
          break
        case 'ip_range':
          label = `${item.from}${item.to ? ` - ${item.to}` : ''}`
          break
        case 'browser':
          label = `${item.name} ${item.condition} ${
            item.version > 0 ? item.version : ''
          }`
          break
        case 'language':
          label = language ? language[item] : ''
          break
        case 'os':
          label = `${item.name} ${item.condition} ${
            item.version > 0 ? item.version : ''
          }`
          break
        case 'device_format':
          label = deviceText[`_${item}`]
          break
        case 'vendor':
          label = `vendor ${isExcluded ? '!' : ''}= '${item}'`
          break
        case 'isp':
          label = `isp ${isExcluded ? '!' : ''}= '${item}'`
          break
        case 'connection_type':
          label = connectionTypeText[`_${item}`]
          break
        case 'user_type':
          label = userTypesText[`_${item}`]
          break
        case 'week':
          label = `${t(`week_${item.week}`)} ${
            item.start ? `, ${item.start}${item.end ? `-${item.end}` : ''}` : ''
          }`
          break
        case 'custom':
          label = `${item.name} ${
            item.condition === '='
              ? `${isExcluded ? '!' : ''}${item.condition} '${item.value}'`
              : `ILIKE '%${item.value}%'`
          }`
          break
        case 'referer':
          label = `referer ${
            item.condition === '='
              ? `${isExcluded ? '!' : ''}${item.condition} '${item.value}'`
              : `ILIKE '%${item.value}%'`
          }`
          break
        case 'date':
          label = `${item.from ? `${t('from')}: ${item.from}` : ''}
							${item.from && item.to ? ' - ' : ''}
							${item.to ? `${t('to')}: ${item.to}` : ''}`
          break
        case 'zones':
          label = `zone ${isExcluded ? '!' : ''}= '${item}'`
          break
        case 'impressions_total':
        case 'impressions_daily':
        case 'clicks_total':
        case 'clicks_daily':
        case 'budget_total':
        case 'budget_daily':
          label = `${t(type)}=${item}`
          break
        case 'capping':
          label = `
              ${t(`capping_type_${item.cappingType}`)},
              ${item.amount > 0 && ` ${t('capping_amount')}=${item.amount}`},
              ${
                item.period > 0 &&
                ` ${t('capping_period')}=${item.period} ${t(
                  `capping_period_${item.periodType}`,
                )}`
              },
              ${t(`capping_subject_${item.subject}`)}
              ${t(`capping_flat`)}=${item.flat ? 'true' : 'false'}
            `
          break
        default:
          console.error('Unknown type')
      }

      return createChip(key, color, label, prefix)
    })
  }

  const renderCategory = (category) => {
    const items = targets[category]
    const exItems = targets[`ex_${category}`]

    if (!items && !exItems) {
      return null
    }

    return (
      <>
        {items && renderItems(items, category)}
        {exItems && renderItems(exItems, category, true)}
      </>
    )
  }

  const renderLocations = (locations, color) => {
    return locations.map((location) =>
      createChip(
        random(),
        color,
        `${countryList(location.country).getLabel(location.country)}, ${
          location.region ? `${location.region}, ` : ''
        }${location.label}`,
        <FlagImg countryCode={location.country} />,
      ),
    )
  }

  const locations = () => {
    const hasLocations =
      'locations' in targets &&
      ((!!targets.locations.regions && targets.locations.regions.length > 0) ||
        (!!targets.locations.cities && targets.locations.cities.length > 0))

    const hasExLocations =
      'ex_locations' in targets &&
      ((!!targets.ex_locations.regions &&
        targets.ex_locations.regions.length > 0) ||
        (!!targets.ex_locations.cities &&
          targets.ex_locations.cities.length > 0))

    if (!hasLocations && !hasExLocations) {
      return null
    }

    return hasLocations || hasExLocations ? (
      <>
        {hasLocations &&
          renderLocations(
            [
              ...(targets.locations.regions && targets.locations.regions.length
                ? targets.locations.regions
                : []),
              ...(targets.locations.cities && targets.locations.cities.length
                ? targets.locations.cities
                : []),
            ],
            'primary',
          )}
        {hasExLocations &&
          renderLocations(
            [
              ...(targets.ex_locations.regions &&
              targets.ex_locations.regions.length
                ? targets.ex_locations.regions
                : []),
              ...(targets.ex_locations.cities &&
              targets.ex_locations.cities.length
                ? targets.ex_locations.cities
                : []),
            ],
            'danger',
          )}
      </>
    ) : null
  }

  const vpnTor = () => {
    let vpn = '',
      tor = ''
    if (targets.anonymous) {
      vpn = targets.anonymous.vpn
        ? createChip(
            random(),
            targets.anonymous.vpn === 1 ? 'primary' : 'danger',
            t('vpn_switch'),
            '',
          )
        : ''

      tor = targets.anonymous.tor
        ? createChip(
            random(),
            targets.anonymous.tor === 1 ? 'primary' : 'danger',
            t('tor_switch'),
            '',
          )
        : ''
    }

    if (vpn === '' && tor === '') {
      return null
    }

    return [vpn, tor]
  }

  const limitsViews = (category) => {
    const totalItems = limits[`${category}_total`]
    const dailyItems = limits[`${category}_daily`]

    if (!totalItems && !dailyItems) {
      return null
    }

    return (
      <>
        {!!totalItems && renderItems([totalItems], `${category}_total`)}
        {!!dailyItems && renderItems([dailyItems], `${category}_daily`)}
      </>
    )
  }

  const capping = () => {
    return cappings.length ? renderItems(cappings, `capping`) : null
  }

  return { renderCategory, limitsViews, vpnTor, locations, capping }
}

export const FlagImg = ({ countryCode, sx }) => (
  <ListItemDecorator
    sx={{ width: '20px', minInlineSize: '24px!important', ...sx }}
  >
    <AspectRatio ratio="1" sx={{ minWidth: '15px', borderRadius: '50%' }}>
      <img
        loading="lazy"
        width="20"
        src={
          countryCode
            ? `https://flagcdn.com/${countryCode.toLowerCase()}.svg`
            : ''
        }
        srcSet={
          countryCode
            ? `https://flagcdn.com/${countryCode.toLowerCase()}.svg 2x`
            : ''
        }
        alt=""
      />
    </AspectRatio>
  </ListItemDecorator>
)

export const renderChip = ({
  item,
  type,
  removeElement,
  label,
  ex_label,
  rotation,
  current,
}) => {
  if (!ex_label) {
    ex_label = label
  }

  if (!item) {
    if (type === 'regions' || type === 'cities') {
      item = current.val.targets.locations
        ? current.val.targets.locations
        : { region: [], cities: [] }
    } else {
      item = current.val.targets
    }
  }

  const removeItem = (rotation, item_id, val, type) => {
    const obj = (item[type] ? [...item[type]] : [])
      .filter((x) => ![val].includes(x))
      .filter((value, index, array) => array.indexOf(value) === index)

    current.val.targets[type] = obj
    current.set(current.val)

    return (
      rotation &&
      rotation.map((i) => {
        if (i.item_id === item_id) {
          i.targets[type] = i.targets[type].filter((x) => ![val].includes(x))
        }

        if (i.items) i.items = removeItem(i.items, item_id, val, type)
        return i
      })
    )
  }

  const removeElementDefault = (item_id, val, type) =>
    rotation.set(removeItem(rotation.val, item_id, val, type))

  const render = (i, type, removeElement) => {
    const isInclude = !type.includes('ex_')
    const color = isInclude ? 'primary' : 'danger'

    return (
      <Chip
        key={`${type}-${random()}`}
        size="lg"
        sx={{ mr: 1, mb: 1, px: 2 }}
        variant="soft"
        color={color}
        endDecorator={
          <ChipDelete
            onDelete={() => {
              removeElement
                ? removeElement({ i, type })
                : removeElementDefault(current.val.item_id, i, type)
            }}
          />
        }
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            pr: 1,
            fontSize: 14,
          }}
        >
          {isInclude ? label(i) : ex_label(i)}
        </Box>
      </Chip>
    )
  }

  const hasInn = type in item && item[type].length > 0
  const hasEx = `ex_${type}` in item && item[`ex_${type}`].length > 0

  return (
    <Box sx={{ mt: hasInn || hasEx ? 2.5 : 0 }}>
      {hasInn && item[type].map((inn) => render(inn, `${type}`, removeElement))}
      {hasEx &&
        item[`ex_${type}`].map((ex) => render(ex, `ex_${type}`, removeElement))}
    </Box>
  )
}
