import React, { useCallback, useState } from 'react'
import { Box } from '@mui/joy'
import { useSelector } from 'react-redux'
import { useFilter } from '../../../../../hooks/filter'
import TreeView from '../../../../../layout/TreeView'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const Publishers = () => {
  const { updateFilter } = useFilter()

  const rows = useSelector((state) => state.rotations.target.rows?.zones)

  const [publishers, setPublishers] = useState([])
  const [zones, setZones] = useState([])

  const handleIncludeExclude = useCallback(
    (action, type, items) => {
      if (items.length) {
        updateFilter(
          `${action}_${type}`,
          items.filter((item) => item.value),
        )
      }
    },
    [updateFilter],
  )

  const handleSelectionChange = useCallback((_, meta) => {
    if (!meta) return

    const newPublishers = []
    const newZones = []

    meta.forEach((item) => {
      const newItem = {
        name: item.name,
        value: item.zoneId || item.publisherId,
      }

      if (item.type === 'publishers') {
        newPublishers.push(newItem)
      } else if (item.type === 'zones') {
        newZones.push(newItem)
      }
    })
    if (newPublishers.length) setPublishers(newPublishers)
    else setZones(newZones)
  }, [])

  return (
    <>
      <TreeView
        items={rows}
        multiSelect={true}
        selectedBranch={true}
        onSelect={handleSelectionChange}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: '10px',
          justifyContent: 'end',
        }}
      >
        <IncludeExcludeBtn
          include={() => {
            handleIncludeExclude('in', 'publisher', publishers)
            handleIncludeExclude('in', 'zone', zones)
          }}
          exclude={() => {
            handleIncludeExclude('ex', 'publisher', publishers)
            handleIncludeExclude('ex', 'zone', zones)
          }}
        />
      </Box>
    </>
  )
}

export default Publishers
