import React from 'react'

import Form from './Form'
import Submit from './Submit'

import { toast } from '../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../store'
import { categoryEdit } from '../../../../store/categories'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'

const BaseInformation = ({ rows, setRows }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { category_id, name } = rows
  const [, setCookie] = useCookies(['reload'])

  const handlerForm = (event) => {
    event.preventDefault()
    dispatch(
      categoryEdit({
        category_id,
        name,
      }),
    ).then((result) => {
      result
        ? toast.success(t('edit_category_successfully'))
        : toast.error(t('error'))

      setCookie('reload', new Date().getTime(), { path: '/' })
    })
  }

  return (
    <form onSubmit={handlerForm}>
      <Form
        values={{
          set: setRows,
          arr: rows,
        }}
      />
      <Submit />
    </form>
  )
}

export default BaseInformation
