import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UsersState {
  addData: {
    user_id: number | null
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  editData: {
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  deleteData: {
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  getAllData: {
    rows: object | null
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  getData: {
    user_id: number | null
    email: string
    role_id: number | null
    created_on: string
    last_login: string
    active: number | null

    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  activeData: {
    isLoading: boolean
    user_id: number | null
    active: number | null
    success: boolean | null
    error: string | boolean | null
  }
}

const initialState: UsersState = {
  addData: {
    user_id: null,
    isLoading: false,
    success: false,
    error: null,
  },

  editData: {
    success: false,
    isLoading: false,
    error: null,
  },

  deleteData: {
    success: false,
    isLoading: false,
    error: null,
  },

  getAllData: {
    rows: [],
    isLoading: false,
    success: false,
    error: null,
  },

  getData: {
    user_id: null,
    email: '',
    role_id: null,
    created_on: '',
    last_login: '',
    active: null,

    isLoading: false,
    success: false,
    error: null,
  },

  activeData: {
    isLoading: false,
    user_id: null,
    active: null,
    success: false,
    error: null,
  },
}

export const reducer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // Add advertiser
    userAddStart: (state): UsersState => ({
      ...state,
      addData: {
        ...state.addData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),
    userAddSuccess: (state, action: PayloadAction<void>): UsersState => ({
      ...state,
      addData: {
        ...state.addData,
        isLoading: false,
        success: true,
        error: null,
      },
    }),
    userAddFailure: (state, action: PayloadAction<string>): UsersState => ({
      ...state,
      addData: {
        ...state.addData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),

    // Edit advertiser
    userEditStart: (state): UsersState => ({
      ...state,
      editData: {
        ...state.editData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),
    userEditSuccess: (state, action: PayloadAction<void>): UsersState => ({
      ...state,
      editData: {
        ...state.editData,
        success: true,
        error: false,
        isLoading: false,
      },
    }),
    userEditFailure: (state, action: PayloadAction<string>): UsersState => ({
      ...state,
      editData: {
        ...state.editData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),

    // Edit advertiser
    userDeleteStart: (state): UsersState => ({
      ...state,
      deleteData: {
        ...state.deleteData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),
    userDeleteSuccess: (state, action: PayloadAction<void>): UsersState => ({
      ...state,
      deleteData: {
        ...state.deleteData,
        success: true,
        error: false,
        isLoading: false,
      },
    }),
    userDeleteFailure: (state, action: PayloadAction<string>): UsersState => ({
      ...state,
      deleteData: {
        ...state.deleteData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),

    // Get All advertiser
    userGetAllStart: (state): UsersState => ({
      ...state,
      getAllData: {
        ...state.getAllData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),

    userGetAllSuccess: (state, action: PayloadAction<any>): UsersState => ({
      ...state,
      getAllData: {
        ...state.getAllData,
        rows: action.payload.result.rows,
        success: true,
        error: false,
        isLoading: false,
      },
    }),

    userGetAllFailure: (state, action: PayloadAction<string>): UsersState => ({
      ...state,
      getAllData: {
        ...state.getAllData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),

    // Get advertiser by id
    userGetStart: (state): UsersState => ({
      ...state,
      getData: {
        ...state.getData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),
    userGetSuccess: (state, action: PayloadAction<any>): UsersState => ({
      ...state,
      getData: {
        ...state.getData,
        success: true,
        error: false,
        isLoading: false,
        ...action.payload.result,
      },
    }),
    userGetFailure: (state, action: PayloadAction<string>): UsersState => ({
      ...state,
      getData: {
        ...state.getData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),

    // Set active
    userSetActiveStart: (state): UsersState => ({
      ...state,
      activeData: {
        ...state.activeData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),
    userSetActiveSuccess: (state, action: PayloadAction<void>): UsersState => ({
      ...state,
      activeData: {
        ...state.activeData,
        success: true,
        error: false,
        isLoading: false,
      },
    }),
    userSetActiveFailure: (
      state,
      action: PayloadAction<string>,
    ): UsersState => ({
      ...state,
      activeData: {
        ...state.activeData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),
  },
})

export const {
  userAddStart,
  userAddSuccess,
  userAddFailure,
  userGetAllSuccess,
  userGetAllFailure,
  userGetAllStart,
  userSetActiveStart,
  userSetActiveSuccess,
  userSetActiveFailure,
  userDeleteStart,
  userDeleteSuccess,
  userDeleteFailure,
  userEditStart,
  userEditSuccess,
  userEditFailure,
  userGetStart,
  userGetSuccess,
  userGetFailure,
} = reducer.actions

export default reducer.reducer
