import { useState } from 'react'

import { Input, Select, Space } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Search = ({ setSearchCandidate, setAdvertisersListCategory }) => {
  const { t } = useTranslation()
  const categories = useSelector((state) => state.categories?.getAllData?.rows)
  const options = [{ label: t('All'), value: 0 }]

  const onChangeAdvertCategory = (value) => {
    setListCategory(value)
    setAdvertisersListCategory(value)
  }
  const handleSearchChange = (event) => {
    setSearchCandidate(event.target.value)
  }
  const [listCategory, setListCategory] = useState(options[0].value)

  categories &&
    Object.values(categories).forEach((selectItem) => {
      options.push({
        label: selectItem.name,
        value: parseInt(selectItem.category_id),
      })
    })

  return (
    <Space.Compact style={{ padding: '10px', paddingTop: '10px' }}>
      <Input
        size={'small'}
        style={{ width: '60%' }}
        placeholder={t('search')}
        onChange={(searchCandidate) => {
          handleSearchChange(searchCandidate)
        }}
      />
      <Select
        size={'small'}
        style={{ width: '42%' }}
        options={options}
        placeholder={t('category')}
        value={listCategory}
        onChange={onChangeAdvertCategory}
      />
    </Space.Compact>
  )
}

export default Search
