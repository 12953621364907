import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import {
  IRotationsAddRequest,
  IRotationsDuplicateRequest,
} from '../../api/rotations/add/types'
import {
  IRotationsGetRequest,
  IRotationsGetAllRequest,
} from '../../api/rotations/get/types'
import { IRotationsRemoveRequest } from '../../api/rotations/delete/types'
import {
  IRotationsEditRequest,
  IRotationsSetActiveRequest,
  IRotationsTargetRequest,
} from '../../api/rotations/edit/types'

import {
  rotationAddStart,
  rotationAddSuccess,
  rotationAddFailure,
  rotationEditStart,
  rotationEditSuccess,
  rotationEditFailure,
  rotationDeleteStart,
  rotationDeleteSuccess,
  rotationDeleteFailure,
  rotationDuplicateStart,
  rotationDuplicateSuccess,
  rotationDuplicateFailure,
  rotationGetAllSuccess,
  rotationGetAllFailure,
  rotationGetAllStart,
  rotationGetStart,
  rotationGetSuccess,
  rotationGetFailure,
  rotationTargetStart,
  rotationTargetSuccess,
  rotationTargetFailure,
  rotationSetActiveStart,
  rotationSetActiveSuccess,
  rotationSetActiveFailure,
} from './reducer'

export const rotationAdd =
  (data: IRotationsAddRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(rotationAddStart())
      const result = await api.rotationsAdd.create(data)
      dispatch(rotationAddSuccess({ result: result.data }))
      return true
    } catch (e: any) {
      dispatch(rotationAddFailure(e.message))
      return false
    }
  }

export const rotationDel =
  (data: IRotationsRemoveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(rotationDeleteStart())
      await api.rotationsDel.remove(data)
      dispatch(rotationDeleteSuccess())
      return true
    } catch (e: any) {
      dispatch(rotationDeleteFailure(e.message))
      return false
    }
  }

export const rotationDup =
  (data: IRotationsDuplicateRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(rotationDuplicateStart())
      await api.rotationsAdd.duplicate(data)
      dispatch(rotationDuplicateSuccess())
      return true
    } catch (e: any) {
      dispatch(rotationDuplicateFailure(e.message))
      return false
    }
  }

export const rotationEdit =
  (data: IRotationsEditRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(rotationEditStart())
      await api.rotationsEdit.update(data)
      dispatch(rotationEditSuccess())
      return true
    } catch (e: any) {
      dispatch(rotationEditFailure(e.message))
      return false
    }
  }

export const rotationTarget =
  (data: IRotationsTargetRequest) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      dispatch(rotationTargetStart())
      const result = await api.rotationsEdit.target(data)
      dispatch(rotationTargetSuccess({ result: result.data }))
      return result
    } catch (e: any) {
      dispatch(rotationTargetFailure(e.message))
      return false
    }
  }

export const rotationGet =
  (data: IRotationsGetRequest) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      dispatch(rotationGetStart())
      const result = await api.rotationsGet.get(data)
      dispatch(rotationGetSuccess({ result: result.data }))
      return result.data
    } catch (e: any) {
      dispatch(rotationGetFailure(e.message))
      return false
    }
  }

export const rotationGetAll =
  (data: IRotationsGetAllRequest) =>
  async (dispatch: Dispatch<any>): Promise<Object | boolean> => {
    try {
      dispatch(rotationGetAllStart())
      const result = await api.rotationsGet.getAll(data)
      dispatch(rotationGetAllSuccess({ result: result.data }))
      return result
    } catch (e: any) {
      dispatch(rotationGetAllFailure(e.message))
      return false
    }
  }

export const setActive =
  (data: IRotationsSetActiveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(rotationSetActiveStart())
      await api.rotationsEdit.setActive(data)
      dispatch(rotationSetActiveSuccess())
      return true
    } catch (e: any) {
      dispatch(rotationSetActiveFailure(e.message))
      return false
    }
  }
