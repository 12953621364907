import React, { useState } from 'react'

import FormContainer from './FormContainer'

import { useTranslation } from 'react-i18next'
import { toast } from '../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../store'
import { advertisersEdit } from '../../../../../store/advertisers'
import { useAdv } from '../../../../hooks/advertisers'

const Form = () => {
  const { t } = useTranslation()
  const { adv } = useAdv()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handlerForm = async (event) => {
    event.preventDefault()
    setIsLoading(true)

    dispatch(advertisersEdit(adv)).then((result) => {
      result
        ? toast.success(t('edit_advertiser_successfully'))
        : toast.error(t('error'))

      setIsLoading(false)
    })
  }

  return (
    <form onSubmit={handlerForm}>
      <FormContainer isLoading={isLoading} />
    </form>
  )
}

export default Form
