import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'
import { history } from '../utils/history'
import { isTokenExpired } from '../utils/jwt'
import { toast } from '../../frontend/layout/JoyToaster'
import { store } from '..'
import { AxiosPromise } from 'axios'

import { ILoginRequest, ILoginResponse } from '../../api/auth/types'
import {
  loginStart,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  loadProfileStart,
  loadProfileFailure,
  loadProfileSuccess,
} from './authReducer'

export const loginUser =
  (data: ILoginRequest, errorStr: string) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      dispatch(loginStart())
      const res = await api.auth.login(data)

      dispatch(loginSuccess(res.data.accessToken))
      refreshTokenRequest = null

      dispatch(getProfile())
    } catch (e: any) {
      e.response.status === 401 && toast.error(errorStr)

      dispatch(loginFailure(e.message))
    }
  }

export const logoutUser =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await api.auth.logout()

      dispatch(logoutSuccess())

      history.push('/')
    } catch (e) {
      console.error(e)
    }
  }

export const getProfile =
  () =>
  async (dispatch: Dispatch<any>): Promise<Object> => {
    try {
      dispatch(loadProfileStart())

      const res = await api.auth.getProfile()
      dispatch(loadProfileSuccess(res.data))

      return res
    } catch (e: any) {
      console.error(e)

      dispatch(loadProfileFailure(e.message))
      return { error: true }
    }
  }

// // переменная для хранения запроса токена (для избежания race condition)
let refreshTokenRequest: AxiosPromise<ILoginResponse> | null = null

export const getAccessToken =
  () =>
  async (dispatch: Dispatch<any>): Promise<string | null> => {
    try {
      const accessToken = store.getState().auth.authData.accessToken

      if (!accessToken || isTokenExpired(accessToken)) {
        if (refreshTokenRequest === null) {
          refreshTokenRequest = api.auth.refreshToken()
        }

        const res = await refreshTokenRequest
        refreshTokenRequest = null

        dispatch(loginSuccess(res.data.accessToken))

        return res.data.accessToken
      }

      return accessToken
    } catch (e) {
      console.error(e)

      return null
    }
  }
