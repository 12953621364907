import React, { useEffect, useState } from 'react'
import { Box } from '@mui/joy'
import { Select, Tag, Space } from 'antd'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'
import { useFilter } from '../../../../../hooks/filter'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getAllCategories } from '../../../../../../store/categories'

const Category = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { updateFilter } = useFilter()
  const [category, setCategory] = useState({})
  const rows = useSelector((state) => state.categories.getAllData?.rows)

  useEffect(() => {
    if (!rows) {
      dispatch(getAllCategories())
    }
  }, [dispatch, rows])

  const handleInclude = () => updateFilter('in_category', category)
  const handleExclude = () => updateFilter('ex_category', category)

  const items = rows
    ? Object.values(rows).map((i) => ({
        label: i.name,
        value: i.name,
        key: i.category_id,
      }))
    : []

  return (
    <Box sx={{ display: 'flex', width: '100%', gap: '10px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          gap: 10,
          alignItems: 'center',
        }}
      >
        {items.length > 0 && (
          <Select
            placeholder={t('category')}
            maxTagCount={'responsive'}
            allowClear
            mode="multiple"
            style={{ width: '100%' }}
            options={items}
            tagRender={({ label, value, closable, onClose }) => {
              const onPreventMouseDown = (event) => {
                event.preventDefault()
                event.stopPropagation()
              }

              return (
                <Tag
                  key={`${label}-${value}`}
                  onMouseDown={onPreventMouseDown}
                  closable={closable}
                  onClose={onClose}
                  style={{
                    marginInlineEnd: 4,
                    display: 'flex',
                  }}
                >
                  <Space style={{ gridGap: '4px', mr: '4px' }}>{label}</Space>
                </Tag>
              )
            }}
            onChange={(_, newValue) => {
              setCategory(newValue)
            }}
          />
        )}
      </Box>

      <IncludeExcludeBtn include={handleInclude} exclude={handleExclude} />
    </Box>
  )
}

export default Category
