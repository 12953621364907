import React, { useState } from 'react'
import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Input, Select, Switch } from 'antd'
import { Add, PersonRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import FormItem from '../../../layout/FormItem'

const UserRoles = require('../../../configs/user_roles.json')

const Basic = ({ values }) => {
  const { t } = useTranslation()
  const [index, setIndex] = useState(0)
  const [checked, setChecked] = useState(false)
  const { arr, set } = values

  return (
    <Accordion
      sx={{ borderBottom: 0 }}
      expanded={index === 0}
      onChange={(event, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <PersonRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>{t('add_user_basic')}</Box>
      </AccordionSummary>

      <AccordionDetails>
        <FormItem
          text={'users_table_email'}
          component={
            <Input
              onChange={(e) => {
                set({
                  ...arr,
                  ...{ email: e.target.value },
                })
              }}
            />
          }
        />

        <FormItem
          text={'users_table_password'}
          component={
            <Input.Password
              visibilityToggle
              onChange={(e) => {
                set({
                  ...arr,
                  ...{ password: e.target.value },
                })
              }}
            />
          }
        />

        <FormItem
          text={'users_table_role'}
          component={
            <Select
              options={Object.keys(UserRoles).map((item) => ({
                label: UserRoles[item],
                value: item,
              }))}
              style={{ width: '100%' }}
              onChange={(val) => {
                set({
                  ...arr,
                  ...{ role_id: parseInt(val) },
                })
              }}
            />
          }
        />

        <FormItem
          text={'users_table_status'}
          component={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Switch
                size="small"
                checked={checked}
                onChange={(e) => {
                  setChecked(e)
                  set({
                    ...arr,
                    ...{ active: e },
                  })
                }}
              />
            </Box>
          }
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Basic
