import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../../store'
import { setActive } from '../../../../../store/advertisers'
import ListItemComponent from './ListItemComponent'
import Campaign from './Campaign'
import { campaignGetAll } from '../../../../../store/campaigns'
import { useSelector } from 'react-redux'
import { makeSelectCampaignsByAdvertiserId } from '../../../../../store/advertisers/selector'
import { Box, CircularProgress } from '@mui/joy'

const Advertiser = ({ advertiser: { advertiser_id, name, active } }) => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const addPath = `/advertisers/campaigns/add/${advertiser_id}`
  const path = `/advertisers/edit/${advertiser_id}`
  const campaigns = useSelector((state) =>
    makeSelectCampaignsByAdvertiserId()(state, advertiser_id),
  )
  const [isLoading, setIsLoading] = useState(false)

  const getCampaigns = useCallback(
    (id) => {
      setIsLoading(true)

      dispatch(campaignGetAll({ active: 1, advertiser_id: id })).then((res) => {
        setIsLoading(false)
      })
    },
    [dispatch],
  )

  const toggleCampaignList = useCallback(() => {
    setIsOpen((bool) => !bool)
    !isOpen && getCampaigns(advertiser_id)
  }, [isOpen, getCampaigns, advertiser_id])

  const onSetActive = useCallback(
    (id, active) => {
      dispatch(setActive({ active, advertiser_id: id }))
    },
    [dispatch],
  )

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.reload && event.data.isAdangle && isOpen) {
        getCampaigns(advertiser_id)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [getCampaigns, advertiser_id, isOpen])

  return (
    <ListItemComponent
      isOpen={isOpen}
      name={name}
      path={path}
      id={advertiser_id}
      addPath={addPath}
      active={active}
      borderLeft={true}
      onSetActive={onSetActive}
      toggleOpen={toggleCampaignList}
      nestedItems={
        isLoading ? (
          <Box
            sx={{
              display: 'flex',
              height: '30px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress size="sm" />
          </Box>
        ) : campaigns.length ? (
          campaigns.map((campaign) => (
            <Campaign
              key={campaign.campaign_id}
              campaign={campaign}
              advertiserId={advertiser_id}
            />
          ))
        ) : (
          <></>
        )
      }
    />
  )
}

export default Advertiser
