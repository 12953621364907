const weborama = (code) => {
  if (!code.includes('frontend.weborama')) {
    return { type: 0, videoType: 0 }
  }

  let type = 0
  let videoType = 0

  if (code.includes('a.A=im')) {
    type = 1
  } else if (code.includes('a.A=cl')) {
    type = 2
  } else if (code.includes('a.A=ev')) {
    type = 4

    const videoTypes = {
      skip: 13,
      mute: 11,
      unmute: 15,
      pause: 3,
      start: 1,
      fullscreen: 12,
      exitFullscreen: 16,
      firstQuartile: 6,
      midpoint: 10,
      thirdQuartile: 14,
      complete: 2,
    }

    Object.entries(videoTypes).forEach(([key, value]) => {
      if (code.includes(key)) {
        videoType = value
      }
    })
  }

  return { type, videoType }
}

const replaceRandom = (code) => {
  const replacements = [
    '[ramdom]',
    '[[random]]',
    '[RANDOM]',
    '[[RANDOM]]',
    '~random~',
    '~RANDOM~',
    '[rand]',
    '[[rand]]',
    '[RAND]',
    '[[RAND]]',
    '~rand~',
    '~RAND~',
    '$rand$',
    '$RAND$',
    '$$rand$$',
    '$$RAND$$',
    '$$random$$',
    '{rand}',
    '{{rand}}',
    '{RAND}',
    '{{RANDOM}}',
  ]

  replacements.forEach(
    (item) => (code = code.replaceAll(item, '$$$$RANDOM$$$$')),
  )
  return code
}

const pixelSystem = (code) => {
  const currentCode = replaceRandom(code)
  ;[weborama].forEach((system) => {
    code = system(code)
  })
  return { ...code, code: currentCode }
}

export default pixelSystem
