import React from 'react'
import Input from '@mui/joy/Input'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import { useTranslation } from 'react-i18next'

const EmailInput = () => {
  const { t } = useTranslation()

  return (
    <FormControl>
      <FormLabel>{t('login')}</FormLabel>

      <Input
        placeholder={t('login')}
        type="text"
        autoComplete="username"
        name="email"
      />
    </FormControl>
  )
}

export default EmailInput
