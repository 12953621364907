import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ReTargetState {
  addData: {
    target_id: number | null
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  editData: {
    target_id: string | null
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  deleteData: {
    success: boolean | null
    isLoading: boolean
    error: string | boolean | null
  }

  getAllData: {
    rows: object | null
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  pixels: {
    domains: object | null
    advertiser_id: number | null
    success: boolean | null
    isLoading: boolean
    error: string | boolean | null
  }
}

const initialState: ReTargetState = {
  addData: {
    target_id: null,
    isLoading: false,
    success: false,
    error: null,
  },

  editData: {
    target_id: null,
    isLoading: false,
    success: false,
    error: null,
  },

  deleteData: {
    success: false,
    isLoading: false,
    error: null,
  },

  getAllData: {
    rows: [],
    isLoading: false,
    success: false,
    error: null,
  },

  pixels: {
    domains: null,
    advertiser_id: null,
    success: false,
    isLoading: false,
    error: null,
  },
}

export const reducer = createSlice({
  name: 'retarget',
  initialState,
  reducers: {
    // Add reTarget
    reTargetAddStart: (state): ReTargetState => ({
      ...state,
      addData: {
        ...state.addData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),
    reTargetAddSuccess: (state, action: PayloadAction<any>): ReTargetState => ({
      ...state,
      addData: {
        ...state.addData,
        isLoading: false,
        success: true,
        error: false,
        target_id: action.payload.target_id,
      },
    }),
    reTargetAddFailure: (
      state,
      action: PayloadAction<string>,
    ): ReTargetState => ({
      ...state,
      addData: {
        ...state.addData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),

    // Add reTarget
    reTargetEditStart: (state): ReTargetState => ({
      ...state,
      editData: {
        ...state.editData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),
    reTargetEditSuccess: (
      state,
      action: PayloadAction<any>,
    ): ReTargetState => ({
      ...state,
      editData: {
        ...state.editData,
        isLoading: false,
        success: true,
        error: false,
        target_id: action.payload.target_id,
      },
    }),
    reTargetEditFailure: (
      state,
      action: PayloadAction<string>,
    ): ReTargetState => ({
      ...state,
      editData: {
        ...state.editData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),

    // Delete reTarget
    reTargetDeleteStart: (state): ReTargetState => ({
      ...state,
      deleteData: {
        ...state.deleteData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),
    reTargetDeleteSuccess: (
      state,
      action: PayloadAction<void>,
    ): ReTargetState => ({
      ...state,
      deleteData: {
        ...state.deleteData,
        isLoading: false,
        success: true,
        error: false,
      },
    }),
    reTargetDeleteFailure: (
      state,
      action: PayloadAction<string>,
    ): ReTargetState => ({
      ...state,
      deleteData: {
        ...state.deleteData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),

    // Get All reTarget
    reTargetGetAllStart: (state): ReTargetState => ({
      ...state,
      getAllData: {
        ...state.getAllData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),
    reTargetGetAllSuccess: (
      state,
      action: PayloadAction<any>,
    ): ReTargetState => ({
      ...state,
      getAllData: {
        ...state.getAllData,
        isLoading: false,
        success: true,
        error: false,
        rows: action.payload.result.rows,
      },
    }),
    reTargetGetAllFailure: (
      state,
      action: PayloadAction<string>,
    ): ReTargetState => ({
      ...state,
      getAllData: {
        ...state.getAllData,
        isLoading: false,
        success: false,
        error: action.payload,
        rows: [],
      },
    }),

    reTargetPixelsStart: (state): ReTargetState => ({
      ...state,
      pixels: {
        ...state.pixels,
        isLoading: true,
        success: false,
        error: false,
      },
    }),
    reTargetPixelsSuccess: (
      state,
      action: PayloadAction<any>,
    ): ReTargetState => ({
      ...state,
      pixels: {
        ...state.pixels,
        domains: action.payload.result.domains,
        advertiser_id: action.payload.result.advertiser_id,
        isLoading: false,
        success: true,
        error: false,
      },
    }),
    reTargetPixelsFailure: (
      state,
      action: PayloadAction<string>,
    ): ReTargetState => ({
      ...state,
      pixels: {
        ...state.pixels,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),
  },
})

export const {
  reTargetAddStart,
  reTargetAddSuccess,
  reTargetAddFailure,
  reTargetEditStart,
  reTargetEditSuccess,
  reTargetEditFailure,
  reTargetDeleteStart,
  reTargetDeleteSuccess,
  reTargetDeleteFailure,
  reTargetGetAllStart,
  reTargetGetAllSuccess,
  reTargetGetAllFailure,
  reTargetPixelsStart,
  reTargetPixelsSuccess,
  reTargetPixelsFailure,
} = reducer.actions

export default reducer.reducer
