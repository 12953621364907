import React, { useContext, useState } from 'react'

import { Select } from 'antd'
import { useFilter } from '../../../../../hooks/filter'
import { FiltersContext } from '../../../../../hocs/FiltersProvider'

const ReportType = ({ withAdvancedSearch, setWithAdvancedSearch }) => {
  const { filter, updateFilter, resetFilter } = useFilter()
  const [report, setReport] = useState(filter.report_type || 0)

  const reportTypes = [
    { value: 0, label: 'General' },
    { value: 1, label: 'Advanced' },
    { value: 2, label: 'Re-Target', disabled: true },
    { value: 3, label: 'Logs', disabled: false },
  ]
  const { setterVpn, setterTor, setterUniq } = useContext(FiltersContext)

  const setToDefault = () => {
    setterVpn('0')
    setterUniq('0')
    setterTor('0')
    resetFilter()
  }

  const handleChange = (newValue) => {
    newValue === 0 && setToDefault()
    if (reportTypes.some((item) => !item.disabled && item.value === newValue)) {
      setWithAdvancedSearch(newValue)
    } else {
      setReport(newValue)
    }
    updateFilter('report_type', newValue)
  }

  return (
    <Select
      defaultValue={report}
      maxTagCount={'responsive'}
      style={{ marginRight: '12px', flex: 1 }}
      options={reportTypes}
      onChange={handleChange}
    />
  )
}

export default ReportType
