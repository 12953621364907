import React, { useState } from 'react'

import { Box, Sheet } from '@mui/joy'
import { Select, Tag, Space } from 'antd'
import { renderChip } from '../../../../../handlers/Chips'
import { useTranslation } from 'react-i18next'
import { updateItem } from '../utils'
import { useSelector } from 'react-redux'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const Language = ({ rotation, current }) => {
  const { t } = useTranslation()

  const [value, setValue] = useState([])
  const language = useSelector((state) => state.rotations.target.rows?.lang)

  const handleIncludeExclude = (target_name) => {
    updateItem({ rotation, current, value, target_name })
  }

  const items = language
    ? Object.keys(language).map((i) => ({
        label: language[i],
        value: i,
        key: i,
      }))
    : []

  return (
    <>
      <Sheet
        sx={(theme) => ({
          mt: '0',
          borderRadius: '8px',
          p: 2,
          display: 'flex',
          [theme.getColorSchemeSelector('light')]: {
            backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
          },
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
          },
        })}
      >
        <Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              gridGap: 10,
              alignItems: 'center',
            }}
          >
            <Select
              placeholder={t('target_1_browser_lang')}
              maxTagCount={'responsive'}
              allowClear
              mode="multiple"
              style={{ width: '100%' }}
              options={items}
              defaultValue={value}
              tagRender={({ label, value, closable, onClose }) => {
                const onPreventMouseDown = (event) => {
                  event.preventDefault()
                  event.stopPropagation()
                }

                return (
                  <Tag
                    key={`${label}-${value}`}
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={onClose}
                    style={{
                      marginInlineEnd: 4,
                      display: 'flex',
                    }}
                  >
                    <Space style={{ gridGap: '4px', mr: '4px' }}>{label}</Space>
                  </Tag>
                )
              }}
              onChange={(_, newValue) => {
                setValue(newValue)
              }}
            />
          </Box>
          <IncludeExcludeBtn
            include={() => {
              handleIncludeExclude('language')
            }}
            exclude={() => {
              handleIncludeExclude('ex_language')
            }}
          />
        </Box>
      </Sheet>
      {renderChip({
        current: current,
        rotation: rotation,
        type: 'language',
        label: (i) => language[i],
      })}
    </>
  )
}

export default Language
