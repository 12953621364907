import React, { useState, useMemo } from 'react'

import { Box, Sheet } from '@mui/joy'
import { Select, Space, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { updateItem } from '../utils'
import { renderChip, FlagImg } from '../../../../../handlers/Chips'

import countryList from 'react-select-country-list'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const Country = ({ rotation, current }) => {
  const { t } = useTranslation()
  const countries = useMemo(() => countryList().getData(), [])
  const [value, setValue] = useState([])

  const handleIncludeExclude = (target_name) => {
    updateItem({ rotation, current, value, target_name })
  }

  return (
    <>
      <Sheet
        sx={(theme) => ({
          mt: '0',
          borderRadius: '8px',
          p: 2,
          display: 'flex',
          [theme.getColorSchemeSelector('light')]: {
            backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
          },
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
          },
        })}
      >
        <Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
          <Select
            placeholder={t('country')}
            maxTagCount={'responsive'}
            allowClear
            mode="multiple"
            style={{ width: '100%' }}
            onChange={async (_, val) => {
              console.log(val)
              setValue(val)
            }}
            defaultValue={value}
            tagRender={({ label, value, closable, onClose }) => {
              const onPreventMouseDown = (event) => {
                event.preventDefault()
                event.stopPropagation()
              }

              return (
                <Tag
                  key={`${label}-${value}`}
                  onMouseDown={onPreventMouseDown}
                  closable={closable}
                  onClose={onClose}
                  style={{
                    marginInlineEnd: 4,
                    display: 'flex',
                  }}
                >
                  <Space style={{ gridGap: '4px', mr: '4px' }}>
                    <FlagImg sx={{ display: 'flex' }} countryCode={value} />
                    {label}
                  </Space>
                </Tag>
              )
            }}
            options={countries}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            optionRender={(option) => (
              <Space>
                <FlagImg
                  sx={{ display: 'flex' }}
                  countryCode={option.data.value}
                />
                {option.data.label}
              </Space>
            )}
          />

          <IncludeExcludeBtn
            include={() => {
              handleIncludeExclude('country')
            }}
            exclude={() => {
              handleIncludeExclude('ex_country')
            }}
          />
        </Box>
      </Sheet>
      {renderChip({
        current: current,
        rotation: rotation,
        type: 'country',
        label: (i) => (
          <>
            <FlagImg countryCode={i} />
            {countryList(i).getLabel(i)}
          </>
        ),
      })}
    </>
  )
}

export default Country
