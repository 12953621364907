import React, { useState, useEffect } from 'react'
import { Box, CircularProgress } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import { getCategory } from '../../../../store/categories'

import BaseInformation from './BaseInformation'

const Edit = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const categoryId = parseInt(params.pageId)
  const [rows, setRows] = useState(false)

  useEffect(() => {
    dispatch(getCategory({ category_id: categoryId })).then((result) => {
      setRows(result.rows)
    })
  }, [categoryId, dispatch])

  return rows ? (
    <Box
      sx={{
        maxWidth: 'clamp(450px, 100dvw, 900px)',
        width: '100%',
        overflowY: 'auto',
        borderRight: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'var(--joy-palette-background-surface)',
      }}
    >
      <BaseInformation rows={rows} setRows={setRows} />
    </Box>
  ) : (
    <CircularProgress size="sm" />
  )
}

export default Edit
