import * as React from 'react'
import Routers from './Routers'
import { FiltersProvider } from '../../hocs/FiltersProvider'

const Filter = () => {
  return (
    <FiltersProvider>
      <Routers />
    </FiltersProvider>
  )
}

export default Filter
