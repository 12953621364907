import * as React from 'react'

import Sheet from '@mui/joy/Sheet'

const Topbar = () => {
  return (
    <>
      <Sheet
        sx={{
          position: {
            md: 'sticky',
          },
          transform: {
            md: 'none',
          },
          width: 'calc(100dvw - var(--Sidebar-width))',
          height: 'var(--Topbar-height)',
          p: 3,
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        Topbar
      </Sheet>
    </>
  )
}

export default Topbar
