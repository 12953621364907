import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CustomState {
  customFieldsAddData: {
    field_id: number | null
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  customParamsAddData: {
    param_id: number | null
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  customFieldsDeleteData: {
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  customParamsDeleteData: {
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  customFieldsEditData: {
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  customParamsEditData: {
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  customFieldsGetData: {
    field_id: number | null
    name: string
    field_value: string
    field_key: string
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  customParamsGetData: {
    param_id: number | null
    name: string
    param_value: string
    param_key: string
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  customFieldsGetAllData: {
    rows: object | null
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }

  customParamsGetAllData: {
    rows: object | null
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
  }
}

const initialState: CustomState = {
  customFieldsAddData: {
    field_id: null,
    isLoading: false,
    success: false,
    error: false,
  },

  customParamsAddData: {
    param_id: null,
    isLoading: false,
    success: false,
    error: false,
  },

  customFieldsDeleteData: {
    success: false,
    isLoading: false,
    error: false,
  },

  customParamsDeleteData: {
    success: false,
    isLoading: false,
    error: false,
  },

  customFieldsEditData: {
    success: false,
    isLoading: false,
    error: false,
  },

  customParamsEditData: {
    success: false,
    isLoading: false,
    error: false,
  },

  customFieldsGetData: {
    field_id: null,
    name: '',
    field_value: '',
    field_key: '',
    isLoading: false,
    success: false,
    error: false,
  },

  customParamsGetData: {
    param_id: null,
    name: '',
    param_value: '',
    param_key: '',
    isLoading: false,
    success: false,
    error: false,
  },

  customFieldsGetAllData: {
    rows: [],
    isLoading: false,
    success: false,
    error: false,
  },

  customParamsGetAllData: {
    rows: [],
    isLoading: false,
    success: false,
    error: false,
  },
}

export const reducer = createSlice({
  name: 'custom',
  initialState,
  reducers: {
    customFieldsAddStart: (state): CustomState => ({
      ...state,
      customFieldsAddData: {
        ...state.customFieldsAddData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),
    customFieldsAddSuccess: (
      state,
      action: PayloadAction<any>,
    ): CustomState => ({
      ...state,
      customFieldsAddData: {
        ...state.customFieldsAddData,
        field_id: action.payload.field_id,
        isLoading: false,
        success: true,
        error: false,
      },
    }),
    customFieldsAddFailure: (
      state,
      action: PayloadAction<string>,
    ): CustomState => ({
      ...state,
      customFieldsAddData: {
        ...state.customFieldsAddData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),
    customParamsAddStart: (state): CustomState => ({
      ...state,
      customParamsAddData: {
        ...state.customParamsAddData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),
    customParamsAddSuccess: (
      state,
      action: PayloadAction<any>,
    ): CustomState => ({
      ...state,
      customParamsAddData: {
        ...state.customParamsAddData,
        param_id: action.payload.param_id,
        isLoading: false,
        success: true,
        error: false,
      },
    }),
    customParamsAddFailure: (
      state,
      action: PayloadAction<string>,
    ): CustomState => ({
      ...state,
      customParamsAddData: {
        ...state.customParamsAddData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),

    customFieldsDeleteStart: (state): CustomState => ({
      ...state,
      customFieldsDeleteData: {
        ...state.customFieldsDeleteData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),
    customFieldsDeleteSuccess: (
      state,
      action: PayloadAction<void>,
    ): CustomState => ({
      ...state,
      customFieldsDeleteData: {
        ...state.customFieldsDeleteData,
        success: true,
        error: false,
        isLoading: false,
      },
    }),
    customFieldsDeleteFailure: (
      state,
      action: PayloadAction<string>,
    ): CustomState => ({
      ...state,
      customFieldsDeleteData: {
        ...state.customFieldsDeleteData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),
    customParamsDeleteStart: (state): CustomState => ({
      ...state,
      customParamsDeleteData: {
        ...state.customParamsDeleteData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),
    customParamsDeleteSuccess: (
      state,
      action: PayloadAction<void>,
    ): CustomState => ({
      ...state,
      customParamsDeleteData: {
        ...state.customParamsDeleteData,
        success: true,
        error: false,
        isLoading: false,
      },
    }),
    customParamsDeleteFailure: (
      state,
      action: PayloadAction<string>,
    ): CustomState => ({
      ...state,
      customParamsDeleteData: {
        ...state.customParamsDeleteData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),

    customFieldsEditStart: (state): CustomState => ({
      ...state,
      customFieldsEditData: {
        ...state.customFieldsEditData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),
    customFieldsEditSuccess: (
      state,
      action: PayloadAction<void>,
    ): CustomState => ({
      ...state,
      customFieldsEditData: {
        ...state.customFieldsEditData,
        success: true,
        error: false,
        isLoading: false,
      },
    }),
    customFieldsEditFailure: (
      state,
      action: PayloadAction<string>,
    ): CustomState => ({
      ...state,
      customFieldsEditData: {
        ...state.customFieldsEditData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),
    customParamsEditStart: (state): CustomState => ({
      ...state,
      customParamsEditData: {
        ...state.customParamsEditData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),
    customParamsEditSuccess: (
      state,
      action: PayloadAction<void>,
    ): CustomState => ({
      ...state,
      customParamsEditData: {
        ...state.customParamsEditData,
        success: true,
        error: false,
        isLoading: false,
      },
    }),
    customParamsEditFailure: (
      state,
      action: PayloadAction<string>,
    ): CustomState => ({
      ...state,
      customParamsEditData: {
        ...state.customParamsEditData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),

    customFieldsGetStart: (state): CustomState => ({
      ...state,
      customFieldsGetData: {
        ...state.customFieldsGetData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),
    customFieldsGetSuccess: (
      state,
      action: PayloadAction<any>,
    ): CustomState => ({
      ...state,
      customFieldsGetData: {
        ...state.customFieldsGetData,
        isLoading: false,
        success: true,
        error: false,
        ...action.payload.result.rows,
      },
    }),
    customFieldsGetFailure: (
      state,
      action: PayloadAction<string>,
    ): CustomState => ({
      ...state,
      customFieldsGetData: {
        ...state.customFieldsGetData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),
    customParamsGetStart: (state): CustomState => ({
      ...state,
      customParamsGetData: {
        ...state.customParamsGetData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),
    customParamsGetSuccess: (
      state,
      action: PayloadAction<any>,
    ): CustomState => ({
      ...state,
      customParamsGetData: {
        ...state.customParamsGetData,
        isLoading: false,
        success: true,
        error: false,
        ...action.payload.result.rows,
      },
    }),
    customParamsGetFailure: (
      state,
      action: PayloadAction<string>,
    ): CustomState => ({
      ...state,
      customParamsGetData: {
        ...state.customParamsGetData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),

    customFieldsGetAllStart: (state): CustomState => ({
      ...state,
      customFieldsGetAllData: {
        ...state.customFieldsGetAllData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),
    customFieldsGetAllSuccess: (
      state,
      action: PayloadAction<any>,
    ): CustomState => ({
      ...state,
      customFieldsGetAllData: {
        ...state.customFieldsGetAllData,
        isLoading: false,
        success: true,
        error: false,
        rows: action.payload.result.rows,
      },
    }),
    customFieldsGetAllFailure: (
      state,
      action: PayloadAction<string>,
    ): CustomState => ({
      ...state,
      customFieldsGetAllData: {
        ...state.customFieldsGetAllData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),
    customParamsGetAllStart: (state): CustomState => ({
      ...state,
      customParamsGetAllData: {
        ...state.customParamsGetAllData,
        success: false,
        error: false,
        isLoading: true,
      },
    }),
    customParamsGetAllSuccess: (
      state,
      action: PayloadAction<any>,
    ): CustomState => ({
      ...state,
      customParamsGetAllData: {
        ...state.customParamsGetAllData,
        isLoading: false,
        success: true,
        error: false,
        rows: action.payload.result.rows,
      },
    }),
    customParamsGetAllFailure: (
      state,
      action: PayloadAction<string>,
    ): CustomState => ({
      ...state,
      customParamsGetAllData: {
        ...state.customParamsGetAllData,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),
  },
})

export const {
  customFieldsAddStart,
  customFieldsAddSuccess,
  customFieldsAddFailure,
  customParamsAddStart,
  customParamsAddSuccess,
  customParamsAddFailure,

  customFieldsEditStart,
  customFieldsEditSuccess,
  customFieldsEditFailure,
  customParamsEditStart,
  customParamsEditSuccess,
  customParamsEditFailure,

  customFieldsDeleteStart,
  customFieldsDeleteSuccess,
  customFieldsDeleteFailure,
  customParamsDeleteStart,
  customParamsDeleteSuccess,
  customParamsDeleteFailure,

  customFieldsGetStart,
  customFieldsGetSuccess,
  customFieldsGetFailure,
  customParamsGetStart,
  customParamsGetSuccess,
  customParamsGetFailure,

  customFieldsGetAllStart,
  customFieldsGetAllSuccess,
  customFieldsGetAllFailure,
  customParamsGetAllStart,
  customParamsGetAllSuccess,
  customParamsGetAllFailure,
} = reducer.actions

export default reducer.reducer
