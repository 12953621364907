import React from 'react'

import { Tooltip, IconButton } from '@mui/joy'
import { VerifiedRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const ActiveBtn = (props) => {
  const { t } = useTranslation()
  const {
    search: { value, set },
  } = props

  const iconButtonSx = () => ({
    display: 'flex',
    [`&.active svg`]: {
      color:
        'var(--joy-palette-success-solidBg, var(--joy-palette-success-500, #1F7A1F))',
    },
  })

  return (
    <Tooltip
      arrow
      size="sm"
      placement="top"
      color="primary"
      title={
        value.active === -1
          ? t('hide_inactive_users')
          : t('show_inactive_users')
      }
      sx={{ px: 1 }}
    >
      <IconButton
        size="sm"
        sx={iconButtonSx}
        onClick={() => {
          set({
            ...value,
            ...{ active: value.active === -1 ? 1 : -1 },
          })
        }}
        className={value.active !== -1 && 'active'}
      >
        <VerifiedRounded />
      </IconButton>
    </Tooltip>
  )
}

export default ActiveBtn
