import React from 'react'

import { CssVarsProvider } from '@mui/joy/styles'
import Pages from './'

export default function App() {
  return (
    <CssVarsProvider disableTransitionOnChange>
      <Pages />
    </CssVarsProvider>
  )
}
