import React from 'react'
import Box from '@mui/joy/Box'
import Header from './Header'
import Main from './Main'
import Footer from './Footer'

const Middle = () => {
  return (
    <Box
      sx={() => ({
        width:
          'calc(clamp(100vw, (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw) - 65px)',
        transition: 'width var(--Transition-duration)',
        transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        backdropFilter: 'blur(3px)',
      })}
    >
      <Box
        sx={(theme) => ({
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100dvh',
          width:
            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
          maxWidth: '100%',
          backgroundColor: 'rgba(255 255 255 / 0.8)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.8)',
          },
        })}
      >
        <Header />
        <Main />
        <Footer />
      </Box>
    </Box>
  )
}

export default Middle
