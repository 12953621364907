import React, { useEffect } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Add, LocationSearchingRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../../../../store'
import { zoneTrackerGetAll } from '../../../../../../../store/zones'

import Table from './Table'
import InputBar from './InputBar'

const Tracking = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [cookie] = useCookies(['reload'])
  const publisher_id = parseInt(useParams().pageId)
  const zone_id = parseInt(useParams().zoneId)

  useEffect(() => {
    dispatch(
      zoneTrackerGetAll({
        zone_id,
        publisher_id,
      }),
    )
  }, [cookie.reload, publisher_id, zone_id, dispatch])

  return (
    <Accordion sx={{ border: 'none' }}>
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <LocationSearchingRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>{t('trackers_and_pixels')}</Box>
      </AccordionSummary>
      <AccordionDetails>
        <InputBar />
        <Table />
      </AccordionDetails>
    </Accordion>
  )
}

export default Tracking
