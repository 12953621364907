import React from 'react'

import Routers from './Routers'
import { CampaignProvider } from '../../../hooks/campaigns'

const Campaigns = () => (
  <CampaignProvider>
    <Routers />
  </CampaignProvider>
)

export default Campaigns
