import React, { useEffect, useState } from 'react'

import {
  Box,
  Typography,
  Divider,
  DialogTitle,
  DialogContent,
  Modal,
  ModalDialog,
  ModalClose,
  List,
  ListItem,
} from '@mui/joy'

import { Input, Space, Button, Select } from 'antd'
import { ContentCopyRounded } from '@mui/icons-material'

import Editor from '@monaco-editor/react'
import FormItem from '../../../../layout/FormItem'

import { useColorScheme } from '@mui/joy/styles'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSelector } from 'react-redux'
import { toast } from '../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../store'
import { reTargetPix, reTargetEdit } from '../../../../../store/retarget'
import { getAllAdvertisers } from '../../../../../store/advertisers'

const ReTargetModal = ({ open }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { mode } = useColorScheme()
  const [domain, setDomain] = useState('')
  const [advert, setAdvert] = useState(null)
  const [current, setCurrent] = useState(0)

  const [openReTargetDialog, setOpenReTargetDialog] = useState(false)
  const rows = useSelector((state) => state.retarget?.pixels)
  const adverts = useSelector(
    (state) => state.advertisers.allData.advertisersById,
  )
  const isLoading = useSelector((state) => state.retarget.pixels?.isLoading)

  const currentDomain = !!rows.domains
    ? !!domain && window.location.host.indexOf('localhost') > -1
      ? `http://${domain}`
      : `https://${domain}`
    : ''

  useEffect(() => {
    if (open) {
      dispatch(reTargetPix({ target_id: open })).then((result) => {
        setAdvert(result.advertiser_id)
        setCurrent(result.advertiser_id)
        setDomain(result.domains[0])
        setOpenReTargetDialog(true)
      })
      dispatch(
        getAllAdvertisers({
          active: 'all',
          category: 0,
        }),
      )
    }
  }, [open, dispatch])

  const CopyBtn = ({ text }) => (
    <CopyToClipboard
      text={`${text}`}
      onCopy={(_, result) => {
        if (result) {
          toast.success(t('copied'))
        }
      }}
    >
      <Button
        style={{
          padding: '2px 7px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ContentCopyRounded sx={{ fontSize: 16 }} />
      </Button>
    </CopyToClipboard>
  )

  const code = `<script type="text/javascript">
  new Image().src = "${currentDomain}/px/${open}?" +
  new URLSearchParams({
    _uri: encodeURIComponent(window.location.pathname),
    _user: 0,
    _status: 0
  })
</script>`

  const items =
    rows.domains && rows.domains.map((i) => ({ label: i, value: i }))

  const handlerSaveAdvertiser = () => {
    dispatch(
      reTargetEdit({
        target_id: open,
        advertiser_id: advert,
      }),
    ).then((e) => {
      if (!e.error) {
        setCurrent(advert)
        toast.success(t('edit_retarget_successfully'))
      }
    })
  }

  return (
    <>
      <Modal
        open={!!openReTargetDialog}
        onClose={() => setOpenReTargetDialog(false)}
      >
        <ModalDialog
          sx={{ width: '800px' }}
          variant="outlined"
          role="alertdialog"
        >
          <DialogTitle>{t('re-target-urls')}</DialogTitle>
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Divider sx={{ mt: 1 }} />

          <FormItem
            text={'domain'}
            component={
              <Select
                placeholder={t('domain')}
                maxTagCount={'responsive'}
                style={{ width: '100%', zIndex: 999 }}
                loading={isLoading}
                defaultValue={domain}
                options={items}
                onChange={setDomain}
              />
            }
          />

          <FormItem
            text={'tracker-id'}
            component={
              <DialogContent>
                <Space.Compact>
                  <Input
                    disabled
                    defaultValue={open}
                    style={{
                      flex: 1,
                      ml: 0,
                      '--Input-decoratorChildHeight': '32px',
                    }}
                  />
                  <CopyBtn text={open} />
                </Space.Compact>
              </DialogContent>
            }
          />

          <Divider />

          <Typography level="title-sm">{t('re-target-tracker')}</Typography>
          <DialogContent
            sx={{
              '.monaco-editor': {
                '--vscode-editor-background': 'transparent!important',
                '--vscode-editorGutter-background': 'transparent!important',
              },
              '.decorationsOverviewRuler': {
                display: 'none!important',
              },

              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Editor
              height="150px"
              language="html"
              theme={mode === 'dark' ? 'vs-dark' : 'vs-light'}
              value={code}
              options={{
                fixedOverflowWidgets: true,
                inlineSuggest: true,
                fontSize: '12px',
                formatOnType: true,
                readOnly: true,
                readOnlyMessage: { value: '' },
                autoClosingBrackets: true,
                scrollBeyondLastColumn: 0,
                scrollBeyondLastLine: false,
                wordWrap: 'off',
                acceptSuggestionOnEnter: 'off',
                contextmenu: false,
                minimap: { enabled: false },
                lineNumbers: 'off',
                folding: false,
                scrollPredominantAxis: false,
                smoothScrolling: false,
                renderLineHighlight: 'none',
                renderLineHighlightOnlyWhenFocus: false,
                lineDecorationsWidth: 0,
                stickyScroll: { enabled: false },
                guides: { indentation: false },
                scrollbar: {
                  vertical: 'hidden',
                  horizontal: 'hidden',
                  scrollByPage: false,
                  alwaysConsumeMouseWheel: false,
                  handleMouseWheel: false,
                  useShadows: false,
                },
              }}
            />
            <Box>
              <CopyBtn text={code} />
            </Box>
          </DialogContent>

          <Box>
            <List sx={{ fontSize: 12 }}>
              <ListItem sx={{ minHeight: '30px', lineHeight: '20px', pl: 0 }}>
                <code>_uri - {t('re-target-param-uri')}</code>
              </ListItem>
              <ListItem sx={{ minHeight: '30px', lineHeight: '20px', pl: 0 }}>
                <code>_user - {t('re-target-param-user')}</code>
              </ListItem>
              <ListItem sx={{ minHeight: '30px', lineHeight: '20px', pl: 0 }}>
                <code>_status - {t('re-target-param-status')}</code>
              </ListItem>
            </List>
          </Box>

          <Divider sx={{ mt: 1, mb: 1 }} />

          <FormItem
            text={'advertiser'}
            component={
              <>
                <Select
                  placeholder={t('advertiser')}
                  maxTagCount={'responsive'}
                  style={{ width: '100%' }}
                  loading={!!isLoading}
                  value={advert}
                  fieldNames={{ label: 'name', value: 'advertiser_id' }}
                  options={Object.values(adverts)}
                  onChange={setAdvert}
                />
                {advert !== current ? (
                  <>
                    <Button
                      style={{ marginLeft: '8px' }}
                      type={'primary'}
                      onClick={handlerSaveAdvertiser}
                    >
                      {t('edit_advertiser_submit')}
                    </Button>
                  </>
                ) : (
                  ''
                )}
              </>
            }
          />
        </ModalDialog>
      </Modal>
    </>
  )
}

export default ReTargetModal
