import React from 'react'
import { Box } from '@mui/joy'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { toast } from '../../../../layout/JoyToaster'

const Submit = ({ values, onSubmit, isLoading }) => {
  const { t } = useTranslation()

  const handleClick = (event) => {
    event.preventDefault()

    if (!values.url && !values.creative) {
      toast.error(t('banner_both_field_empty_error'))
    } else if (!values.url) {
      toast.error(t('banner_link_empty_error'))
    } else if (!values.creative) {
      toast.error(t('banner_creative_empty_error'))
    } else if (!values.name) {
      toast.error(t('banner_name_empty_error'))
    } else {
      onSubmit(event)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'end',
        mr: 2,
        mt: 2,
      }}
    >
      <Button
        size="small"
        loading={isLoading}
        type="primary"
        htmlType={'submit'}
        variant="solid"
        onClick={handleClick}
      >
        {t('add_advertiser_submit')}
      </Button>
    </Box>
  )
}

export default Submit
