import React from 'react'

import Form from './Form'
import Submit from './Submit'

import { toast } from '../../../layout/JoyToaster'
import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../store'
import { useTranslation } from 'react-i18next'
import { customFieldEdit } from '../../../../store/custom'

const BaseInformation = ({ rows, setRows }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [, setCookie] = useCookies(['reload'])

  const handlerForm = (event) => {
    event.preventDefault()

    dispatch(
      customFieldEdit({
        field_id: rows.field_id,
        field_value: rows.field_value,
        field_key: rows.field_key,
        name: rows.name,
      }),
    ).then((result) => {
      result
        ? toast.success(t('edit_custom_field_successfully'))
        : toast.error(t('error'))

      setCookie('reload', new Date().getTime(), { path: '/' })
    })
  }

  return (
    <form onSubmit={handlerForm}>
      <Form
        values={{
          set: setRows,
          arr: rows,
        }}
      />
      <Submit />
    </form>
  )
}

export default BaseInformation
