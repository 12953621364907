import React, { useEffect } from 'react'

import { Box, CircularProgress } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useCmp } from '../../../../hooks/campaigns'
import api from '../../../../../api'
import ControlBar from './ControlBar'
import Form from './Form'

const Edit = () => {
  const { cmp, replaceCampaign } = useCmp()
  const advertiserId = parseInt(useParams().pageId)
  const campaignId = parseInt(useParams().campaignId)

  useEffect(() => {
    const isValid = !!cmp
      ? !cmp.advertiser_id ||
        !cmp.campaign_id ||
        cmp.advertiser_id !== advertiserId ||
        cmp.campaign_id !== campaignId
      : false

    if (isValid && advertiserId > 0 && campaignId > 0) {
      api.campaignsGet
        .get({
          campaign_id: campaignId,
          advertiser_id: advertiserId,
        })
        .then((result) => {
          if (!result.data.error) {
            replaceCampaign(result.data.rows)
          }
        })
    }
  }, [replaceCampaign, campaignId, advertiserId, cmp])

  return !!cmp &&
    Object.keys(cmp).length &&
    cmp.advertiser_id === advertiserId &&
    cmp.campaign_id === campaignId ? (
    <Box sx={{ width: '100%' }}>
      <ControlBar />
      <Form />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '20dvh',
      }}
    >
      <CircularProgress size="sm" />
    </Box>
  )
}

export default Edit
