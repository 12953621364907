import React from 'react'

import { Box, Chip, ChipDelete, AspectRatio, ListItemDecorator } from '@mui/joy'
import countryList from 'react-select-country-list'
import { useReport } from '../../../../../../hooks/report'

const createChip = ({ key, color, label, prefix = '', index, onDelete }) => (
  <Chip
    key={key}
    sx={{ mr: 1, mt: 1, pr: 2, fontSize: 12 }}
    size="md"
    variant="soft"
    color={color}
    endDecorator={
      <ChipDelete
        sx={{
          width: '14px',
          ml: '2px',
          mr: '-12px',
          [`& svg`]: {
            width: '14px',
          },
        }}
        onDelete={onDelete}
      />
    }
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gridGap: '5px',
        fontSize: 13,
      }}
    >
      {prefix}
      {label}
    </Box>
  </Chip>
)

const FlagImg = ({ countryCode }) => (
  <ListItemDecorator sx={{ width: '20px', minInlineSize: '24px!important' }}>
    <AspectRatio ratio="1" sx={{ minWidth: '15px', borderRadius: '50%' }}>
      <img
        loading="lazy"
        width="20"
        src={`https://flagcdn.com/${countryCode.toLowerCase()}.svg`}
        srcSet={`https://flagcdn.com/${countryCode.toLowerCase()}.svg 2x`}
        alt=""
      />
    </AspectRatio>
  </ListItemDecorator>
)

const renderItems = ({
  items,
  type,
  isExcluded = false,
  deviceText,
  connectionTypeText,
  userTypesText,
  groupByText,
  language,
  onDelete,
}) => {
  return items.map((item, index) => {
    const color = isExcluded ? 'danger' : 'primary'
    const key = `${type}-${index}-${color}`
    let label = ''
    let prefix = null

    switch (type) {
      case 'country':
        label = countryList().getLabel(item.value)
        prefix = <FlagImg countryCode={item.value} />
        break

      case 'region':
        label = `${countryList().getLabel(item.country)}, ${item.label}`
        prefix = <FlagImg countryCode={item.country} />
        break

      case 'city':
        label = `${countryList().getLabel(item.country)}, ${item.region}, ${
          item.label
        }`
        prefix = <FlagImg countryCode={item.country} />
        break

      case 'ip_range':
        label = `${item.from}${item.to ? ` - ${item.to}` : ''}`
        break

      case 'language':
        label = item.label
        break

      case 'browser':
      case 'os':
        label = `${item.name} ${item.condition} ${
          item.version > 0 ? item.version : ''
        }`
        break

      case 'device_format':
        label = deviceText[`_${item.value}`]
        break

      case 'vendor':
      case 'isp':
      case 'category':
      case 'template':
        label = `${type} ${isExcluded ? '!' : ''}= '${item.value}'`
        break

      case 'connection_type':
        label = connectionTypeText[`_${item.value}`]
        break

      case 'user_type':
        label = userTypesText[`_${item.value}`]
        break

      case 'custom':
        label = `${item.name} ${
          item.condition === '='
            ? `${isExcluded ? '!' : ''}${item.condition} '${item._value}'`
            : `ILIKE '%${item._value}%'`
        }`
        break

      case 'referer':
        label = `referer ${
          item.condition === '='
            ? `${isExcluded ? '!' : ''}${item.condition} '${item.referer}'`
            : `ILIKE '%${item.referer}%'`
        }`
        break
      case 'zone':
      case 'publisher':
      case 'advertiser':
      case 'campaign':
      case 'banner':
        label = `${type} ${isExcluded ? '!' : ''}= ${item.name}`
        break
      case 'group_by':
        label = groupByText[item]
        break
      default:
        console.error('Unknown type', type)
    }
    if (!label) return null

    return createChip({
      key,
      color,
      label,
      prefix,
      index,
      onDelete: () => {
        onDelete(Array.isArray(item) ? item.value : item)
      },
    })
  })
}

const OptionChips = ({
  items,
  category,
  deviceText,
  connectionTypeText,
  userTypesText,
  groupByText,
  language,
  name,
  onChange = false,
}) => {
  const { deleteOption } = useReport()

  const isExcluded = category.toString().toLowerCase().indexOf('ex_') > -1
  const type = category.replaceAll('in_', '').replaceAll('ex_', '')
  return (
    items &&
    renderItems({
      items,
      type,
      isExcluded,
      deviceText,
      connectionTypeText,
      userTypesText,
      groupByText,
      language,
      onDelete: (val) => {
        if (onChange && typeof onChange === 'function') {
          onChange()
        }

        deleteOption({ name, key: category, value: val })
      },
    })
  )
}

export default OptionChips
