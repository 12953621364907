import React from 'react'

import SimpleDateTime from 'react-simple-timestamp-to-date'
import {
  DeleteRounded,
  ModeEditRounded,
  CheckRounded,
} from '@mui/icons-material'

import { Button, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { NavLink } from 'react-router-dom'
import { toast } from '../../../../layout/JoyToaster'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../store'
import { usersDel } from '../../../../../store/users'

const UserRoles = require('../../../../configs/user_roles.json')

const Body = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [, setCookie] = useCookies(['reload'])
  const rows = useSelector((state) => state.users.getAllData?.rows)

  const deleteUser = (deleteUserId) => {
    if (deleteUserId) {
      dispatch(usersDel({ user_id: deleteUserId })).then((result) => {
        result
          ? toast.success(t('delete_user_successfully'))
          : toast.error(t('error'))

        setCookie('reload', new Date().getTime(), { path: '/' })
      })
    }
  }

  return (
    <>
      <tbody>
        {rows &&
          rows.map((row) => (
            <tr key={row.user_id} className="delete-container-retarget">
              <td>{row.email}</td>
              <td>{UserRoles[row.role_id]}</td>
              <td>
                <SimpleDateTime format="MYD" dateSeparator="." showTime="0">
                  {row.created_on}
                </SimpleDateTime>
              </td>
              <td
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CheckRounded
                  sx={{ fontSize: 18, opacity: row.active !== 1 ? 0.5 : 1 }}
                  color={row.active === 1 ? 'success' : ''}
                />
              </td>
              <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Popconfirm
                  placement="topRight"
                  style={{ maxWidth: '300px' }}
                  title={t('delete_dialog_title')}
                  description={t('delete_dialog_remove_user')}
                  okText={t('delete_dialog_confirm')}
                  cancelText={t('delete_dialog_cancel')}
                  onConfirm={() => {
                    deleteUser(row.user_id)
                  }}
                >
                  <Button
                    size="small"
                    className="delete-btn-retarget"
                    danger
                    style={{ padding: 3 }}
                  >
                    <DeleteRounded style={{ fontSize: 16, color: '#dc4446' }} />
                  </Button>
                </Popconfirm>
              </td>
              <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                <NavLink to={`/users/edit/${row.user_id}`}>
                  <Button size="small" style={{ padding: 3 }}>
                    <ModeEditRounded style={{ fontSize: 16 }} />
                  </Button>
                </NavLink>
              </td>
            </tr>
          ))}
      </tbody>
    </>
  )
}

export default Body
