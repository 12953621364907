import React, { useState } from 'react'

import {
  Box,
  Sheet,
  Chip,
  ChipDelete,
  Checkbox,
  List,
  ListItem,
} from '@mui/joy'

import { Button, InputNumber, Select, Space } from 'antd'

import { Done } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { random } from '../utils'

const CustomParams = ({ rotation, current }) => {
  const { t } = useTranslation()

  const [cappingType, setCappingType] = useState('2')
  const [amount, setAmount] = useState('')
  const [period, setPeriod] = useState('')
  const [periodType, setPeriodType] = useState('minute')
  const [subject, setSubject] = useState('redis')
  const [flat, setFlat] = useState(false)

  const cappings = current.val.cappings

  const updateRotationTarget = (rotation, item_id, val) =>
    rotation.map((i) => {
      if (i.item_id === item_id) {
        let obj = i.cappings
          ? [...i.cappings, ...val]
          : [...val].filter(
              (value, index, array) => array.indexOf(value) === index,
            )

        i.cappings = obj
      }

      if (i.items) {
        i.items = updateRotationTarget(i.items, item_id, val)
      }

      return i
    })

  const updateCurrent = (current, iso) => {
    const item = (
      current.val.cappings ? [...current.val.cappings, ...iso] : [...iso]
    ).filter((value, index, array) => array.indexOf(value) === index)

    current.val.cappings = item
    current.set(current.val)
  }

  const updateObjToArrItem = ({ rotation, current, value }) => {
    rotation.set(updateRotationTarget(rotation.val, current.val.item_id, value))

    updateCurrent(current, value)
  }

  const arrayUniq = (arr1) =>
    arr1.filter(
      (value, index, array) =>
        array
          .map(
            (i) =>
              `${i.cappingType}-${i.amount}-${i.period}-${i.periodType}-${i.subject}-${i.flat}`,
          )
          .indexOf(
            `${value.cappingType}-${value.amount}-${value.period}-${value.periodType}-${value.subject}-${value.flat}`,
          ) === index,
    )

  const removeItem = (rotation, item_id, val) => {
    const obj = (cappings ? [...cappings] : [])
      .filter((x) => ![val].includes(x))
      .filter((value, index, array) => array.indexOf(value) === index)

    current.val.cappings = obj
    current.set(current.val)

    return (
      rotation &&
      rotation.map((i) => {
        if (i.item_id === item_id) {
          i.cappings = i.cappings.filter((x) => ![val].includes(x))
        }

        if (i.items) i.items = removeItem(i.items, item_id, val)
        return i
      })
    )
  }

  const removeElement = (item_id, val) =>
    rotation.set(removeItem(rotation.val, item_id, val))

  const cappingTypeList = [
    { key: '2', value: t('capping_type_2') },
    { key: '1', value: t('capping_type_1') },
  ]

  const periodTypeList = [
    { key: 'minute', value: t('capping_period_minute') },
    { key: 'hour', value: t('capping_period_hour') },
    { key: 'day', value: t('capping_period_day') },
    { key: 'week', value: t('capping_period_week') },
    { key: 'month', value: t('capping_period_month') },
    { key: 'year', value: t('capping_period_year') },
  ]

  const subjectList = [
    { key: 'cookie', value: t('capping_subject_cookie') },
    { key: 'server', value: t('capping_subject_server') },
    { key: 'redis', value: t('capping_subject_redis') },
    // {key: 'ip', value: t('capping_subject_ip')}
  ]

  const include = (e) => {
    if (!amount || !period) return

    const data = { cappingType, amount, period, periodType, subject, flat }
    const value = arrayUniq(cappings ? [...cappings, ...[data]] : [...[data]])
    return updateObjToArrItem({ rotation, current, value })
  }

  const CappingAddBtn = () => (
    <Button type={'primary'} onClick={include}>
      {t('add')}
    </Button>
  )

  return (
    <>
      <Sheet
        sx={(theme) => ({
          mt: '0',
          borderRadius: '8px',
          p: 2,
          display: 'flex',
          [theme.getColorSchemeSelector('light')]: {
            backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
          },
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gridGap: 10,
            flexDirection: 'row',
          }}
        >
          <Space.Compact style={{ height: '32px', width: '100%' }}>
            <Select
              value={cappingType}
              onChange={setCappingType}
              options={cappingTypeList}
              fieldNames={{ label: 'value', value: 'key' }}
              style={{ width: '100%' }}
            />
            <InputNumber
              placeholder={t('capping_amount')}
              defaultValue={amount}
              onChange={setAmount}
              style={{ minWidth: 120 }}
              min={0}
            />

            <InputNumber
              placeholder={t('capping_period')}
              defaultValue={period}
              onChange={setPeriod}
              style={{ minWidth: 120 }}
              min={0}
            />

            <Select
              value={periodType}
              onChange={setPeriodType}
              style={{ minWidth: 160 }}
              options={periodTypeList}
              fieldNames={{ label: 'value', value: 'key' }}
            />

            <Select
              value={subject}
              style={{ minWidth: 210 }}
              onChange={setSubject}
              options={subjectList}
              fieldNames={{ label: 'value', value: 'key' }}
            />
            <List
              orientation="horizontal"
              wrap
              sx={{
                height: '32px',
                marginInlineStart: 0,
                marginBlockStart: 0,
                ...(!flat && { m: 0, mt: '1px', ml: '1px' }),
                border: 0,

                '--List-gap': '8px',
                '--ListItem-minHeight': '28px',
                '--ListItem-gap': '4px',
              }}
            >
              <ListItem
                sx={{
                  m: 0,
                  height: !flat ? 30 : 32,
                }}
              >
                {flat && (
                  <Done
                    fontSize="md"
                    color="primary"
                    sx={{ m: 0, zIndex: 2, pointerEvents: 'none' }}
                  />
                )}

                <Checkbox
                  size="sm"
                  disableIcon
                  overlay
                  label={t('capping_flat')}
                  checked={flat}
                  variant={flat ? 'soft' : 'outlined'}
                  onChange={(event) => {
                    setFlat(event.target.checked)
                  }}
                  slotProps={{
                    action: ({ checked }) => ({
                      sx: {
                        ...{
                          height: '32px',
                          border: '1px solid',
                          borderColor: 'var(--joy-palette-divider)',
                          borderBottomLeftRadius: 0,
                          borderTopLeftRadius: 0,
                        },
                        ...(!!checked && {
                          borderColor: 'primary.500',
                        }),
                      },
                    }),
                  }}
                />
              </ListItem>
            </List>
          </Space.Compact>

          <CappingAddBtn />
        </Box>
      </Sheet>
      {!!cappings.length && (
        <Box sx={{ mt: 2.5 }}>
          {cappings.map((i) => (
            <Chip
              key={random()}
              sx={{ mr: 1, mb: 1, px: 2 }}
              size={'lg'}
              variant="soft"
              color="primary"
              endDecorator={
                <ChipDelete
                  onDelete={() => removeElement(current.val.item_id, i)}
                />
              }
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gridGap: '5px',
                  pr: 1,
                  fontSize: 14,
                }}
              >
                {`
                  ${t(`capping_type_${i.cappingType}`)},
                  ${i.amount > 0 && ` ${t('capping_amount')}=${i.amount}`},
                  ${
                    i.period > 0 &&
                    ` ${t('capping_period')}=${i.period} ${t(
                      `capping_period_${i.periodType}`,
                    )}`
                  },
                  ${t(`capping_subject_${i.subject}`)}
                  ${t(`capping_flat`)}=${i.flat ? 'true' : 'false'}
                `}
              </Box>
            </Chip>
          ))}
        </Box>
      )}
    </>
  )
}

export default CustomParams
