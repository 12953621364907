import React, { useState, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFilter } from '../../../../../hooks/filter'
import { useAppDispatch } from '../../../../../../store'
import { customParamGetAll } from '../../../../../../store/custom'

import { Box } from '@mui/joy'
import { Input, Select, Space } from 'antd'

import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const { Option } = Select

const Custom = () => {
  const { t } = useTranslation()
  const { updateFilter } = useFilter()
  const dispatch = useAppDispatch()

  const [condition, setCondition] = useState('=')
  const [customParamValue, setCustomParamValue] = useState('')
  const [customParamName, setCustomParamName] = useState('')

  const customParams = useSelector(
    (state) => state.custom.customParamsGetAllData?.rows,
  )

  useEffect(() => {
    if (!customParams.length) {
      dispatch(customParamGetAll())
    }
  }, [dispatch, customParams])

  const include = () => {
    if (!customParamName && !customParamValue) {
      return
    }
    updateFilter('in_custom', {
      value: `${customParamName}${condition}${customParamValue}`,
      name: customParamName,
      _value: customParamValue,
      condition,
    })
  }

  const exclude = () => {
    if (!customParamName && !customParamValue) {
      return
    }
    updateFilter('ex_custom', {
      value: `${customParamName}${condition}${customParamValue}`,
      name: customParamName,
      _value: customParamValue,
      condition,
    })
  }

  const customFields = (
    <Select
      key={'customFields'}
      style={{ minWidth: '100px' }}
      defaultValue={customParamName}
      onChange={setCustomParamName}
    >
      <Option value=""> --- </Option>
      {customParams &&
        customParams.map((e) => (
          <Option key={e.param_id} value={e.param_key}>
            {e.name}
          </Option>
        ))}
    </Select>
  )

  const conditions = (
    <Select
      key={'conditions'}
      style={{ minWidth: '120px' }}
      defaultValue={condition}
      onChange={setCondition}
      options={[
        { label: t('referer_eq'), value: '=' },
        { label: t('referer_contains'), value: '%' },
      ]}
    />
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gridGap: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            gridGap: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Space.Compact block>
            {customFields}
            {conditions}
            <Input
              onChange={(e) => setCustomParamValue(e.target.value)}
              placeholder={t('value')}
              defaultValue={customParamValue}
            />
          </Space.Compact>
        </Box>
        <IncludeExcludeBtn include={include} exclude={exclude} />
      </Box>
    </>
  )
}

export default Custom
