import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  ICampaignsSetActiveRequest,
  ICampaignsSetActiveResponse,
  ICampaignsEditRequest,
  ICampaignsEditResponse,
} from './types'

export const setActive = (
  params: ICampaignsSetActiveRequest,
): AxiosPromise<ICampaignsSetActiveResponse> =>
  axiosInstance.patch(Endpoints.CAMPAIGNS.SET_ACTIVE, params)

export const update = (
  params: ICampaignsEditRequest,
): AxiosPromise<ICampaignsEditResponse> =>
  axiosInstance.put(Endpoints.CAMPAIGNS.EDIT, params)
