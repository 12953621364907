import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface StatisticState {
  filter: {
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
    reports: object
  }
}

const initialState: StatisticState = {
  filter: {
    isLoading: false,
    success: false,
    error: false,
    reports: {},
  },
}

type Report = {
  [key: string]: any[]
}

export const reducer = createSlice({
  name: 'statistic',
  initialState,
  reducers: {
    statisticGetStart: (state): StatisticState => ({
      ...state,
      filter: {
        ...state.filter,
        isLoading: true,
        success: false,
        error: false,
      },
    }),
    statisticGetSuccess: (
      state,
      action: PayloadAction<any>,
    ): StatisticState => {
      const newReports: Report = action.payload.data
      const updatedReports: Report = { ...state.filter.reports }

      Object.keys(newReports).forEach((key) => {
        updatedReports[key] = newReports[key]
      })

      return {
        ...state,
        filter: {
          ...state.filter,
          reports: newReports,
          isLoading: false,
          success: true,
          error: false,
        },
      }
    },
    statisticGetFailure: (
      state,
      action: PayloadAction<string>,
    ): StatisticState => ({
      ...state,
      filter: {
        ...state.filter,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),
  },
})

export const { statisticGetStart, statisticGetSuccess, statisticGetFailure } =
  reducer.actions

export default reducer.reducer
