import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  IRotationsSetActiveRequest,
  IRotationsSetActiveResponse,
  IRotationsEditRequest,
  IRotationsEditResponse,
  IRotationsTargetRequest,
  IRotationsTargetResponce,
} from './types'

export const setActive = (
  params: IRotationsSetActiveRequest,
): AxiosPromise<IRotationsSetActiveResponse> =>
  axiosInstance.patch(Endpoints.ROTATIONS.SET_ACTIVE, params)

export const update = (
  params: IRotationsEditRequest,
): AxiosPromise<IRotationsEditResponse> =>
  axiosInstance.put(Endpoints.ROTATIONS.EDIT, params)

export const target = (
  params: IRotationsTargetRequest,
): AxiosPromise<IRotationsTargetResponce> =>
  axiosInstance.post(Endpoints.ROTATIONS.TARGET, params)
