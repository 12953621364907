import { createSelector } from 'reselect'
import { Banner } from '../banners/types'
import { RootState } from '..'

const selectBannersByCampaignId = (
  state: RootState,
  campaign_id: number,
): Banner[] =>
  Object.values(state.banners.allData.bannersById).filter(
    (banner) => banner.campaign_id === campaign_id,
  )

export const makeSelectBannersByCampaignId = () =>
  createSelector([selectBannersByCampaignId], (banners) => banners)
