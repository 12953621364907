import React from 'react'
import Box from '@mui/joy/Box'
import Form from '../Form'

const Main = () => {
  return (
    <Box
      component="main"
      sx={{
        my: 'auto',
        py: 2,
        pb: 5,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: 400,
        maxWidth: '100%',
        mx: 'auto',
        borderRadius: 'sm',
        '& form': {
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        },
      }}
    >
      <Form />
    </Box>
  )
}

export default Main
