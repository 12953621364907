import * as React from 'react'
import List from '@mui/joy/List'

import LanguageToggle from './LanguageToggle'
import SchemeToggle from './SchemeToggle'

const ButtonBar = () => (
  <List
    sx={{
      mt: 0,
      p: 0,
      flexGrow: 0,
      '--ListItem-radius': '8px',
      '--List-gap': '8px',
    }}
  >
    <LanguageToggle />
    <SchemeToggle />
  </List>
)

export default ButtonBar
