import React, { useState } from 'react'
import { Box, Sheet } from '@mui/joy'
import { Input, Select, Space } from 'antd'

import { useTranslation } from 'react-i18next'
import { updateObjToArrItem } from '../utils'
import { renderChip } from '../../../../../handlers/Chips'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const Referer = ({ rotation, current }) => {
  const { t } = useTranslation()
  const [referer, setReferer] = useState('')
  const [condition, setCondition] = useState('=')
  const targets = current.val.targets

  const arrayUniq = (def, arr1) => {
    const defaultMap = new Map(
      def.map((item) => [
        `${item.name}-${item.condition}-${item.version}`,
        item,
      ]),
    )

    const uniqueMap = new Map(
      arr1
        .map((item) => [`${item.name}-${item.condition}-${item.version}`, item])
        .filter((item) => !defaultMap.has(item[0])),
    )

    return Array.from(uniqueMap.values())
  }

  const updateRefererList = (target_name) => {
    if (!condition || !referer) return

    const newEntry = { condition, value: referer }
    const existingEntries =
      targets && target_name in targets ? targets[target_name] : []
    const value = arrayUniq(existingEntries, [newEntry])

    return updateObjToArrItem({
      rotation,
      current,
      value,
      target_name,
    })
  }

  return (
    <>
      <Sheet
        sx={(theme) => ({
          mt: '0',
          borderRadius: '8px',
          p: 2,
          display: 'flex',
          [theme.getColorSchemeSelector('light')]: {
            backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
          },
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
          },
        })}
      >
        <Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              gridGap: 10,
              alignItems: 'center',
            }}
          >
            <Space.Compact block>
              <Input
                onChange={(e) => setReferer(e.target.value)}
                placeholder={t('value')}
                defaultValue={referer}
              />
              <Select
                key={'conditions'}
                style={{ minWidth: '120px' }}
                defaultValue={condition}
                onChange={(val) => setCondition(val)}
                options={[
                  { label: t('referer_eq'), value: '=' },
                  { label: t('referer_contains'), value: '%' },
                ]}
              />
            </Space.Compact>
          </Box>
          <IncludeExcludeBtn
            include={() => updateRefererList('referer')}
            exclude={() => updateRefererList('ex_referer')}
          />
        </Box>
      </Sheet>
      {renderChip({
        current: current,
        rotation: rotation,
        type: 'referer',
        label: (i) =>
          `referer ${
            i.condition === '=' ? `= '${i.value}'` : `ILIKE '%${i.value}%'`
          }`,
        ex_label: (i) =>
          `referer !${
            i.condition === '=' ? `= '${i.value}'` : `ILIKE '%${i.value}%'`
          }`,
      })}
    </>
  )
}

export default Referer
