import React, { useState, useRef } from 'react'

import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import Divider from '@mui/joy/Divider'
import Avatar from '@mui/joy/Avatar'
import { Popper } from '@mui/base/Popper'

import { useAppDispatch } from '../../../store'
import { logoutUser } from '../../../store/auth/actionCreators'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Navigation = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const onLogout = (e) => {
    e.preventDefault()
    dispatch(logoutUser())
  }

  const getUser = (e) => {}

  return (
    <>
      <ListItem role="none">
        <ListItemButton onClick={getUser} role="menuitem">
          {t('profile')}
        </ListItemButton>
      </ListItem>

      <ListItem role="none">
        <ListItemButton onClick={getUser} role="menuitem">
          {t('settings')}
        </ListItemButton>
      </ListItem>

      <Divider
        sx={{
          my: '4px',
          mx: '5px',
          backgroundColor: 'var(--Divider-lineColor)',
        }}
      />

      <ListItem role="none">
        <ListItemButton onClick={onLogout} role="menuitem">
          {t('sign_out')}
        </ListItemButton>
      </ListItem>
    </>
  )
}

const Profile = () => {
  const avatarIcon = useRef()
  const [open, setOpen] = useState(false)

  return (
    <>
      <Avatar
        variant="outlined"
        ref={avatarIcon}
        onClick={(e) => setOpen(!open)}
      />

      <Popper
        open={open}
        anchorEl={avatarIcon.current}
        disablePortal
        keepMounted
        style={{
          zIndex: 9999,
        }}
      >
        <List
          role="menu"
          variant="outlined"
          sx={{
            ml: 1,
            my: 2,
            width: '140px',
            boxShadow: 'md',
            borderRadius: 'sm',
            '--List-radius': '8px',
            '--List-padding': '4px',
            '--ListDivider-gap': '4px',
            '--ListItemDecorator-size': '32px',
            zIndex: 9999,
            background: 'var(--joy-palette-background-popup)',
          }}
        >
          <Navigation />
        </List>
      </Popper>
    </>
  )
}

const UserBar = () => {
  const isLoggedIn = useSelector((state) => !!state.auth.authData.accessToken)

  return (
    isLoggedIn && (
      <>
        <Divider />

        <List
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': '8px',
            '--List-gap': '8px',
          }}
        >
          <Profile />
        </List>
      </>
    )
  )
}

export default UserBar
