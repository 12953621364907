import React, { useEffect } from 'react'
import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Add, CurrencyExchangeRounded } from '@mui/icons-material'
import { useAppDispatch } from '../../../../../../../store'
import { campaignRateGet } from '../../../../../../../store/campaigns'
import InputBar from './InputBar'
import { FilterProvider } from '../../../../../../hooks/filter'

import Table from './Table'
import { useSelector } from 'react-redux'

const Rate = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [cookie] = useCookies(['reload'])
  const campaignId = parseInt(useParams().campaignId)

  const row = useSelector((state) => state.campaigns.rateAllData?.rates)

  const isHasRate = typeof row !== 'undefined'

  useEffect(() => {
    dispatch(
      campaignRateGet({
        campaign_id: campaignId,
      }),
    )
  }, [cookie.reload, campaignId, dispatch])

  return (
    <FilterProvider>
      <Accordion sx={{ border: 'none' }}>
        <AccordionSummary
          indicator={<Add />}
          onClick={(e) => e.preventDefault()}
        >
          <CurrencyExchangeRounded />
          <Box sx={{ flex: 1, fontSize: '13px' }}>{t('advertiser_rate')}</Box>
        </AccordionSummary>
        <AccordionDetails>
          <InputBar isHasRate={isHasRate} />
          <Table />
        </AccordionDetails>
      </Accordion>
    </FilterProvider>
  )
}

export default Rate
