import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CampaignsState } from './types'

const initialState: CampaignsState = {
  allData: {
    campaignsById: {},
    allCampaignIds: [],
    isLoading: false,
    success: false,
    error: null,
  },

  pricesAllData: {
    campaign_price_id: null,
    prices: [],
    delete: null,
    isLoading: false,
    success: false,
    error: null,
  },

  trackerAllData: {
    campaign_tracking_id: null,
    trackers: [],
    isLoading: false,
    success: false,
    error: null,
    delete: null,
  },

  rateAllData: {
    campaign_rate_id: null,
    rates: [],
    isLoading: false,
    success: false,
    error: null,
    delete: null,
  },
}

export const reducer = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    // Set isLoading to true
    campaignFetchStart: (state): CampaignsState => ({
      ...state,
      allData: {
        ...state.allData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),

    // Get All campaigns
    campaignGetAllDone: (
      state,
      { payload: { data, error } }: PayloadAction<any>,
    ): CampaignsState => {
      if (!error) {
        const campaignsArray = data

        const campaignsById = campaignsArray.reduce(
          (acc: { [x: number]: any }, campaign: { campaign_id: number }) => {
            acc[campaign.campaign_id] = campaign
            return acc
          },
          { ...state.allData.campaignsById },
        )

        const allCampaignIds = Object.keys(campaignsById).map(Number)

        return {
          ...state,
          allData: {
            campaignsById,
            allCampaignIds,
            isLoading: false,
            success: true,
            error: null,
          },
        }
      } else {
        return {
          ...state,
          allData: { ...state.allData, error, success: false },
        }
      }
    },

    // Set active
    campaignSetActiveDone: (
      state,
      { payload: { error, campaign_id, active } }: PayloadAction<any>,
    ): CampaignsState => {
      if (!error) {
        return {
          ...state,
          allData: {
            campaignsById: {
              ...state.allData.campaignsById,
              [campaign_id]: {
                ...state.allData.campaignsById[campaign_id],
                active: active,
              },
            },
            allCampaignIds: state.allData.allCampaignIds,
            isLoading: false,
            success: true,
            error: false,
          },
        }
      } else {
        return {
          ...state,
          allData: { ...state.allData, error, success: false },
        }
      }
    },

    // Add campaign
    campaignAddDone: (
      state,
      { payload: { success, result, error } }: PayloadAction<any>,
    ): CampaignsState => ({
      ...state,
      allData: {
        campaignsById: success
          ? { ...state.allData.campaignsById, [result.campaign_id]: result }
          : state.allData.campaignsById,
        allCampaignIds: success
          ? [...state.allData.allCampaignIds, result.campaign_id]
          : state.allData.allCampaignIds,
        isLoading: false,
        success,
        error,
      },
    }),

    // Delete campaign
    campaignDeleteDone: (
      state,
      { payload: { campaign_id, error } }: PayloadAction<any>,
    ): CampaignsState => {
      if (!error) {
        const { [campaign_id]: _, ...newById } = state.allData.campaignsById
        return {
          ...state,
          allData: {
            campaignsById: newById,
            allCampaignIds: state.allData.allCampaignIds.filter(
              (id) => id !== campaign_id,
            ),
            isLoading: false,
            success: true,
            error: false,
          },
        }
      } else {
        return {
          ...state,
          allData: {
            ...state.allData,
            isLoading: false,
            success: false,
            error,
          },
        }
      }
    },

    // Edit campaign
    campaignEditDone: (
      state,
      { payload: { success, data, error } }: PayloadAction<any>,
    ): CampaignsState => {
      return {
        ...state,
        allData: {
          campaignsById: success
            ? {
                ...state.allData.campaignsById,
                [data.campaign_id]: {
                  ...state.allData.campaignsById[data.campaign_id],
                  ...data,
                },
              }
            : state.allData.campaignsById,
          allCampaignIds: state.allData.allCampaignIds,
          isLoading: false,
          success,
          error,
        },
      }
    },

    // Set isLoading to true (prices)
    campaignPricesFetchStart: (state): CampaignsState => ({
      ...state,
      pricesAllData: {
        ...state.pricesAllData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),

    // Add campaign prices
    campaignPricesAddDone: (
      state,
      { payload: { campaign_price_id, error, success } }: PayloadAction<any>,
    ): CampaignsState => ({
      ...state,
      pricesAllData: {
        ...state.pricesAllData,
        isLoading: false,
        success,
        error,
        campaign_price_id: success
          ? campaign_price_id
          : state.pricesAllData.campaign_price_id,
      },
    }),

    // Delete campaign prices
    campaignPricesDeleteDone: (
      state,
      { payload: { error, success, campaign_price_id } }: PayloadAction<any>,
    ): CampaignsState => ({
      ...state,
      pricesAllData: {
        ...state.pricesAllData,
        prices:
          success && state.pricesAllData.prices
            ? state.pricesAllData.prices.filter(
                (price) => price.campaign_price_id !== campaign_price_id,
              )
            : state.pricesAllData.prices,
        isLoading: false,
        success,
        error,
      },
    }),

    // Get All campaign prices
    campaignPricesGetAllDone: (
      state,
      { payload: { error, success, result } }: PayloadAction<any>,
    ): CampaignsState => ({
      ...state,
      pricesAllData: {
        ...state.pricesAllData,
        isLoading: false,
        success,
        error,
        delete: success ? result.delete : state.pricesAllData.delete,
        prices: success ? result.rows : state.pricesAllData.prices,
      },
    }),

    // Set isLoading to true (tracker)
    campaignTrackerFetchStart: (state): CampaignsState => ({
      ...state,
      trackerAllData: {
        ...state.trackerAllData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),

    // Add campaign tracker
    campaignTrackerAddDone: (
      state,
      { payload: { campaign_tracking_id, error, success } }: PayloadAction<any>,
    ): CampaignsState => ({
      ...state,
      trackerAllData: {
        ...state.trackerAllData,
        isLoading: false,
        success,
        error,
        campaign_tracking_id: success
          ? campaign_tracking_id
          : state.trackerAllData.campaign_tracking_id,
      },
    }),

    // Delete campaign prices
    campaignTrackerDeleteDone: (
      state,
      { payload: { error, success, campaign_tracking_id } }: PayloadAction<any>,
    ): CampaignsState => ({
      ...state,
      trackerAllData: {
        ...state.trackerAllData,
        trackers:
          success && state.trackerAllData.trackers
            ? state.trackerAllData.trackers.filter(
                (tracker) =>
                  tracker.campaign_tracking_id !== campaign_tracking_id,
              )
            : state.trackerAllData.trackers,
        isLoading: false,
        success,
        error,
      },
    }),

    // Get All advertiser
    campaignTrackerGetAllDone: (
      state,
      { payload: { error, success, result } }: PayloadAction<any>,
    ): CampaignsState => ({
      ...state,
      trackerAllData: {
        ...state.trackerAllData,
        isLoading: false,
        success,
        error,
        delete: success ? result.delete : state.trackerAllData.delete,
        trackers: result.rows,
      },
    }),

    campaignRateFetchStart: (state): CampaignsState => ({
      ...state,
      rateAllData: {
        ...state.rateAllData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),

    // Get All advertiser
    campaignRateGetDone: (
      state,
      { payload: { error, success, result } }: PayloadAction<any>,
    ): CampaignsState => ({
      ...state,
      rateAllData: {
        ...state.rateAllData,
        isLoading: false,
        success,
        error,
        delete: success ? result.delete : state.rateAllData.delete,
        rates: result.rows,
      },
    }),

    // Delete campaign prices
    campaignRateDeleteDone: (
      state,
      { payload: { error, success, campaign_rate_id } }: PayloadAction<any>,
    ): CampaignsState => {
      if (success && Array.isArray(state.rateAllData.rates)) {
        const filteredRates = state.rateAllData.rates.filter(
          (rate) => rate.campaign_rate_id !== campaign_rate_id,
        )

        return {
          ...state,
          rateAllData: {
            ...state.rateAllData,
            rates: filteredRates.length === 0 ? [] : filteredRates,
            isLoading: false,
            success,
            error,
          },
        }
      }

      return {
        ...state,
        rateAllData: {
          ...state.rateAllData,
          isLoading: false,
          success,
          error,
        },
      }
    },
  },
})

export const {
  campaignFetchStart,
  campaignAddDone,
  campaignEditDone,
  campaignDeleteDone,
  campaignGetAllDone,
  campaignSetActiveDone,
  campaignPricesFetchStart,
  campaignPricesAddDone,
  campaignPricesDeleteDone,
  campaignPricesGetAllDone,
  campaignTrackerFetchStart,
  campaignTrackerAddDone,
  campaignTrackerDeleteDone,
  campaignTrackerGetAllDone,
  campaignRateFetchStart,
  campaignRateGetDone,
  campaignRateDeleteDone,
} = reducer.actions

export default reducer.reducer
