import React from 'react'

import { Box } from '@mui/joy'
import { Button, Popconfirm } from 'antd'
import { DeleteRounded } from '@mui/icons-material'

import { toast } from '../../../../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../../../../store'
import { campaignRateDel } from '../../../../../../../../store/campaigns'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import countries from 'react-select-country-list'
import { FlagImg } from '../../../../../../../handlers/Chips'
import { useCookies } from 'react-cookie'

const Body = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [, setCookie] = useCookies(['reload'])

  const row = useSelector((state) => state.campaigns.rateAllData?.rates)

  const renderDate = (dateString) => {
    if (dateString) return dateString.replace('T', ' ').split(':')[0]
  }

  const deletePrice = (deleteCampaignPriceId) => {
    if (deleteCampaignPriceId) {
      dispatch(
        campaignRateDel({
          campaign_id: deleteCampaignPriceId,
        }),
      ).then((result) => {
        if (result) {
          setCookie('reload', new Date().getTime(), { path: '/' })
          result
            ? toast.success(t('delete_campaign_rate_successfully'))
            : toast.error(t('error'))
        }
      })
    }
  }

  return (
    <>
      <tbody>
        {row && (
          <tr key={`${row.campaign_id}`} className="delete-container-retarget">
            <td>{row.rate}</td>
            <td>{renderDate(row.rate_datetime_from)}</td>
            <td>{renderDate(row.rate_datetime_to)}</td>
            <td>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gridGap: 5,
                }}
              >
                {typeof row.countries === 'undefined' ? (
                  <></>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 2,
                      paddingY: 1,
                    }}
                  >
                    {row.countries.map((countryCode, index) => (
                      <Box
                        sx={{ display: 'flex', flexWrap: 'nowrap', gap: 1 }}
                        key={index}
                      >
                        <FlagImg countryCode={countryCode} />
                        {countries().getLabel(countryCode)}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </td>
            <td style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Popconfirm
                placement="topRight"
                style={{ maxWidth: '300px' }}
                title={t('delete_dialog_title')}
                description={t('delete_dialog_price')}
                okText={t('delete_dialog_confirm')}
                cancelText={t('delete_dialog_cancel')}
                onConfirm={() => {
                  deletePrice(row.campaign_id)
                }}
              >
                <Button
                  size="small"
                  className="delete-btn-retarget"
                  danger
                  style={{ padding: 3 }}
                >
                  <DeleteRounded style={{ fontSize: 16, color: '#dc4446' }} />
                </Button>
              </Popconfirm>
            </td>
          </tr>
        )}
      </tbody>
    </>
  )
}

export default Body
