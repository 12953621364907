import * as React from 'react'

import { NavLink } from 'react-router-dom'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import Divider from '@mui/joy/Divider'
import Tooltip from '@mui/joy/Tooltip'
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import SourceRoundedIcon from '@mui/icons-material/SourceRounded'
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import BookmarksRoundedIcon from '@mui/icons-material/BookmarksRounded'
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded'
import DisplaySettingsRoundedIcon from '@mui/icons-material/DisplaySettingsRounded'
import TrackChangesRoundedIcon from '@mui/icons-material/TrackChangesRounded'
import AdsClickRoundedIcon from '@mui/icons-material/AdsClickRounded'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const NavBtn = (props) => {
  return (
    <Tooltip
      title={props.tooltip}
      arrow
      placement="right"
      color="primary"
      sx={{ zIndex: 10001, px: 1 }}
    >
      <ListItem>
        <ListItemButton component={NavLink} to={props.to}>
          {props.icon}
        </ListItemButton>
      </ListItem>
    </Tooltip>
  )
}

const Navigation = () => {
  const { t } = useTranslation()
  const text = t('sidebar_navigation', { returnObjects: true })

  const isLoggedIn = useSelector((state) => !!state.auth.authData.accessToken)
  const profile = useSelector((state) => state.auth.profileData.profile)

  return (
    <List
      sx={{
        '--ListItem-radius': '8px',
        '--List-gap': '12px',
        [`& .${listItemButtonClasses.root}.active`]: {
          backgroundColor:
            'var(--variant-plainHoverBg, var(--joy-palette-neutral-plainHoverBg, var(--joy-palette-neutral-100, #F0F4F8)))',
        },
      }}
    >
      {isLoggedIn && (
        <NavBtn tooltip={text['main']} to={'/'} icon={<HomeRoundedIcon />} />
      )}

      {isLoggedIn && profile && [1, 2].includes(profile.role_id) && (
        <NavBtn
          tooltip={text['advertisers']}
          to={'/advertisers'}
          icon={<SourceRoundedIcon />}
        />
      )}

      {isLoggedIn && profile && [1, 2].includes(profile.role_id) && (
        <NavBtn
          tooltip={text['publishers']}
          to={'/publishers'}
          icon={<AssignmentIndRoundedIcon />}
        />
      )}

      {isLoggedIn && (
        <NavBtn
          tooltip={text['statistics']}
          to={'/statistics/filter'}
          icon={<FilterAltRoundedIcon />}
        />
      )}

      {isLoggedIn && profile && [1, 2].includes(profile.role_id) && (
        <NavBtn
          tooltip={text['rotation']}
          to={'/rotation'}
          icon={<AdsClickRoundedIcon />}
        />
      )}

      <Divider sx={{ mt: '12px' }} />

      {isLoggedIn && profile && [1, 2].includes(profile.role_id) && (
        <NavBtn
          tooltip={text['re-target']}
          to={'/re-target'}
          icon={<TrackChangesRoundedIcon />}
        />
      )}

      {isLoggedIn && profile && profile.role_id === 1 && (
        <NavBtn
          tooltip={text['users']}
          to={'/users'}
          icon={<PeopleAltRoundedIcon />}
        />
      )}
      {isLoggedIn && profile && [1, 2].includes(profile.role_id) && (
        <NavBtn
          tooltip={text['categories']}
          to={'/categories'}
          icon={<BookmarksRoundedIcon />}
        />
      )}
      {isLoggedIn && profile && profile.role_id === 1 && (
        <NavBtn
          tooltip={text['templetes']}
          to={'/templetes'}
          icon={<DescriptionRoundedIcon />}
        />
      )}
      {isLoggedIn && profile && profile.role_id === 1 && (
        <NavBtn
          tooltip={text['custom_fields']}
          to={'/fields'}
          icon={<TableRowsRoundedIcon />}
        />
      )}
      {isLoggedIn && profile && profile.role_id === 1 && (
        <NavBtn
          tooltip={text['custom_params']}
          to={'/params'}
          icon={<DisplaySettingsRoundedIcon />}
        />
      )}
      {isLoggedIn && profile && profile.role_id === 1 && (
        <NavBtn
          tooltip={text['sharing_setting']}
          to={'/shares'}
          icon={<ManageAccountsRoundedIcon />}
        />
      )}
    </List>
  )
}

export default Navigation
