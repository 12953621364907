import { Box, Sheet } from '@mui/joy'
import { Select, Space, Tag } from 'antd'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import countryList from 'react-select-country-list'
import { useAppDispatch } from '../../../../../../../store'
import { rotationTarget } from '../../../../../../../store/rotations'
import { FlagImg } from '../../../../../../handlers/Chips'

const Locations = ({ setLocations, locations }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const countries = useMemo(() => countryList().getData(), [])

  const tagRender = ({ label, value, closable, onClose }) => {
    const onPreventMouseDown = (event) => {
      event.preventDefault()
      event.stopPropagation()
    }

    return (
      <Tag
        key={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4, display: 'flex' }}
      >
        <Space style={{ gridGap: '4px', mr: '4px' }}>
          {!!value && <FlagImg sx={{ display: 'flex' }} countryCode={value} />}
          {label}
        </Space>
      </Tag>
    )
  }

  const onChange = (_, newValue) => {
    setLocations(newValue)
    if (newValue.length) {
      getRows(newValue)
    }
  }
  const filterOption = (input, option) =>
    option.label.toLowerCase().includes(input.toLowerCase())

  const optionRender = useCallback(
    (option) => (
      <Space>
        <FlagImg
          sx={{ display: 'flex' }}
          countryCode={option.data.country || option.data.value}
        />
        {option.data.label}
      </Space>
    ),
    [],
  )

  const getRows = (val) => {
    if (val) {
      const country = val.map((i) => i.value)
      dispatch(rotationTarget({ country }))
    }
  }

  const targets = `current.val.targets`

  return (
    <>
      <Sheet
        sx={(theme) => ({
          mt: '0',
          p: 0,
          display: 'flex',
          [theme.getColorSchemeSelector('light')]: {
            backgroundColor: 'transparent',
          },
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'transparent',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '300px',
            flex: 1,
          }}
        >
          <Space.Compact block>
            <Select
              placeholder={t('country')}
              maxTagCount={'responsive'}
              allowClear
              mode="multiple"
              style={{ width: '100%' }}
              onChange={onChange}
              options={countries}
              value={locations}
              tagRender={tagRender}
              optionLabelProp="label"
              optionRender={optionRender}
              filterOption={filterOption}
            />
          </Space.Compact>
        </Box>
      </Sheet>

      {targets.locations}
    </>
  )
}

export default Locations
