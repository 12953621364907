import React from 'react'

import Sidebar from './Sidebar'
import Routers from './Routers'
import Splitter from '../../layout/Splitter'
import { PublisherProvider } from '../../hooks/publishers'

const Publishers = () => (
  <PublisherProvider>
    <Splitter>
      <Sidebar />
      <Routers />
    </Splitter>
  </PublisherProvider>
)

export default Publishers
