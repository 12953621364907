import React, { useState } from 'react'

import { Box } from '@mui/joy'
import Form from './Form'
import Submit from './Submit'

import { toast } from '../../../layout/JoyToaster'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../store'
import { reTargetAdd } from '../../../../store/retarget'
import { useCookies } from 'react-cookie'

const Add = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const defaultFormValues = { name: '' }

  const [, setCookie] = useCookies(['reload'])
  const [formValues, setFormValues] = useState(defaultFormValues)

  const handlerForm = (event) => {
    event.preventDefault()
    dispatch(reTargetAdd(formValues)).then((result) => {
      result ? toast.success(t('add_retarget_added')) : toast.error(t('error'))
      setCookie('reload', new Date().getTime(), { path: '/' })
    })
  }

  return (
    <Box
      sx={{
        maxWidth: 'clamp(450px, 100dvw, 900px)',
        width: '100%',
        overflowY: 'auto',
        borderRight: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'var(--joy-palette-background-surface)',
      }}
    >
      <form onSubmit={handlerForm}>
        <Form values={{ set: setFormValues, arr: formValues }} />
        <Submit />
      </form>
    </Box>
  )
}

export default Add
