import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  IRotationsAddRequest,
  IRotationsAddResponse,
  IRotationsDuplicateRequest,
  IRotationsDuplicateResponse,
} from './types'

export const create = (
  params: IRotationsAddRequest,
): AxiosPromise<IRotationsAddResponse> =>
  axiosInstance.post(Endpoints.ROTATIONS.ADD, params)

export const duplicate = (
  params: IRotationsDuplicateRequest,
): AxiosPromise<IRotationsDuplicateResponse> =>
  axiosInstance.post(Endpoints.ROTATIONS.DUPLICATE, params)
