import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import { ICategoriesAddRequest } from '../../api/categories/add/types'
import { ICategoriesGetRequest } from '../../api/categories/get/types'
import { ICategoriesEditRequest } from '../../api/categories/edit/types'
import { ICategoriesRemoveRequest } from '../../api/categories/delete/types'

import {
  categoryAddStart,
  categoryAddSuccess,
  categoryAddFailure,
  categoryGetAllStart,
  categoryGetAllSuccess,
  categoryGetAllFailure,
  categoryEditStart,
  categoryEditSuccess,
  categoryEditFailure,
  categoryDeleteStart,
  categoryDeleteSuccess,
  categoryDeleteFailure,
  categoryGetStart,
  categoryGetSuccess,
  categoryGetFailure,
} from './reducer'

export const categoryAdd =
  (data: ICategoriesAddRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(categoryAddStart())
      const result = await api.categoriesAdd.create(data)
      dispatch(categoryAddSuccess({ result: result.data }))
      return true
    } catch (e: any) {
      dispatch(categoryAddFailure(e.message))
      return false
    }
  }

export const categoryEdit =
  (data: ICategoriesEditRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(categoryEditStart())
      await api.categoriesEdit.update(data)
      dispatch(categoryEditSuccess())
      return true
    } catch (e: any) {
      dispatch(categoryEditFailure(e.message))
      return false
    }
  }

export const categoryDel =
  (data: ICategoriesRemoveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(categoryDeleteStart())
      await api.categoriesDel.remove(data)
      dispatch(categoryDeleteSuccess())
      return true
    } catch (e: any) {
      dispatch(categoryDeleteFailure(e.message))
      return false
    }
  }

export const getCategory =
  (data: ICategoriesGetRequest) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      dispatch(categoryGetStart())
      const result = await api.categoriesGet.get(data)
      dispatch(categoryGetSuccess({ result: result.data }))
      return result.data
    } catch (e: any) {
      dispatch(categoryGetFailure(e.message))
      return false
    }
  }

export const getAllCategories =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      dispatch(categoryGetAllStart())
      const result = await api.categoriesGet.getAll()
      dispatch(categoryGetAllSuccess({ result: result.data }))
    } catch (e: any) {
      dispatch(categoryGetAllFailure(e.message))
    }
  }
