import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  IBannersAddRequest,
  IBannersAddResponse,
  IBannersTrackerAddRequest,
  IBannersTrackerAddResponse,
  IBannersUploadRequest,
  IBannersUploadResponse,
} from './types'

export const create = (
  params: IBannersAddRequest,
): AxiosPromise<IBannersAddResponse> =>
  axiosInstance.post(Endpoints.BANNERS.ADD, params)

export const trackerCreate = (
  params: IBannersTrackerAddRequest,
): AxiosPromise<IBannersTrackerAddResponse> =>
  axiosInstance.post(Endpoints.BANNERS.TRACKER_ADD, params)

export const upload = (
  params: IBannersUploadRequest,
): AxiosPromise<IBannersUploadResponse> => {
  if (!params.file) return axiosInstance.post(Endpoints.BANNERS.UPLOAD, params)

  const fd = new FormData()
  let key: keyof typeof params
  for (key in params) {
    if (params[key]) {
      fd.set(key, params[key] ?? '')
    }
  }
  fd.set('file', params.file)
  return axiosInstance.post(Endpoints.BANNERS.UPLOAD, fd)
}
