import React from 'react'

import { Box, CircularProgress } from '@mui/joy'
import { useSelector } from 'react-redux'
import Rotations from './Rotations'

const List = () => {
  const rows = useSelector((state) => state.rotations.getAllData?.rows)
  const isLoading = useSelector(
    (state) => state.rotations.getAllData?.isLoading,
  )

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      {!!rows.length && !isLoading ? (
        <Rotations />
      ) : (
        !!isLoading && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '20dvh',
            }}
          >
            <CircularProgress size="sm" />
          </Box>
        )
      )}
    </Box>
  )
}

export default List
