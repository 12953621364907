import React, { useState } from 'react'
import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Input } from 'antd'
import { Add, BookmarksRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import FormItem from '../../../layout/FormItem'

const Basic = ({ values }) => {
  const { t } = useTranslation()
  const [index, setIndex] = useState(0)
  const { arr, set } = values

  return (
    <Accordion
      sx={{ borderBottom: 0 }}
      expanded={index === 0}
      onChange={(_, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <BookmarksRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>{t('add_category_basic')}</Box>
      </AccordionSummary>

      <AccordionDetails>
        <FormItem
          text={'category_add_name'}
          component={
            <Input
              style={{ width: '100%' }}
              onChange={(e) => {
                set({
                  ...arr,
                  ...{ name: e.target.value },
                })
              }}
            />
          }
        />

        <FormItem
          text={'value'}
          component={
            <Input
              style={{ width: '100%' }}
              onChange={(e) => {
                set({
                  ...arr,
                  ...{ field_value: e.target.value },
                })
              }}
            />
          }
        />

        <FormItem
          text={'key'}
          component={
            <Input
              style={{ width: '100%' }}
              onChange={(e) => {
                set({
                  ...arr,
                  ...{ field_key: e.target.value },
                })
              }}
            />
          }
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Basic
