import * as React from 'react'

import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import AspectRatio from '@mui/joy/AspectRatio'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import Avatar from '@mui/joy/Avatar'

import { useTranslation, initReactI18next } from 'react-i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
  })

const FlagImg = (props) => {
  return (
    <ListItemDecorator sx={{ minInlineSize: '30px!important' }}>
      <AspectRatio ratio="1" sx={{ minWidth: '20px', borderRadius: '50%' }}>
        <Avatar
          alt={props.code.toLowerCase()}
          loading="lazy"
          src={`https://flagcdn.com/w40/${props.code.toLowerCase()}.png`}
        />
      </AspectRatio>
    </ListItemDecorator>
  )
}

const LanguageToggle = () => {
  const { i18n } = useTranslation()

  return (
    <ListItem>
      <ListItemButton
        onClick={() =>
          i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')
        }
      >
        {i18n.language === 'en' ? (
          <FlagImg code={'ru'} name="ru" />
        ) : (
          <FlagImg code={'gb'} name="en" />
        )}
      </ListItemButton>
    </ListItem>
  )
}

export default LanguageToggle
