import React from 'react'
import { ListItem, ListItemButton, Button, List } from '@mui/joy'
import { KeyboardArrowDown } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'
import AddButton from './AddButton'
import Active from './Active'
import { ListItemText } from '@mui/material'

const ListItemComponent = ({
  isOpen,
  name,
  path,
  id,
  addPath,
  active,
  onSetActive,
  toggleOpen,
  nestedItems,
  ml = '20px',
  borderLeft = false,
  hasNestedList = true,
  sx,
}) => (
  <ListItem
    sx={{
      ml: ml,
      '&:hover > div > a  svg': {
        opacity: 1,
      },
      ...sx,
    }}
    nested
    endAction={
      <>
        <AddButton opacity={0} addPath={addPath} />
        <Active active={active} contentId={id} activeBtnOnClick={onSetActive} />
      </>
    }
    startAction={
      <>
        {hasNestedList && (
          <Button
            variant="plain"
            color="neutral"
            size="sm"
            sx={{ p: '3px', px: '1px', borderRadius: 0 }}
            onClick={toggleOpen}
          >
            <KeyboardArrowDown
              sx={{
                transform: isOpen ? 'initial' : 'rotate(-90deg)',
                transition: '.2s ease-in-out',
              }}
            />
          </Button>
        )}
      </>
    }
  >
    <ListItem className={'advertiser_item_name'}>
      <ListItemButton
        sx={{
          ml: '-36px',
          borderRadius: '0!important',
          pl: hasNestedList ? '30px' : '15px',
          fontSize: 12,
          [`&.active`]: {
            fontWeight: 'bold',
          },
        }}
        component={NavLink}
        to={path}
      >
        <ListItemText
          sx={{
            [`span`]: {
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          }}
          primary={name}
        />
      </ListItemButton>
    </ListItem>
    {isOpen && nestedItems && (
      <List
        sx={{
          borderLeft: borderLeft ? '1px dashed' : 'none',
          borderLeftColor: 'divider',
        }}
      >
        {nestedItems}
      </List>
    )}
  </ListItem>
)

export default ListItemComponent
