import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  ICustomFieldsGetAllResponse,
  ICustomParamsGetAllResponse,
  ICustomFieldsGetRequest,
  ICustomFieldsGetResponse,
  ICustomParamsGetRequest,
  ICustomParamsGetResponse,
} from './types'

export const fieldsGetAll = (): AxiosPromise<ICustomFieldsGetAllResponse> =>
  axiosInstance.get(Endpoints.CUSTOM.FIELDS_GET_ALL)

export const paramsGetAll = (): AxiosPromise<ICustomParamsGetAllResponse> =>
  axiosInstance.get(Endpoints.CUSTOM.PARAMS_GET_ALL)

export const fieldsGet = (
  params: ICustomFieldsGetRequest,
): AxiosPromise<ICustomFieldsGetResponse> =>
  axiosInstance.get(`${Endpoints.CUSTOM.FIELDS_GET}/${params.field_id}`)

export const paramsGet = (
  params: ICustomParamsGetRequest,
): AxiosPromise<ICustomParamsGetResponse> =>
  axiosInstance.get(`${Endpoints.CUSTOM.PARAMS_GET}/${params.param_id}`)
