import React from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Add, EmojiObjectsRounded } from '@mui/icons-material'
import { Input } from 'antd'
import { useTranslation } from 'react-i18next'
import FormItem from '../../../../layout/FormItem'
import Uploader from '../../../../layout/Uploader'

const { TextArea } = Input

const Creative = ({ values }) => {
  const { t } = useTranslation()
  const { arr, set } = values

  return (
    <Accordion>
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <EmojiObjectsRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>
          {t('add_advertiser_creative')}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <FormItem
          text={'creative'}
          component={
            <Uploader
              value={arr.creative || ''}
              onChange={(e) => {
                set({
                  ...arr,
                  ...{ creative: e.target.value, metadata: arr.metadata },
                })
              }}
            />
          }
        />

        <FormItem
          text={'add_advertiser_banner_link'}
          component={
            <TextArea
              style={{ width: '100%' }}
              value={arr.url || ''}
              onChange={(e) => {
                set({
                  ...arr,
                  ...{ url: e.target.value },
                })
              }}
            />
          }
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Creative
