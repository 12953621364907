export const updateRotationTarget = (rotation, item_id, key, val) =>
  rotation.map((i) => {
    if (i.item_id === item_id) {
      let obj = i.targets[key] ? [...i.targets[key]] : []
      obj = [...obj, ...val].filter(
        (value, index, array) => array.indexOf(value) === index,
      )

      i.targets = { ...i.targets, ...{ [`${key}`]: obj } }
    }

    if (i.items) {
      i.items = updateRotationTarget(i.items, item_id, key, val)
    }

    return i
  })

export const random = () => {
  return (Math.random() + 1).toString(36).substring(7)
}

export const addToRotation = (rotation, item_id) =>
  rotation &&
  rotation.map((i) => {
    if (i.item_id === item_id) {
      i.items.push({
        type: 'case',
        item_id: random(),
        content_id: false,
        name: 'Case',
        percent: 0,
        targets: {},
        limits: {},
        cappings: [],
        items: [],
      })
    }

    i.items = addToRotation(i.items, item_id)
    return i
  })

export const removeItemRotation = (rotation, item_id) =>
  rotation.length
    ? rotation
        .map((i) => {
          if (i.item_id !== item_id) {
            i.items = removeItemRotation(i.items, item_id).filter(
              (value) => Object.keys(value).length !== 0,
            )
            return i
          } else {
            return null
          }
        })
        .filter((i) => !!i)
    : []

export const updateCurrent = (current, target_name, iso) => {
  const item = (
    current.val.targets[target_name]
      ? [...current.val.targets[target_name], ...iso]
      : [...iso]
  ).filter((value, index, array) => array.indexOf(value) === index)

  const currentItemNewObj = {
    ...current.val.targets,
    ...{ [`${target_name}`]: item },
  }

  current.val.targets = currentItemNewObj
  current.set(current.val)
}

export const updateItem = ({ rotation, current, target_name, value }) => {
  const iso = value
    .map((i) => i.value)
    .filter((value, index, array) => array.indexOf(value) === index)

  rotation.set(
    updateRotationTarget(rotation.val, current.val.item_id, target_name, iso),
  )

  updateCurrent(current, target_name, iso)
}

export const updateCurrentObj = (current, target_name, iso) => {
  const item = current.val.targets[target_name]
    ? { ...current.val.targets[target_name], ...iso }
    : { ...iso } //.filter((value, index, array) => array.indexOf(value) === index)

  const currentItemNewObj = {
    ...current.val.targets,
    ...{ [`${target_name}`]: item },
  }

  current.val.targets = currentItemNewObj
  current.set(current.val)
}

export const updateRotationObjTarget = (rotation, item_id, key, val) =>
  rotation.map((i) => {
    if (i.item_id === item_id) {
      let obj = i.targets[key] ? { ...i.targets[key] } : {}
      obj = { ...obj, ...val }
      // .filter((value, index, array) => array.map(i=>JSON.stringify(i)).indexOf(JSON.stringify(value)) === index)

      i.targets = { ...i.targets, ...{ [`${key}`]: obj } }
    }

    if (i.items) {
      i.items = updateRotationObjTarget(i.items, item_id, key, val)
    }

    return i
  })

export const updateObjItem = ({ rotation, current, target_name, value }) => {
  rotation.set(
    updateRotationObjTarget(
      rotation.val,
      current.val.item_id,
      target_name,
      value,
    ),
  )

  updateCurrentObj(current, target_name, value)
}

export const updateObjToArrItem = ({
  rotation,
  current,
  target_name,
  value,
}) => {
  rotation.set(
    updateRotationTarget(rotation.val, current.val.item_id, target_name, value),
  )

  updateCurrent(current, target_name, value)
}

export function filterNodesWithNoChildren(tree) {
  const result = []

  for (const node of tree) {
    if (node.children && node.children.length > 0) {
      const filteredChildren = filterNodesWithNoChildren(node.children)

      if (filteredChildren.length > 0) {
        result.push({ ...node, children: filteredChildren })
      }
    } else {
      if (!node.children || node.children.length > 0) {
        result.push(node)
      }
    }
  }

  return result
}

export function generateCompositionKeysForTree(tree) {
  const result = []

  const queue = [...tree]

  while (queue.length > 0) {
    const node = queue.shift()

    if (node.children && node.children.length > 0) {
      const nodeChildren = node.children.map((child) => {
        const processedChildren = child.children.map((grandChild) => {
          const grandChildCompositeKey = `${grandChild.metadata.type}-${grandChild.metadata.key}`
          const processedChild = {
            ...grandChild,
            metadata: {
              ...grandChild.metadata,
              key: grandChildCompositeKey,
            },
            id: grandChildCompositeKey,
          }
          return processedChild
        })

        const childCompositeKey = `${child.metadata.type}-${child.metadata.key}`
        const processedChild = {
          ...child,
          metadata: {
            ...child.metadata,
            key: childCompositeKey,
          },
          id: childCompositeKey,
          children: processedChildren,
        }

        return processedChild
      })
      node.children = nodeChildren
    }
    const copyNode = {
      ...node,
      id: `${node.metadata.type}-${node.metadata.advertiserId}`,
    }
    result.push(copyNode)
  }
  return result
}
