import React from 'react'
import FormContainer from './FormContainer'

import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { toast } from '../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../store'
import { rotationEdit } from '../../../../../store/rotations'
import { useRtt } from '../../../../hooks/rotations'

const Form = ({ rotation }) => {
  const { t } = useTranslation()
  const { rtt } = useRtt()
  const dispatch = useAppDispatch()
  const [, setCookie] = useCookies(['reload'])

  const handlerForm = (event) => {
    event.preventDefault()

    dispatch(
      rotationEdit({
        ...rtt,
        ...{ rotation: rotation },
      }),
    ).then((result) => {
      result
        ? toast.success(t('edit_rotation_successfully'))
        : toast.error(t('error'))

      setCookie('reload', new Date().getTime(), { path: '/' })
    })
  }

  return (
    <form onSubmit={handlerForm}>
      <FormContainer />
    </form>
  )
}

export default Form
