import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth/authReducer'
import advertisersReducer from './advertisers/reducer'
import categoriesReducer from './categories/reducer'
import publishersReducer from './publishers/reducer'
import campaignsReducer from './campaigns/reducer'
import templatesReducer from './templates/reducer'
import rotationsReducer from './rotations/reducer'
import statisticReducer from './statistic/reducer'
import retargetReducer from './retarget/reducer'
import bannersReducer from './banners/reducer'
import customReducer from './custom/reducer'
import usersReducer from './users/reducer'
import zonesReducer from './zones/reducer'
import { useDispatch } from 'react-redux'

// import logger from 'redux-logger'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    advertisers: advertisersReducer,
    categories: categoriesReducer,
    publishers: publishersReducer,
    campaigns: campaignsReducer,
    templates: templatesReducer,
    rotations: rotationsReducer,
    statistic: statisticReducer,
    retarget: retargetReducer,
    banners: bannersReducer,
    custom: customReducer,
    users: usersReducer,
    zones: zonesReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      /* ...(prcess.env.NODE_ENV != 'production' ? [logger]:*/ [],
    ),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
