import React, { useEffect, useRef } from 'react'
import { Sheet, Divider } from '@mui/joy'

import List from './List'
import Header from './Header'

import { useAppDispatch } from '../../../../store'
import { getAllPublishers } from '../../../../store/publishers'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'

const Sidebar = () => {
  const dispatch = useAppDispatch()

  const [cookie, setCookie] = useCookies(['pubsListActive', 'reload'])
  const isLoading = useSelector(
    (state) => state.publishers.getAllData.isLoading,
  )
  const isLoadingRef = useRef(isLoading)

  const onChangeActive = (value) => {
    setCookie('pubsListActive', value ? value : 'all', { path: '/' })
  }

  useEffect(() => {
    if (!isLoadingRef.current) {
      dispatch(getAllPublishers({ active: cookie.pubsListActive }))
    }
  }, [cookie.reload, cookie.pubsListActive, isLoadingRef, dispatch])

  return (
    <Sheet
      sx={{
        borderRight: '1px solid',
        borderColor: 'divider',
        transition: 'transform 0.4s',
        height: 'calc(100dvh - var(--Topbar-height))',
        top: 0,
        pt: 1,
        overflowY: 'auto',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 999,
      }}
    >
      <Header
        active={{
          value: cookie.pubsListActive,
          set: onChangeActive,
        }}
      />
      <Divider />
      <List />
    </Sheet>
  )
}

export default Sidebar
