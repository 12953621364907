import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import { IReTargetAddRequest } from '../../api/retarget/add/types'
import { IReTargetEditRequest } from '../../api/retarget/edit/types'
import { IReTargetRemoveRequest } from '../../api/retarget/delete/types'
import { IReTargetGetPixelsRequest } from '../../api/retarget/get/types'

import {
  reTargetAddStart,
  reTargetAddSuccess,
  reTargetAddFailure,
  reTargetEditStart,
  reTargetEditSuccess,
  reTargetEditFailure,
  reTargetDeleteStart,
  reTargetDeleteSuccess,
  reTargetDeleteFailure,
  reTargetGetAllSuccess,
  reTargetGetAllFailure,
  reTargetGetAllStart,
  reTargetPixelsStart,
  reTargetPixelsSuccess,
  reTargetPixelsFailure,
} from './reducer'

export const reTargetAdd =
  (data: IReTargetAddRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(reTargetAddStart())
      const result = await api.reTargetAdd.create(data)
      dispatch(reTargetAddSuccess({ result: result.data }))
      return true
    } catch (e: any) {
      dispatch(reTargetAddFailure(e.message))
      return false
    }
  }

export const reTargetEdit =
  (data: IReTargetEditRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(reTargetEditStart())
      const result = await api.reTargetEdit.update(data)
      dispatch(reTargetEditSuccess({ result: result.data }))
      return true
    } catch (e: any) {
      dispatch(reTargetEditFailure(e.message))
      return false
    }
  }

export const reTargetDel =
  (data: IReTargetRemoveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(reTargetDeleteStart())
      await api.reTargetDel.remove(data)
      dispatch(reTargetDeleteSuccess())
      return true
    } catch (e: any) {
      dispatch(reTargetDeleteFailure(e.message))
      return false
    }
  }

export const reTargetGetAll =
  () =>
  async (dispatch: Dispatch<any>): Promise<Object | boolean> => {
    try {
      dispatch(reTargetGetAllStart())
      const result = await api.reTargetGet.getAll()
      dispatch(reTargetGetAllSuccess({ result: result.data }))
      return result
    } catch (e: any) {
      dispatch(reTargetGetAllFailure(e.message))
      return false
    }
  }

export const reTargetPix =
  (data: IReTargetGetPixelsRequest) =>
  async (dispatch: Dispatch<any>): Promise<object | boolean> => {
    try {
      dispatch(reTargetPixelsStart())
      const result = await api.reTargetGet.pixels(data)
      dispatch(reTargetPixelsSuccess({ result: result.data }))
      return result.data
    } catch (e: any) {
      dispatch(reTargetPixelsFailure(e.message))
      return false
    }
  }
