import React, { useState, useEffect } from 'react'

import { Box, CircularProgress } from '@mui/joy'
import BaseInformation from './BaseInformation'

import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import { getUser } from '../../../../store/users'

const Edit = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const userId = parseInt(params.pageId)
  const [rows, setRows] = useState(false)

  useEffect(() => {
    dispatch(getUser({ user_id: userId })).then((i) => {
      setRows(i)
    })
  }, [dispatch, userId])

  return rows ? (
    <Box
      sx={{
        maxWidth: 'clamp(450px, 100dvw, 900px)',
        width: '100%',
        overflowY: 'auto',
        borderRight: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'var(--joy-palette-background-surface)',
      }}
    >
      <BaseInformation rows={rows} setRows={setRows} />
    </Box>
  ) : (
    <CircularProgress size="sm" />
  )
}

export default Edit
