import React, { useCallback, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { ConfigProvider, DatePicker } from 'antd'
import localeRu from 'antd/locale/ru_RU'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

const { RangePicker } = DatePicker

const DateInputs = ({ setDateFrom, dateFrom, dateTo, setDateTo }) => {
  const { i18n } = useTranslation()

  const dateFormat = 'YYYY-MM-DD HH'

  const onDatesChange = useCallback(
    (_, dateString) => {
      setDateFrom(dateString[0])
      setDateTo(dateString[1])
    },
    [setDateFrom, setDateTo],
  )

  useEffect(() => {
    onDatesChange(null, [
      dayjs(dateFrom).format(dateFormat),
      dayjs(dateTo).format(dateFormat),
    ])
  }, [dateFrom, dateTo, onDatesChange])

  return (
    <>
      <ConfigProvider locale={i18n.language === 'ru' ? localeRu : {}}>
        <RangePicker
          style={{ width: '400px' }}
          defaultValue={[dateFrom, dateTo]}
          showTime={{ format: 'HH' }}
          format={dateFormat}
          onChange={onDatesChange}
        />
      </ConfigProvider>
    </>
  )
}

export default DateInputs
