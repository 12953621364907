import React, { useState } from 'react'

import {
  Box,
  Sheet,
  Tooltip,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/joy'

import { SourceRounded, DeleteForeverRounded } from '@mui/icons-material'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../../store'
import { zoneDel } from '../../../../../store/zones'
import { useZone } from '../../../../hooks/zones'
import DeleteDialog from '../../../../layout/DeleteDialog'

const ControlBar = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { zone } = useZone()
  const [, setCookie] = useCookies(['reload'])
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const pageId = parseInt(useParams().pageId)
  const zoneId = parseInt(useParams().zoneId)

  const btnSx = { fontSize: '20px' }

  const deleteZone = async () => {
    dispatch(
      zoneDel({
        publisher_id: pageId,
        id: zoneId,
      }),
    ).then((result) => {
      setOpenDeleteDialog(false)
      setCookie('reload', new Date().getTime(), { path: '/' })
    })
  }

  const RemoveCampaign = () => (
    <Tooltip
      arrow
      color="primary"
      placement="top"
      title={t('remove_zone')}
      variant="solid"
    >
      <IconButton size="sm" onClick={setOpenDeleteDialog}>
        <DeleteForeverRounded sx={btnSx} color="danger" />
      </IconButton>
    </Tooltip>
  )

  return (
    <>
      <DeleteDialog
        open={{ set: setOpenDeleteDialog, value: openDeleteDialog }}
        description={t('delete_dialog_remove_zone')}
        onClick={(e) => {
          deleteZone()
        }}
      />
      <Sheet
        variant="outlined"
        sx={{
          border: 'none',
          transition: 'transform 0.4s',
          overflow: 'hidden',
          px: 2,
          py: 1.5,
          minHeight: '58px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor:
            'var(--variant-softBg, var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #E3EFFB)))',
        }}
      >
        <Typography
          level="title-md"
          sx={{ flex: 1, display: 'flex', alignItems: 'center' }}
        >
          <SourceRounded sx={{ mr: '10px' }} />
          {zone.name ? zone.name : <CircularProgress size="sm" />}
        </Typography>

        <Box sx={{ gridGap: '10px', display: 'flex' }}>
          <RemoveCampaign />
        </Box>
      </Sheet>
    </>
  )
}

export default ControlBar
