import React, { useState } from 'react'

import { Box, Sheet } from '@mui/joy/'
import { Button, Select } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useColorScheme } from '@mui/joy/styles'
import { NavLink } from 'react-router-dom'
import FormItem from '../../../../../../layout/FormItem'
import Editor from '@monaco-editor/react'

const Banner = () => {
  const { t } = useTranslation()
  const { mode } = useColorScheme()
  const [domain, setDomain] = useState(0)

  const out = useSelector((state) => state.zones?.getData?.out)
  const uuid = useSelector((state) => state.zones?.getData?.uuid)

  const currentDomain =
    out.domains &&
    out.domains.banner[domain] &&
    window.location.host.indexOf('localhost') > -1
      ? `http://${out.domains.banner[domain][0]}`
      : `https://${out.domains.banner[domain][0]}`

  const Code = () => {
    const code = `<ins class="${out.client}" data-key="${uuid}"></ins>
<script async src="${currentDomain}/o/s/${out.domains.banner[domain][1]}"></script>`

    return (
      <>
        <FormItem
          text={`invocation_codes`}
          component={
            <>
              <Sheet
                sx={{
                  width: '100%',
                  border: '1px solid',
                  borderColor: 'divider',
                  p: 1,
                  borderRadius: 8,
                  '.monaco-editor': {
                    '--vscode-editor-background': 'transparent!important',
                    '--vscode-editorGutter-background': 'transparent!important',
                  },
                  '.decorationsOverviewRuler': {
                    display: 'none!important',
                  },
                }}
              >
                <Editor
                  height="60px"
                  language="html"
                  theme={mode === 'dark' ? 'vs-dark' : 'vs-light'}
                  value={code}
                  options={{
                    fixedOverflowWidgets: true,
                    inlineSuggest: true,
                    fontSize: '12px',
                    formatOnType: true,
                    readOnly: true,
                    readOnlyMessage: { value: '' },
                    autoClosingBrackets: true,
                    scrollBeyondLastColumn: 0,
                    scrollBeyondLastLine: false,
                    wordWrap: 'on',
                    acceptSuggestionOnEnter: 'off',
                    contextmenu: false,
                    minimap: { enabled: false },
                    lineNumbers: 'off',
                    folding: false,
                    scrollPredominantAxis: false,
                    smoothScrolling: false,
                    renderLineHighlight: 'none',
                    renderLineHighlightOnlyWhenFocus: false,
                    lineDecorationsWidth: 0,
                    stickyScroll: { enabled: false },
                    guides: { indentation: false },
                    scrollbar: {
                      vertical: 'hidden',
                      horizontal: 'hidden',
                      scrollByPage: false,
                      alwaysConsumeMouseWheel: false,
                      handleMouseWheel: false,
                      useShadows: false,
                    },
                  }}
                />
              </Sheet>
            </>
          }
        />
      </>
    )
  }

  const tLink = `${currentDomain}/b/test/${uuid}`

  const isLoading = useSelector((state) => state.zones?.getData?.isLoading)
  const items =
    out.domains && out.domains.banner.map((i, k) => ({ label: i[0], value: k }))

  return (
    <>
      <FormItem
        text={'domain'}
        component={
          <Select
            maxTagCount={'responsive'}
            style={{ width: '100%', zIndex: 999 }}
            loading={isLoading}
            defaultValue={domain}
            options={items}
            onChange={setDomain}
          />
        }
      />

      <Code />

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'right',
          justifyContent: 'end',
          textDecoration: 'none',
        }}
        component={NavLink}
        to={tLink}
        target={'_blank'}
      >
        <Button size="sm" variant="soft">
          <EyeOutlined /> {t('preview')}
        </Button>
      </Box>
    </>
  )
}

export default Banner
