import React from 'react'

import Routers from './Routers'
import { BannerProvider } from '../../../hooks/banners'

const Banners = () => (
  <BannerProvider>
    <Routers />
  </BannerProvider>
)

export default Banners
