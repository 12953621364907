import React from 'react'
import { Button } from 'antd'
import { SaveRounded } from '@mui/icons-material'

const SaveReport = () => {
  const handlePrint = () => {}

  return (
    <Button
      onClick={handlePrint}
      size="small"
      ghost
      style={{
        paddingLeft: '4px',
        paddingRight: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SaveRounded sx={{ fontSize: 15 }} />
    </Button>
  )
}

export default SaveReport
