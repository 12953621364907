import * as React from 'react'
import {
  RemoveRedEyeRounded,
  LinkRounded,
  AttachMoneyRounded,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { Accord, AccordItem } from '../Accord'
import LimitComponent from './LimitComponent'

const Limits = ({ rotation, current }) => {
  const { t } = useTranslation()

  return (
    <Accord
      items={
        <>
          <AccordItem
            text={t('target_2_impressions')}
            icon={<RemoveRedEyeRounded />}
            items={
              <LimitComponent
                name={'impressions'}
                rotation={rotation}
                current={current}
              />
            }
          />
          <AccordItem
            text={t('target_2_clicks')}
            icon={<LinkRounded />}
            items={
              <LimitComponent
                name={'clicks'}
                rotation={rotation}
                current={current}
              />
            }
          />
          <AccordItem
            text={t('target_2_budgets')}
            icon={<AttachMoneyRounded />}
            last={1}
            items={
              <LimitComponent
                name={'budget'}
                rotation={rotation}
                current={current}
              />
            }
          />
        </>
      }
    />
  )
}

export default Limits
