import React from 'react'

import { useCookies } from 'react-cookie'
import SplitterLayout from 'react-splitter-layout-react-v18'
import 'react-splitter-layout-react-v18/lib/index.css'

const Splitter = ({ cacheKey, vertical, children, secondaryInitialSize }) => {
  const cookieName = `splitter-${cacheKey}`
  const [cookie, setCookie] = useCookies([cookieName])
  secondaryInitialSize = cookie[cookieName]
    ? cookie[cookieName]
    : secondaryInitialSize

  return (
    <>
      <SplitterLayout
        customClassName={'splitter-report-container'}
        primaryMinSize={20}
        vertical={vertical}
        secondaryMinSize={20}
        secondaryInitialSize={secondaryInitialSize}
        percentage={true}
        onSecondaryPaneSizeChange={(e) => {
          setCookie(cookieName, e)
        }}
      >
        {children}
      </SplitterLayout>
    </>
  )
}

export default Splitter
