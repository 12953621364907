import React, { useState } from 'react'

import Form from './Form'
import Submit from './Submit'

import { Box } from '@mui/joy'
import { toast } from '../../../layout/JoyToaster'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../store'
import { advertiserAdd } from '../../../../store/advertisers'

const Add = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const defaultFormValues = {
    name: '',
    active: false,
    contact_name: '',
    email: '',
    description: '',
    category: 0,
  }

  const [formValues, setFormValues] = useState(defaultFormValues)

  const handlerForm = (event) => {
    event.preventDefault()

    setIsLoading(true)

    dispatch(advertiserAdd(formValues)).then((result) => {
      if (result) {
        toast.success(t('add_advertiser_added'))
        setFormValues(defaultFormValues)
      } else {
        toast.error(t('error'))
      }

      setIsLoading(false)
    })
  }

  return (
    <Box
      sx={{
        maxWidth: 'clamp(450px, 100dvw, 900px)',
      }}
    >
      <form onSubmit={handlerForm}>
        <Form values={{ set: setFormValues, arr: formValues }} />
        <Submit isLoading={isLoading} />
      </form>
    </Box>
  )
}

export default Add
