import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import { IStatisticFilterRequest } from '../../api/statistic/get/types'
import {
  statisticGetStart,
  statisticGetSuccess,
  statisticGetFailure,
} from './reducer'

export const filterRequest =
  (data: IStatisticFilterRequest) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      dispatch(statisticGetStart())
      const result = await api.statisticGet.filter(data)
      dispatch(statisticGetSuccess({ data: result.data }))
      return result.data
    } catch (e: any) {
      dispatch(statisticGetFailure(e.message))
      return false
    }
  }
