import React from 'react'

import { Box } from '@mui/joy'
import { Button, Popconfirm } from 'antd'
import { DeleteRounded } from '@mui/icons-material'
import { toast } from '../../../../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../../../../store'
import { campaignTrackerDel } from '../../../../../../../../store/campaigns'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const events = require('../../../../../../../configs/events.json')

const Body = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const advertiser_id = parseInt(useParams().pageId)

  const deleteTracker = (deleteId, deleteTrackerType) => {
    if (deleteId) {
      dispatch(
        campaignTrackerDel({ campaign_tracking_id: deleteId, advertiser_id }),
      ).then((result) => {
        if (result) {
          result
            ? deleteTrackerType === 1
              ? toast.success(t('delete_campaign_pixel_successfully'))
              : toast.success(t('delete_campaign_tracker_successfully'))
            : toast.error(t('error'))
        }
      })
    }
  }

  const rows = useSelector((state) => state.campaigns.trackerAllData?.trackers)
  const del = useSelector((state) => state.campaigns.trackerAllData?.delete)

  return (
    <>
      <tbody>
        {rows &&
          rows.map((item) => {
            return (
              <tr
                key={item.campaign_tracking_id}
                className="delete-container-retarget"
              >
                <td>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gridGap: 5,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      wordBreak: 'break-all',
                    }}
                  >
                    {item.tracking_code}
                  </Box>
                </td>
                <td>{item.tracking_type === 1 ? t('pixel') : t('tracker')}</td>
                <td>
                  {item.tracking_event === 4
                    ? events.video[item.tracking_video_event]
                    : events.types[item.tracking_event]}
                </td>
                <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                  {del && (
                    <Popconfirm
                      placement="topRight"
                      style={{ maxWidth: '300px' }}
                      title={t('delete_dialog_title')}
                      description={
                        item.tracking_type === 1
                          ? t('delete_dialog_pixel')
                          : t('delete_dialog_tracker')
                      }
                      okText={t('delete_dialog_confirm')}
                      cancelText={t('delete_dialog_cancel')}
                      onConfirm={() => {
                        deleteTracker(
                          item.campaign_tracking_id,
                          item.tracking_type,
                        )
                      }}
                    >
                      <Button
                        size="small"
                        className="delete-btn-retarget"
                        danger
                        style={{ padding: 3 }}
                      >
                        <DeleteRounded
                          style={{ fontSize: 16, color: '#dc4446' }}
                        />
                      </Button>
                    </Popconfirm>
                  )}
                </td>
              </tr>
            )
          })}
      </tbody>
    </>
  )
}

export default Body
