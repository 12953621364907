import React, { useEffect, useState } from 'react'

import { useTheme } from '@mui/joy/styles'
import { JoyToaster } from './layout/JoyToaster'
import CssBase from './handlers/CssBase'
import Pages from './pages'

import { ConfigProvider, theme } from 'antd'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { useAppDispatch } from '../store'
import { getProfile } from '../store/auth/actionCreators'

export default function App() {
  const dispatch = useAppDispatch()
  const [users, setUsers] = useState([])
  const { defaultAlgorithm, darkAlgorithm } = theme

  const mode = useTheme().palette.mode

  useEffect(() => {
    dispatch(getProfile())
      .then((users) => setUsers(users))
      .catch((users) => setUsers(users))
  }, [dispatch])

  const router = createBrowserRouter([
    { path: '*', element: <Pages profile={users} /> },
  ])

  return (
    <ConfigProvider
      theme={{
        algorithm: mode === 'dark' ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <JoyToaster />
      <CssBase />
      <RouterProvider router={router} />
    </ConfigProvider>
  )
}
