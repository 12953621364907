import React, { useEffect } from 'react'

import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../../store'
import { reTargetGetAll } from '../../../../../store/retarget'

import Container from './Container'

const CustomTable = () => {
  const dispatch = useAppDispatch()
  const [cookie] = useCookies(['reload'])

  useEffect(() => {
    dispatch(reTargetGetAll())
  }, [cookie.reload, dispatch])

  return <Container />
}

export default CustomTable
