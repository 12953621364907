import React from 'react'

import { Box } from '@mui/joy'
import { Button, Popconfirm } from 'antd'
import { DeleteRounded } from '@mui/icons-material'

import { toast } from '../../../../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../../../../store'
import { zonePricesDel } from '../../../../../../../../store/zones'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import countries from 'react-select-country-list'
import { FlagImg } from '../../../../../../../handlers/Chips'

const Body = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const publisher_id = parseInt(useParams().pageId)
  const [, setCookie] = useCookies(['reload'])
  const pricingType = { 1: 'CPM', 2: 'CPC', 3: 'CPA' }

  const deleteZone = (deletePriceId) => {
    if (deletePriceId) {
      dispatch(zonePricesDel({ id: deletePriceId, publisher_id })).then(
        (result) => {
          result
            ? toast.success(t('delete_campaign_rate_successfully'))
            : toast.error(t('error'))

          setCookie('reload', new Date().getTime(), { path: '/' })
        },
      )
    }
  }

  const rows = useSelector((state) => state.zones.pricesGetAllData?.rows)
  const del = useSelector((state) => state.zones.pricesGetAllData?.delete)

  return (
    <tbody>
      {rows &&
        rows.map((item) => {
          return (
            <tr key={`${item.id}`} className="delete-container-retarget">
              <td>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gridGap: 5,
                  }}
                >
                  {item.country === '  ' || item.country === '' ? (
                    ''
                  ) : (
                    <FlagImg countryCode={item.country} />
                  )}
                  {item.country === '  ' || item.country === ''
                    ? 'Global'
                    : countries().getLabel(item.country)}
                </Box>
              </td>
              <td>{item.price}</td>
              <td>{pricingType[item.pricing_type] || '---'}</td>
              <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                {del && (
                  <Popconfirm
                    placement="topRight"
                    style={{ maxWidth: '300px' }}
                    title={t('delete_dialog_title')}
                    description={t('delete_dialog_price')}
                    okText={t('delete_dialog_confirm')}
                    cancelText={t('delete_dialog_cancel')}
                    onConfirm={() => {
                      deleteZone(item.id)
                    }}
                  >
                    <Button
                      size="small"
                      className="delete-btn-retarget"
                      danger
                      style={{ padding: 3 }}
                    >
                      <DeleteRounded
                        style={{ fontSize: 16, color: '#dc4446' }}
                      />
                    </Button>
                  </Popconfirm>
                )}
              </td>
            </tr>
          )
        })}
    </tbody>
  )
}

export default Body
