import React, { useEffect, useState } from 'react'

import Advertiser from './Advertiser'
import { useSelector } from 'react-redux'
import { List, Box, CircularProgress } from '@mui/joy'
import { listItemButtonClasses } from '@mui/joy/ListItemButton'
import { useTranslation } from 'react-i18next'
import {
  selectAdvertisersById,
  selectIsLoading,
} from '../../../../../store/advertisers/selector'

const MainList = ({ searchCandidate }) => {
  const advertisers = useSelector(selectAdvertisersById)
  const isLoading = useSelector(selectIsLoading)
  const [filteredAdvertisers, setFilteredAdvertisers] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    if (advertisers) {
      if (searchCandidate) {
        const filteredItems = advertisers.filter((item) =>
          item.name?.toLowerCase().includes(searchCandidate?.toLowerCase()),
        )
        setFilteredAdvertisers(filteredItems)
      } else {
        const orderedAdvertisers = [...advertisers].sort((a, b) => {
          const nameA = a.name?.toLowerCase() || ''
          const nameB = b.name?.toLowerCase() || ''
          return nameA.localeCompare(nameB)
        })
        setFilteredAdvertisers(orderedAdvertisers)
      }
    }
  }, [advertisers, searchCandidate])

  const listSx = () => ({
    '.advertiser_item_name': {
      '--ListItem-plainActiveBg': 'transparent',
      [`& .${listItemButtonClasses.root}`]: {
        borderLeftColor: 'transparent',
      },
    },
    [`& .${listItemButtonClasses.root}`]: {
      borderRadius: 'var(--Button-radius, var(--joy-radius-sm))',
    },
    [`& .${listItemButtonClasses.root}.active`]: {
      backgroundColor:
        'var(--variant-plainActiveBg, var(--joy-palette-neutral-plainActiveBg, var(--joy-palette-neutral-200, #DDE7EE)))',
    },
    '--ListItem-paddingLeft': '16px',
    '--ListItem-startActionWidth': '0px',
    '--ListItem-startActionTranslateX': '-50%',
  })

  const text = t('not_found_search')

  const boxSx = () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })

  return (
    <Box>
      <List size="sm" sx={listSx}>
        {!filteredAdvertisers.length && !isLoading && (
          <Box sx={boxSx}>{text}</Box>
        )}

        {filteredAdvertisers.length
          ? filteredAdvertisers.map((advertiser) => (
              <Advertiser
                key={advertiser.advertiser_id}
                advertiser={advertiser}
              />
            ))
          : isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '20dvh',
                }}
              >
                <CircularProgress size="sm" />
              </Box>
            )}
      </List>
    </Box>
  )
}

export default MainList
