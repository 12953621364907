import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import { IUsersAddRequest } from '../../api/users/add/types'
import { IUsersRemoveRequest } from '../../api/users/delete/types'
import {
  IUsersGetRequest,
  IUsersGetAllRequest,
} from '../../api/users/get/types'

import {
  IUsersSetActiveRequest,
  IUsersEditRequest,
} from '../../api/users/edit/types'

import {
  userAddStart,
  userAddSuccess,
  userAddFailure,
  userGetAllSuccess,
  userGetAllFailure,
  userGetAllStart,
  userSetActiveStart,
  userSetActiveSuccess,
  userSetActiveFailure,
  userDeleteStart,
  userDeleteSuccess,
  userDeleteFailure,
  userEditStart,
  userEditSuccess,
  userEditFailure,
  userGetStart,
  userGetSuccess,
  userGetFailure,
} from './reducer'

export const usersAdd =
  (data: IUsersAddRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(userAddStart())
      await api.usersAdd.create(data)
      dispatch(userAddSuccess())
      return true
    } catch (e: any) {
      dispatch(userAddFailure(e.message))
      return false
    }
  }

export const usersDel =
  (data: IUsersRemoveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(userDeleteStart())
      await api.usersDel.remove(data)
      dispatch(userDeleteSuccess())
      return true
    } catch (e: any) {
      dispatch(userDeleteFailure(e.message))
      return false
    }
  }

export const usersEdit =
  (data: IUsersEditRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(userEditStart())
      await api.usersEdit.update(data)
      dispatch(userEditSuccess())
      return true
    } catch (e: any) {
      dispatch(userEditFailure(e.message))
      return false
    }
  }

export const getUser =
  (data: IUsersGetRequest) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      dispatch(userGetStart())
      const result = await api.usersGet.get(data)
      dispatch(userGetSuccess({ result: result.data }))
      return result.data
    } catch (e: any) {
      dispatch(userGetFailure(e.message))
      return false
    }
  }

export const getAllUsers =
  (data: IUsersGetAllRequest) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      dispatch(userGetAllStart())
      const result = await api.usersGet.getAll(data)
      dispatch(userGetAllSuccess({ result: result.data }))
    } catch (e: any) {
      dispatch(userGetAllFailure(e.message))
    }
  }

export const setActive =
  (data: IUsersSetActiveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(userSetActiveStart())
      await api.usersEdit.setActive(data)
      dispatch(userSetActiveSuccess())
      return true
    } catch (e: any) {
      dispatch(userSetActiveFailure(e.message))
      return false
    }
  }
