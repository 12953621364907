import React from 'react'
import { useTranslation } from 'react-i18next'

const Head = () => {
  const { t } = useTranslation()

  return (
    <thead>
      <tr>
        <th>{t('users_table_email')}</th>
        <th style={{ width: '120px' }}>{t('users_table_sharing_type')}</th>
        <th style={{ width: '30px' }}></th>
      </tr>
    </thead>
  )
}

export default Head
