import React, { useState } from 'react'

import { Button, List, ListItem, ListItemButton } from '@mui/joy'
import { KeyboardArrowDown } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'

import AddButton from './AddButton'
import Active from './Active'

const Container = ({
  getItems,
  content_id,
  templateItem,
  addPath,
  path,
  title,
  active,
  activeBtnOnClick,
}) => {
  const [open, setOpen] = useState(false)
  const [template, setTemplate] = useState([])

  const handlerOnClick = () => {
    setOpen((bool) => !bool)
    !open && getItems && getItems(setTemplate, content_id)
  }

  const Content = () => {
    return (
      open &&
      (template.length || !Object.keys(template).length) && (
        <List
          sx={{
            borderLeft: '1px dashed',
            borderLeftColor: 'divider',
          }}
        >
          {template &&
            !!template.length &&
            !!templateItem &&
            templateItem(template, content_id)}
        </List>
      )
    )
  }

  return (
    <ListItem
      sx={{
        ml: '20px',
        '&:hover > div > a  svg': {
          opacity: 1,
        },
      }}
      nested
      endAction={
        <>
          <AddButton opacity={0} addPath={addPath} />
          <Active
            active={active}
            contentId={content_id}
            activeBtnOnClick={activeBtnOnClick}
          />
        </>
      }
      startAction={
        <>
          {getItems ? (
            <Button
              variant="plain"
              color="neutral"
              size="sm"
              sx={{ p: '3px', px: '1px', borderRadius: 0 }}
              onClick={handlerOnClick}
            >
              <KeyboardArrowDown
                sx={{
                  transform: open ? 'initial' : 'rotate(-90deg)',
                  transition: '.2s ease-in-out',
                }}
              />
            </Button>
          ) : (
            ''
          )}
        </>
      }
    >
      <ListItem className={'advertiser_item_name'}>
        <ListItemButton
          sx={{
            ml: '-36px',
            borderRadius: '0!important',
            pl: getItems ? '30px' : '15px',
            fontSize: 12,
            [`&.active`]: {
              fontWeight: 'bold',
            },
          }}
          component={NavLink}
          to={path}
        >
          {title}
        </ListItemButton>
      </ListItem>
      <Content />
    </ListItem>
  )
}

export default Container
