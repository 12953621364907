import { createContext, useState } from 'react'
import * as React from 'react'

export const FiltersContext = createContext(undefined)

export const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState([])
  const [withAdvancedSearch, setWithAdvancedSearch] = useState(0)
  const [valueVpn, setterVpn] = useState('0')
  const [valueTor, setterTor] = useState('0')
  const [valueUniq, setterUniq] = useState('0')

  const value = {
    filters,
    setFilters,
    withAdvancedSearch,
    setWithAdvancedSearch,
    valueVpn,
    setterVpn,
    valueTor,
    setterTor,
    valueUniq,
    setterUniq,
  }

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  )
}
