import React, { useState } from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Input, Space, Select } from 'antd'
import { useTranslation } from 'react-i18next'
const UserRoles = require('../../../../configs/user_roles.json')

const SearchUser = (props) => {
  const { t } = useTranslation()
  const {
    search: { set, value },
  } = props

  const [user, setUser] = useState('')
  const [role, setRole] = useState('')

  let items = [{ label: t('All'), value: '' }]

  UserRoles &&
    Object.keys(UserRoles).forEach((item) => {
      items.push({
        label: UserRoles[item],
        value: item,
      })
    })

  return (
    <>
      <Space.Compact style={{ flex: 1 }}>
        <Input
          size={'middle'}
          value={user}
          prefix={<UserOutlined />}
          onChange={(el) => {
            set({
              ...value,
              ...{ search: el.target.value },
            })
            setUser(el.target.value)
          }}
        />
        <Select
          style={{ minWidth: 210 }}
          value={role}
          type={'text'}
          options={items}
          onChange={(val) => {
            setRole(val)
            set({
              ...value,
              ...{ role_id: val === '' ? -1 : parseInt(val) },
            })
          }}
        />
      </Space.Compact>
    </>
  )
}

export default SearchUser
