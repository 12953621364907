import React, { useState, useEffect, useMemo } from 'react'

import { Box, Typography } from '@mui/joy'
import { DeleteRounded } from '@mui/icons-material'
import { Input, Button, Upload, Space, Image, Select } from 'antd'
import {
  UploadOutlined,
  FolderOutlined,
  FileOutlined,
  EyeOutlined,
  FolderOpenOutlined,
  FileImageOutlined,
  PlaySquareOutlined,
  FileZipOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store'
import { customFieldGetAll } from '../../store/custom'
import api from '../../api'

import { ReactComponent as MailRu } from '../icons/mail.svg'
import { ReactComponent as Yandex } from '../icons/yandex.svg'
import { ReactComponent as Google } from '../icons/google.svg'
import { ReactComponent as DropBox } from '../icons/dropbox.svg'
import { bannerGetCdns } from '../../store/banners'
import { toast } from './JoyToaster'

const { TextArea } = Input

const SvgIcons = {
  mail: <MailRu />,
  yandex: <Yandex />,
  google: <Google />,
  dropbox: <DropBox />,
}

const Uploader = ({ value, onChange }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [uri, setUri] = useState('')
  const [tree, setTree] = useState([])
  const [currentCdn, setCurrentCdn] = useState('')
  const [visible, setVisible] = useState(false)
  const [src, setSrc] = useState('')
  const [mime, setMime] = useState('')
  const [open, setOpen] = useState({})
  const [localFile, setLocalFile] = useState(null)

  const cdnData = useSelector((state) => state.banners.cdnData)
  const cf = useSelector((state) => state.custom.customFieldsGetAllData?.rows)

  const _setSrc = (src) => {
    setSrc(src)
    setVisible(true)
  }
  const service = useMemo(() => parseServiceFromUri(uri.trim()), [uri])

  useEffect(() => {
    if (cdnData.data.length || cdnData.isLoading || cdnData.error) return
    dispatch(bannerGetCdns())
  }, [dispatch, cdnData])

  useEffect(() => {
    if (cf.length) return
    dispatch(customFieldGetAll())
  }, [dispatch, cf])

  /** @type {import('react').ComponentProps<typeof Upload>['customRequest']} */
  const onUpload = (event) => {
    const maxSizeMb = event.file.type.startsWith('video') ? 10 : 2
    const maxBytes = maxSizeMb * 1024 * 1024
    setTree([])
    setUri('')
    if (event.file.size > maxBytes) {
      toast.error(t('size_exceeded'))
      return event.onError()
    }
    setLocalFile(event.file)
    return event.onSuccess()
  }

  const handlerOnChangeServiceUri = async (newUri) => {
    const trimmed = newUri.trim()
    setUri(trimmed)
    const service = parseServiceFromUri(trimmed)
    if (!service) return
    try {
      const result = await api.bannersGet.cloud({ uri: trimmed, service })
      setTree(result.data.tree)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      {mime.indexOf('image') > -1 || checkFormat(mime) === 'image' ? (
        <Image
          width={0}
          style={{ display: 'none' }}
          src={src}
          preview={{ visible, src, onVisibleChange: setVisible }}
        />
      ) : (
        <Image
          width={0}
          style={{ display: 'none' }}
          src={src}
          preview={{
            visible,
            imageRender: () => (
              <video muted width="500px" height="300px" controls src={src} />
            ),
            toolbarRender: () => null,
            onVisibleChange: setVisible,
          }}
        />
      )}
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '8px',
            justifyContent: 'space-between',
            gap: '8px',
          }}
        >
          <Box>
            <Upload
              customRequest={onUpload}
              onChange={(e) => !e.fileList.length && setLocalFile(null)}
              itemRender={(_, file, __, actions) =>
                file.status === 'done' && (
                  <Box
                    sx={{
                      padding: '2px 6px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '4px',
                      border:
                        '1px solid var(--variant-plainHoverBg, var(--joy-palette-neutral-plainHoverBg, var(--joy-palette-neutral-100, #F0F4F8)))',
                      borderRadius: '6px',
                    }}
                  >
                    <span>{file.name}</span>
                    <Box sx={{ display: 'flex', gap: '4px' }}>
                      <UploadBtn
                        cdn={currentCdn}
                        name={file.name}
                        file={localFile}
                        type={checkItemFormat(file)}
                        disabled={!currentCdn}
                        onChange={onChange}
                      />
                      <Button
                        size={'small'}
                        onClick={actions.remove}
                        type="text"
                        icon={<DeleteRounded style={{ color: '#dc4446' }} />}
                      ></Button>
                    </Box>
                  </Box>
                )
              }
            >
              <Button
                disabled={!!localFile}
                style={{ minWidth: '160px' }}
                icon={<UploadOutlined />}
              >
                {t('upload')}
              </Button>
            </Upload>
          </Box>

          <Input
            value={uri}
            prefix={SvgIcons[service] ?? null}
            disabled={!!localFile}
            style={{ width: '100%' }}
            onChange={(e) => handlerOnChangeServiceUri(e.target.value)}
          />
          <Select
            style={{ width: '200px' }}
            options={cdnData.data}
            placeholder={t('select_cdn')}
            onChange={setCurrentCdn}
          />
        </Box>

        <Box sx={{ mb: '10px' }}>
          <Items
            setSrc={_setSrc}
            setMime={setMime}
            onChange={onChange}
            setOpen={setOpen}
            currentCdn={currentCdn}
            open={open}
            parentKey={''}
            tree={tree}
            display={true}
            parent={true}
          />
        </Box>

        <TextArea style={{ width: '100%' }} value={value} onChange={onChange} />
      </Box>
    </>
  )
}

const Folder = ({
  setSrc,
  setMime,
  setOpen,
  onChange,
  open,
  parent,
  item,
  currentCdn,
}) => {
  const convertToMB = (kilobytes) => kilobytes / 1024
  const key = (item) => itemKey(parent, item)

  return item.type === 'folder' ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '4px',
        cursor: 'pointer',
        [`&:hover`]: {
          backgroundColor:
            'var(--variant-plainHoverBg, var(--joy-palette-neutral-plainHoverBg, var(--joy-palette-neutral-100, #F0F4F8)))',
        },
      }}
      onClick={() => setOpen({ ...open, [key(item)]: !open[key(item)] })}
    >
      <Space.Compact>
        {open[key(item)] ? (
          <FolderOpenOutlined style={{ color: 'orange' }} />
        ) : (
          <FolderOutlined style={{ color: 'orange' }} />
        )}
        <Typography sx={{ fontSize: 12, paddingLeft: '4px' }}>
          {item.name}
        </Typography>
      </Space.Compact>
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '4px',
        [`&:hover`]: {
          backgroundColor:
            'var(--variant-plainHoverBg, var(--joy-palette-neutral-plainHoverBg, var(--joy-palette-neutral-100, #F0F4F8)))',
        },
      }}
    >
      <Space.Compact
        style={{ display: 'flex', width: '100%', alignItems: 'center' }}
      >
        {(item.mime && item.mime.indexOf('image') > -1) ||
        checkFormat(item.name) === 'image' ? (
          <FileImageOutlined style={{ color: 'green' }} />
        ) : checkFormat(item.name) === 'video' ? (
          <PlaySquareOutlined style={{ color: 'green' }} />
        ) : checkFormat(item.name) === 'zip' ? (
          <FileZipOutlined style={{ color: 'green' }} />
        ) : (
          <FileOutlined />
        )}

        <Typography
          sx={{ fontSize: 12, paddingLeft: '4px', flex: 1, width: '100%' }}
        >
          {item.name}
        </Typography>
        <Box
          sx={{
            fontSize: 12,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: 12, mx: '4px' }}>
            {item.size ? `${convertToMB(item.size).toFixed(2)}мб` : ''}
          </Typography>
          {(item.preview || item.download) &&
          ((item.mime &&
            (item.mime.indexOf('video') > -1 ||
              item.mime.indexOf('image') > -1)) ||
            ['image', 'video'].includes(checkFormat(item.name)) > 0) ? (
            <Typography sx={{ fontSize: 12 }}>
              <Preview
                onClick={() => {
                  setSrc(
                    (item.mime && item.mime.indexOf('video') > -1) ||
                      checkFormat(item.name) === 'video'
                      ? item.download
                      : item.preview || item.download,
                  )
                  setMime(item.mime || item.name)
                }}
              />
            </Typography>
          ) : (
            <></>
          )}
          {!!item.download && (
            <UploadBtn
              onChange={onChange}
              url={item.download}
              name={item.name}
              type={checkItemFormat(item)}
              cdn={currentCdn}
            />
          )}
        </Box>
      </Space.Compact>
    </Box>
  )
}

const UploadBtn = ({
  onChange,
  url,
  name,
  type,
  cdn,
  file = null,
  disabled = false,
}) => {
  const cf = useSelector((state) => state.custom.customFieldsGetAllData?.rows)

  const uploadFile = async () => {
    try {
      const params = { url, name, type, cdn, file }
      if (file) {
        delete params.url
        params.file = file
      }
      const result = await api.bannersAdd.upload(params)
      if (result.data.status !== 'success' || !Array.isArray(cf)) return
      let cdnUrl = result.data.value
      for (const { field_value, field_key } of cf) {
        cdnUrl = cdnUrl.replaceAll(field_value, `$$$$CF[${field_key}]$$$$`)
      }
      onChange({ target: { value: cdnUrl } })
    } catch (e) {
      if (e?.response?.data?.errorText) {
        toast.error(e?.response?.data?.errorText)
      }
    }
  }

  return (
    <Button
      type="text"
      disabled={disabled}
      size={'small'}
      onClick={uploadFile}
      icon={<UploadOutlined />}
    ></Button>
  )
}

const Items = ({
  setSrc,
  setMime,
  onChange,
  currentCdn,
  open,
  tree,
  display,
  parent,
  parentKey,
  setOpen,
}) => {
  if (!tree || !tree.length || !Array.isArray(tree)) return
  const key = (item) => itemKey(parentKey, item)

  return tree.map((item) =>
    item.type === 'folder' ? (
      <Box
        sx={{
          display: display || parent ? 'flex' : 'none',
          flexDirection: 'column',
          flex: 1,
        }}
        key={key(item)}
      >
        <Folder
          setSrc={setSrc}
          setMime={setMime}
          setOpen={setOpen}
          currentCdn={currentCdn}
          onChange={onChange}
          open={open}
          parent={parentKey}
          item={item}
        />
        <Box sx={{ ml: '10px', flexDirection: 'column', flex: 1 }}>
          {open?.[key(item)] && (
            <Items
              setSrc={setSrc}
              setMime={setMime}
              setOpen={setOpen}
              onChange={onChange}
              currentCdn={currentCdn}
              open={open}
              parentKey={key(item)}
              tree={item.children}
              parent={open?.[key(item)]}
            />
          )}
        </Box>
      </Box>
    ) : (
      <Folder
        key={key(item)}
        setSrc={setSrc}
        setMime={setMime}
        currentCdn={currentCdn}
        setOpen={setOpen}
        onChange={onChange}
        open={open}
        parent={parentKey}
        item={item}
      />
    ),
  )
}

const Preview = React.memo(({ onClick }) => {
  return (
    <Button
      type="text"
      style={{ borderRadius: '0' }}
      size={'small'}
      onClick={onClick}
    >
      <EyeOutlined />
    </Button>
  )
})

const checkItemFormat = (item) =>
  item.mime
    ? item.mime.indexOf('video') > -1
      ? 'video'
      : item.mime.indexOf('image') > -1
        ? 'image'
        : 'file'
    : checkFormat(item.name)

const checkFormat = (str) => {
  const pattern = /\.([0-9a-z]+)(?:[?#]|$)/i
  const extension = (str.match(pattern) || ['', ''])[1]
  if (['jpg', 'png', 'gif'].includes(extension.toLowerCase())) {
    return 'image'
  } else if (['mp4', 'mov', 'm4p'].includes(extension.toLowerCase())) {
    return 'video'
  } else if (['zip'].includes(extension.toLowerCase())) {
    return 'zip'
  } else {
    return 'file'
  }
}

const itemKey = (parentKey, item) => `${parentKey}${item.name}${item.type}`

/**
 * @param {string} uri
 * @returns {(keyof typeof SvgIcons) | null}
 */
const parseServiceFromUri = (uri) => {
  if (uri.includes('cloud.mail.ru')) return 'mail'
  if (uri.includes('disk.yandex.ru')) return 'yandex'
  if (uri.includes('dropbox.com')) return 'dropbox'
  if (uri.includes('google.com')) return 'google'

  return null
}

export default Uploader
