import * as React from 'react'

import FormLabel from '@mui/joy/FormLabel'
import FormControl from '@mui/joy/FormControl'
import { useTranslation } from 'react-i18next'

const FormItem = (props) => {
  const { t } = useTranslation()

  return (
    <>
      <FormControl
        sx={{ minHeight: '42px', py: '5px', ...{ ...props.sx2 } }}
        orientation={'horizontal'}
        required={props.required}
      >
        <FormLabel
          sx={{
            pt: '6px',
            maxWidth: '160px',
            width: '100%',
            justifyContent: 'right',
            alignItems: 'baseline',
            ...props.sx,
          }}
        >
          {t(props.text)}
        </FormLabel>

        {props.component}
      </FormControl>
    </>
  )
}

export default FormItem
