import React from 'react'
import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <Box component="footer" sx={{ py: 3 }}>
      <Typography level="body3" textAlign="center">
        © AdAngle {currentYear}
      </Typography>
    </Box>
  )
}

export default Footer
