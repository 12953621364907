import React, { useState } from 'react'
import { Box, Sheet } from '@mui/joy'
import { Space } from 'antd'
import { renderChip } from '../../../../../handlers/Chips'
import { updateObjToArrItem } from '../utils'
import MaskInput from 'antd-mask-input'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const IpRange = ({ rotation, current }) => {
  const [valueFrom, setValueFrom] = useState('255.255.255.0')
  const [valueTo, setValueTo] = useState('255.255.255.254')

  const handleIncludeExclude = (isInclude) => {
    updateObjToArrItem({
      rotation,
      current,
      value: [{ from: valueFrom, to: valueTo }],
      target_name: isInclude ? 'ip_range' : 'ex_ip_range',
    })
  }

  return (
    <>
      <Sheet
        variant="outlined"
        sx={(theme) => ({
          mt: '0',
          border: 'none',
          borderRadius: '8px',
          p: 2,
          gap: 1,
          display: 'flex',
          [theme.getColorSchemeSelector('light')]: {
            backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
          },
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
          },
        })}
      >
        <Box
          sx={{ flex: 1, display: 'flex', flexDirection: 'row', gridGap: 10 }}
        >
          <Space.Compact block>
            <MaskInput
              mask={'0[0][0].0[0][0].0[0][0].0[0][0]'}
              value={valueFrom}
              onChange={(e) => {
                const { value } = e.target
                if (value !== '_._._._') {
                  const ip = value.replaceAll('_', '').replaceAll('...', '')
                  setValueFrom(ip)
                } else {
                  setValueFrom('')
                }
              }}
              placeholder="From: 255.255.255.0"
              size="middle"
              className="ant-input-compact-item ant-input-compact-first-item"
            />
            <MaskInput
              mask={'0[0][0].0[0][0].0[0][0].0[0][0]'}
              value={valueTo}
              onChange={(e) => {
                const { value } = e.target
                if (value !== '_._._._') {
                  const ip = value.replaceAll('_', '').replaceAll('...', '')
                  setValueTo(ip)
                } else {
                  setValueTo('')
                }
              }}
              placeholder="To: 255.255.255.254"
              size="middle"
              className="ant-input-compact-item ant-input-compact-last-item"
            />
          </Space.Compact>
        </Box>
        <IncludeExcludeBtn
          include={() => handleIncludeExclude(true)}
          exclude={() => handleIncludeExclude(false)}
        />
      </Sheet>

      {renderChip({
        current,
        rotation,
        type: 'ip_range',
        label: (item) => `${item.from}${item.to ? ` - ${item.to}` : ''}`,
      })}
    </>
  )
}

export default IpRange
