import React, { useState } from 'react'

import Form from './Form'
import Submit from './Submit'

import { Box } from '@mui/joy'
import { toast } from '../../../../layout/JoyToaster'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../store'
import { bannerAdd } from '../../../../../store/banners'
import { useParams } from 'react-router-dom'

const Add = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const advertiserId = parseInt(useParams().pageId)
  const campaignId = parseInt(useParams().campaignId)
  const [isLoading, setIsLoading] = useState(false)

  const defaultFormValues = {
    advertiser_id: advertiserId,
    campaign_id: campaignId,
    active: null,
    name: '',
    url: '',
    metadata: { upload: 0 },
    creative: '',
  }

  const [formValues, setFormValues] = useState(defaultFormValues)

  const handlerForm = async (event) => {
    event.preventDefault()
    try {
      setIsLoading(true)
      const result = await dispatch(bannerAdd(formValues))
      if (result.success) {
        return toast.success(t('add_campaign_added'))
      }
      toast.error(result.error ?? t('error'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      sx={{
        maxWidth: 'clamp(450px, 100dvw, 900px)',
      }}
    >
      <form onSubmit={handlerForm}>
        <Form values={{ set: setFormValues, arr: formValues }} />

        <Submit
          values={formValues}
          onSubmit={handlerForm}
          isLoading={isLoading}
        />
      </form>
    </Box>
  )
}

export default Add
