import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  IBannersSetActiveRequest,
  IBannersSetActiveResponse,
  IBannersEditRequest,
  IBannersEditResponse,
} from './types'

export const setActive = (
  params: IBannersSetActiveRequest,
): AxiosPromise<IBannersSetActiveResponse> =>
  axiosInstance.patch(Endpoints.BANNERS.SET_ACTIVE, params)

export const update = (
  params: IBannersEditRequest,
): AxiosPromise<IBannersEditResponse> =>
  axiosInstance.put(Endpoints.BANNERS.EDIT, params)
