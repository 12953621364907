import React from 'react'

import Tooltip from '@mui/joy/Tooltip'
import IconButton from '@mui/joy/IconButton'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded'

const AddNewUser = () => {
  const { t } = useTranslation()

  const btnSx = {
    fontSize: '20px',
    mx: '6px',
  }

  return (
    <Tooltip
      arrow
      size="sm"
      color="primary"
      placement="top"
      title={t('add_user')}
      variant="solid"
    >
      <IconButton size="sm" component={NavLink} to={`/users/add`}>
        <PersonAddAltRoundedIcon sx={btnSx} color="primary" />
      </IconButton>
    </Tooltip>
  )
}

export default AddNewUser
