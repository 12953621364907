import React, { useEffect, useState } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Input, Select, Switch } from 'antd'
import { Add, PermMediaRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../../../../store'
import { templateGetAll } from '../../../../../../../store/templates'
import { useCmp } from '../../../../../../hooks/campaigns'

import FormItem from '../../../../../../layout/FormItem'

const Basic = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { cmp, updateCampaign } = useCmp()
  const [index, setIndex] = useState(0)
  const [cookie] = useCookies(['reload'])
  const active = parseInt(cmp.active)

  const templateTypes = useSelector((state) => state.templates?.getAllData.rows)

  useEffect(() => {
    dispatch(templateGetAll())
  }, [cookie.reload, dispatch])

  return (
    <Accordion
      sx={{ border: 'none' }}
      expanded={index === 0}
      onChange={(_, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <PermMediaRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>
          {t('add_advertiser_basic')}
        </Box>
      </AccordionSummary>
      <>
        <AccordionDetails>
          <FormItem
            text={'add_advertiser_basic_name'}
            component={
              <Input
                style={{ width: '100%' }}
                value={cmp.name || ''}
                onChange={(e) => {
                  updateCampaign('name', e.target.value)
                }}
              />
            }
          />

          <FormItem
            text={'add_templates'}
            component={
              <Select
                style={{ width: '100%' }}
                value={cmp.template}
                fieldNames={{ label: 'name', value: 'id' }}
                options={[...(templateTypes ? templateTypes : [])]}
                onChange={(val) => {
                  updateCampaign('template', val === '' ? 0 : parseInt(val))
                }}
              />
            }
          />

          <FormItem
            text={'add_advertiser_basic_active'}
            component={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Switch
                  size="small"
                  checked={active === 1}
                  onChange={(e) => {
                    updateCampaign('active', e ? 1 : 0)
                  }}
                />
              </Box>
            }
          />
        </AccordionDetails>
      </>
    </Accordion>
  )
}

export default Basic
